import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TBillingHeader, TUploadHeader } from "../../API";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { BILLING_RESULT_UPLOADED } from "../../global-define";
import { useBilling } from "../../hooks/useBilling";
import { useBillingUploads } from "../../hooks/useBillingUploads";
import { useCommonTools } from "../../hooks/useCommonTools";
// import { Button, Modal} from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const BillResultCSVUploadResult = (props) => {

    const { setUser, setAuthState, userName } = props;

    const { getUploadHeaders, listUploadData } = useBillingUploads();
    const { updateBillingData, getBillingHeadersByTransferDate, updateBillingHeader } = useBilling();
    const { getTimeStampString } = useCommonTools();

    const navigate = useNavigate();
    const OnClickReturn = () => navigate('/MainMenu')
    const [progressMessage, setProgressMessage] = useState<string>('');
    const [progressMessageDetail, setProgressMessageDetail] = useState<string>('');

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    //    const headerId = query.get("uploadIds");
    const idstring = query.get("uploadIds");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [billingHeaders, setBillingHeaders] = useState<TBillingHeader[]>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        const ids = idstring.split(',');
        console.log(ids);
        updateResultStatus(ids);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateHeaderInfo = async (billingHeader: TBillingHeader, uploadHeaders: TUploadHeader[]) => {

        //const foundHeader = uploadHeaders.find(upheader => upheader.consignorCode === billingHeader.consignorCode);
        const foundHeader = uploadHeaders.find(upheader => upheader.classification === billingHeader.classification);

        billingHeader.status = BILLING_RESULT_UPLOADED; // 請求結果ファイルアップロード済み

        if (foundHeader != null) {
            billingHeader.totalNumber = foundHeader.totalNumber;
            billingHeader.totalFee = foundHeader.totalFee;
            billingHeader.totalNumberOfSuccess = foundHeader.totalNumberOfSuccess;
            billingHeader.totalFeeOfSuccess = foundHeader.totalFeeOfSuccess;
            billingHeader.totalNumberOfFailure = foundHeader.totalNumberOfFailure;
            billingHeader.totalFeeOfFailure = foundHeader.totalFeeOfFailure;

            billingHeader.uploadDateTime = foundHeader.uploadDateTime;
            billingHeader.uploadFileName = foundHeader.uploadFileName;
        }
        await updateBillingHeader(billingHeader);
    }

    const updateResultStatus = async (uploadHeaderIds: string[]) => {
        // 最新のダウンロードリストを取得する

        try {
            setProgressMessage("結果データ更新中 . . .");

            // アップロードデータをアップロード時間から取得する
            const upHeaders = await getUploadHeaders(uploadHeaderIds); // 先頭のヘッダのタイムスタンプでデータを串刺し検索
            const uploadDataItems = await listUploadData(upHeaders[0].uploadDateTime);

            // 結果が来るのは必ず当月以降なので年は
            const dt = new Date();
            // 12月の結果が出るのは来年なので振替年から１引く
            const transferYear = (upHeaders[0].transferDate.substring(0, 2) === '12' ? (dt.getFullYear() - 1).toString() : dt.getFullYear().toString());
            // 振替対象データを振替年と振替日から取得
            const bhs = await getBillingHeadersByTransferDate(transferYear, upHeaders[0].transferDate);
            console.log(bhs);
            //const billingData  = await listBillingData( billingHeader.data.BillingOrderByTransferDate.items[0].id )

            //const downdata = downlist[0];
            if (bhs === null) {
                setErrorMessage(`アップロードファイルの振替日(${upHeaders[0].transferDate})に該当する振替データが存在しません。`);
            } else {

                for (const billingHeader of bhs) {
                    // アップロードヘッダから区分コードが一致するヘッダを検索しその情報でヘッダをアップデート
                    await updateHeaderInfo(billingHeader, upHeaders);
                    // 行番号をキーにループして更新する。
                    console.log(uploadDataItems);
                    const billingDataItems = billingHeader.billingData.items;
                    //const uploadData = updata.uploadData.items;
                    // 振替データに該当するアップロードデータを順次検索し更新
                    let billingNo = 1;
                    for (const billingData of billingDataItems) {

                        const uploadData = uploadDataItems.find(upload => upload.customerNumber === billingData.customerNumber);

                        if (typeof uploadData !== "undefined") { // データが見つかったら
                            billingData.transferResultCode = uploadData.transferResultCode;
                            // BillingDataのアップデート
                            await updateBillingData(billingData);
                            setProgressMessageDetail(`区分:(${billingHeader.classification}/${bhs.length})　データ:(${billingNo}/${billingDataItems.length})`);
                            //console.log(`区分:(${billingHeader.classification}/${bhs.length})　データ:(${billingNo}/${billingDataItems.length})`); 
                        }else{
                            console.log(`アップロードデータが見つかりません。区分:(${billingHeader.classification}/${bhs.length}) データ:${billingNo}/${billingDataItems.length}　顧客番号:${billingData.customerNumber}`);
                        }
                        billingNo ++; 
                    }
                }
                // 更新が終わったら結果を取り直し
                const resultHeaders = await getBillingHeadersByTransferDate(getTimeStampString().substring(0, 4), upHeaders[0].transferDate);
                setBillingHeaders(resultHeaders);
            }


            setIsLoading(false);

        } catch (e) {
            console.log(e);
        }


    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <>
                    <div className="fs-1 m-5 align-items-center text-center">{progressMessage}</div>
                    <div className="fs-5 m-5 align-items-center text-center">{progressMessageDetail}</div>
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">結果データファイルアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    {errorMessage === '' ? (
                        <>
                            <section className="row">
                                <section className="col">
                                    <header className="pb-2 mb-2 d-flex align-items-center ">
                                        <h1 className="fs-4 m-0 mx-auto">アップロード完了</h1>
                                    </header>
                                </section>
                            </section>


                            <section className="row mb-5">
                                <section className="col">
                                    {
                                    (billingHeaders != null ) &&
                                    billingHeaders.map((billingHeader, index) => (
                                        <>
                                        { billingHeader.classification  }
                                        <table className="table table-striped table-hover table-bordered shadow-sm">


                                            <thead>
                                                <tr>
                                                    <th>行番号</th>
                                                    <th>銀行コード</th>
                                                    <th>支店コード</th>
                                                    <th>預金種別</th>
                                                    <th>口座番号</th>
                                                    <th>預金者名</th>
                                                    <th>振替金額</th>
                                                    <th>新規コード</th>
                                                    <th>振替結果コード</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {billingHeader.billingData.items.map((billingdata, index) => (
                                                    <tr key={index}>
                                                        <td>{billingdata.lineNo}</td>
                                                        <td>{billingdata.transferBankCode}</td>
                                                        <td>{billingdata.transferBankBranchCode}</td>
                                                        <td>{billingdata.depositType}</td>
                                                        <td>{billingdata.accountNumber}</td>
                                                        <td>{billingdata.accountHolder}</td>
                                                        <td>&yen;{Number(billingdata.transferAmount)}</td>
                                                        <td>{billingdata.firstTransferCode}</td>
                                                        <td>{billingdata.transferResultCode}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </>
                                    ))
                                    }
                                </section>
                            </section>
                        </>
                    ) : (
                        <section className="row">
                            <section className="col">
                                <header className="pb-2 mb-2 d-flex align-items-center ">
                                    <h1 className="fs-4 m-4 mx-auto">エラーが発生しました。</h1>
                                </header>
                                <p className="text-center fs-5 m-5">{errorMessage}</p>
                            </section>
                        </section>
                    )}
                </div >
            )
            }
            <Footer />
        </>
    );
};