import { BrowserRouter } from 'react-router-dom';
import { Router } from "./router/Router"
import './App.css';
import { SystemSettingProvider } from "./providers/SystemSettingProvider"
import { SelectedSalonProvider } from "./providers/SelectedSalonProvider"

import React, { useState } from 'react';
import Amplify, { Auth/*, Hub */ } from 'aws-amplify';
import { AuthState, onAuthUIStateChange, CognitoUserInterface } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import { LoginDialog } from './atoms/LoginDialog';
//import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';

//Amplify.configure(awsconfig);
Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true
  }
})

//console.log(Amplify.configure())

function App(): JSX.Element {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<CognitoUserInterface | undefined>();
  const [userName, setUserName] = useState<string>();

  React.useEffect(() => {

    init();
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState as AuthState);
      setUser(authData as CognitoUserInterface)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {

    console.log('init');
    /*
    Hub.listen('auth', async (data) => {
      
      try {
        //const { payload } = data;

        console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);    
      } catch(e){
        console.log(e)
      }
    });
    */

    try {
      const user = await Auth.currentAuthenticatedUser() as CognitoUserInterface;
      console.log(user);
      setAuthState(AuthState.SignedIn);
      setUser(user)
      setUserName(user.attributes.email);
    } catch (e) {
      console.log(e);
      setAuthState(AuthState.SignedOut);
      setUser(null);
      setUserName(null);
    }
  }

  //const flag1 = authState === AuthState.SignedIn 
  //const flag = flag1 && user ; 
  //console.log( flag ) ;  
  // サインイン済み
  return authState === AuthState.SignedIn && user ? (
    <>
      {/*
      <div className="App">
        <div>Hello, {user.username}</div>
        <AmplifySignOut />
      </div>*/}

      <SelectedSalonProvider>
        <SystemSettingProvider>
          <BrowserRouter>
            <Router setUser={setUser} setAuthState={setAuthState} userName={userName} />
          </BrowserRouter>
        </SystemSettingProvider>
      </SelectedSalonProvider>
    </>
  ) : ( // サインイン前
    <>
      <LoginDialog setUser={setUser} setAuthState={setAuthState} setUserName={setUserName} />
      {/*
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            { type: "username" },
            { type: "password" },
            { type: "email" }
          ]}
        />
        </AmplifyAuthenticator>
        */}
    </>
  );
}

process.env.NODE_ENV !== "development" && (console.log = () => { });

export default App;
