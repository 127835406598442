import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";

import { API, graphqlOperation } from 'aws-amplify';
import { getMAdmin, listMAdmins } from '../../graphql/queries';
import { updateMAdmin } from '../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { GetMAdminQuery, CreateMAdminInput, ListMAdminsQuery, MAdmin } from '../../API';
import { useForm } from 'react-hook-form'
import '../../css/common.css';
// get our fontawesome imports
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from 'react-bootstrap';
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { EMAIL_CHECK_PATTERN } from '../../global-define';

type Inputs = {
    accountNo: string;
    mail: string;
    name: string;
    contact: string;
    password: string;
}

export const AccountEdit = (props) => {

    const { setUser, setAuthState, userName } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const navigate = useNavigate();
    const { id } = useParams();

    const { updateAdminCognitoUser } = useCognito();

//    const [nextToken, setNextToken] = useState<string>(null);

    const [admin, setAdmin] = useState<CreateMAdminInput>();
    const [ originalAdmin , setOriginalAdmin  ] = useState<CreateMAdminInput>() ; 
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDuplicateAccountNo, setIsDuplicateAccountNo] = useState<boolean>(false);
    const [isDuplicateMail, setIsDuplicateMail] = useState<boolean>(false);
    const [isRevealPassword, setIsRevealPassword] = useState(false);
//    const [originalPassword, setOriginalPassword] = useState<string>();

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    /**
     * リセットボタン押下（同じページを再読み込み）
     * @returns 
     */
    const OnReset = () => {
        getAdmin();
        handleResetConfiumClose();
        //フォームデータのクリア
        reset();
    }

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate(`/AccountDetail/${admin.id}`)

    /**
     * データ取得
     */
    const getAdmin = async () => {
        console.log('GetAdmin');
        const res = (await API.graphql(graphqlOperation(getMAdmin, {
            id: `${id}`,
        })) as GraphQLResult<GetMAdminQuery>);

        console.log(res);
        const mAdmin = res.data.getMAdmin as CreateMAdminInput;
        setAdmin(mAdmin)
        setOriginalAdmin({...mAdmin});
        //setOriginalPassword(mAdmin.password);
        //setNextToken(res.data.listMAdmins.nextToken);
        setIsLoading(false);
        //setIsLoading(false);
    }

    /**
     * データ更新
     */
    const updateAdmin = async () => {
        console.log('updateAdmin');

        try {

            await updateAdminCognitoUser( originalAdmin as MAdmin , admin as MAdmin );

            const res = (await API.graphql(graphqlOperation(updateMAdmin, {
                input: {
                    accountNo: `${admin.accountNo}`,
                    id: `${admin.id}`,
                    name: `${admin.name}`,
                    contact: `${admin.contact}`,
                    mail: `${admin.mail}`,
                    password: `${admin.password}`
                },
            })) as GraphQLResult<CreateMAdminInput>);

            console.log(admin);
            console.log(res);
            //const pass = await setUserPassword(admin.cognitoUserId, admin.password);
            //console.log(pass);

        } catch (e) {
            console.log(e) ;
        }
        
    }

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        //----------------
        // 重複チェック
        //----------------
        // 登録済みのデータを抽出
        setIsLoading(true);
        let res = null;
        try {
            res = (await API.graphql(graphqlOperation(listMAdmins, {
                limit: 999999
                //nextToken: nextToken,
            })) as GraphQLResult<ListMAdminsQuery>);
        } catch (e) {
            console.log(`エラー発生 ${e.errors}`);
        }
        console.log(res);

        let isDuplicateAccountNo = false;
        let isDuplicateMail = false;
        if (0 < res.data.listMAdmins.items.length) {
            // 自分以外のデータで社員番号かメールアドレスの登録あり
            isDuplicateAccountNo = res.data.listMAdmins.items.some(el => (el.id !== `${admin.id}`) && (el.accountNo === `${admin.accountNo}`));
            isDuplicateMail = res.data.listMAdmins.items.some(el => (el.id !== `${admin.id}`) && (el.mail === `${admin.mail}`));
            // どちらかの項目か判定
            setIsDuplicateAccountNo(isDuplicateAccountNo);
            setIsDuplicateMail(isDuplicateMail);
        }
        if (!isDuplicateAccountNo && !isDuplicateMail) {
            // 重複無し
            // 更新
            await updateAdmin();
            // 遷移
            navigate(`/AccountList/`);
        }
        setIsLoading(false);

    }
    const onChangeAccountNo = (event) => {
        setAdmin({ ...admin, accountNo: event.target.value });
    }
    const onChangeMail = (event) => {
        setAdmin({ ...admin, mail: event.target.value });
    }
    const onChangeName = (event) => {
        setAdmin({ ...admin, name: event.target.value });
    }
    const onChangeContact = (event) => {
        setAdmin({ ...admin, contact: event.target.value });
    }
    const onChangePassword = (event) => {
        setAdmin({ ...admin, password: event.target.value });
    }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showUpdateConfium, setShowUpdateConfium] = useState(false);
    const handleUpdateConfiumClose = () => setShowUpdateConfium(false);
    const handleUpdateConfiumShow = () => setShowUpdateConfium(true);
    /**
     * リセット確認モーダルダイアログの制御用
     */
    const [showResetConfium, setShowResetConfium] = useState(false);
    const handleResetConfiumClose = () => setShowResetConfium(false);
    const handleResetConfiumShow = () => setShowResetConfium(true);

    useEffect(() => {
        getAdmin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">管理者アカウント　編集</h1>
                            </header>
                        </div>
                        </section>
                    </section>

                    <div className="row row-cols-1 g-3">
                        <div className="col">
                            <label htmlFor="accountNo" className="form-label required">社員番号</label>
                            <div className="input-group">
                                <input type="text" {...register('accountNo', { required: true, pattern: /^[a-zA-Z0-9-]+$/ })} className="form-control shadow-sm" id="accountNo" value={admin.accountNo} onChange={onChangeAccountNo} placeholder="半角文字" autoFocus />
                            </div>
                            {errors.accountNo?.types?.required && <div className="text-danger">社員番号を入力してください</div>}
                            {errors.accountNo?.types?.pattern && <div className="text-danger">半角のa～zもしくはA～Zもしくは0～9もしくは-で入力してください</div>}
                            {isDuplicateAccountNo === true && <div className="text-danger">その社員番号は他のアカウントで使われています</div>}
                        </div>
                        <div className="col">
                            <label htmlFor="mail" className="form-label required">メールアドレス</label>
                            <input type="text" {...register('mail', { required: true, pattern: EMAIL_CHECK_PATTERN })} className="form-control shadow-sm" id="mail" value={admin.mail} onChange={onChangeMail} placeholder="半角文字" />
                            {errors.mail?.types?.required && <div className="text-danger">メールアドレスを入力してください</div>}
                            {errors.mail?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                            {isDuplicateMail === true && <div className="text-danger">そのメールアドレスは他のアカウントで使われています</div>}
                        </div>
                        <div className="col">
                            <label htmlFor="name" className="form-label required">氏名</label>
                            <input type="text" {...register('name', { required: true, })} className="form-control shadow-sm" id="name" value={admin.name} onChange={onChangeName} placeholder="" />
                            {errors.name?.types?.required && <div className="text-danger">氏名を入力してください</div>}
                        </div>
                        <div className="col">
                            <label htmlFor="contact" className="form-label required">連絡先</label>
                            <input type="text" {...register('contact', { required: true, pattern: /^[0-9-]+$/ })} className="form-control shadow-sm" id="contact" value={admin.contact} onChange={onChangeContact} placeholder="半角0～9およびハイフン" />
                            {errors.contact?.types?.required && <div className="text-danger">連絡先を入力してください</div>}
                            {errors.contact?.types?.pattern && <div className="text-danger">半角0～9およびハイフンで入力してください</div>}
                        </div>
                        <div className="col">
                            <label htmlFor="password" className="form-label required">パスワード</label>
                            <div className="input-group">
                                <input type={isRevealPassword ? 'text' : 'password'} {...register('password', { required:true, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control shadow-sm" id="password" value={admin.password} onChange={onChangePassword} placeholder="半角8文字以上"/>
                                <span onClick={togglePassword} role="presentation" className="PasswordReveal">
                                    {isRevealPassword ? (
                                        <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                                    ) : (
                                        <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                                    )}
                                </span>
                            </div>
                            {errors.password?.types?.required && <div className="text-danger">パスワードを入力してください</div>}
                            {errors.password?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                            {errors.password?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={handleUpdateConfiumShow}>更新する</button>
                                <button className="btn btn-lg btn-primary m-1" style={{ width: 150 }} type="button" onClick={handleResetConfiumShow}>リセット</button>
                            </div>
                        </div>
                    </div>

                    {/*更新確認ダイアログ*/}
                    <Modal show={showUpdateConfium} onHide={handleUpdateConfiumClose}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>更新します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{ width: 150 }} onClick={handleUpdateConfiumClose}>いいえ</Button>
                                <button className="btn btn-primary ms-auto m-1" style={{ width: 150 }} type="submit" onClick={handleUpdateConfiumClose}>はい</button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                    {/*リセット確認ダイアログ*/}
                    <Modal show={showResetConfium} onHide={handleResetConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>内容をリセットします。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ width: 150 }} onClick={handleResetConfiumClose}>いいえ</Button>
                            <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnReset}>はい</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
            <Footer />
        </>
    );
};
