import { API, Auth } from 'aws-amplify';
//import { getOriginalNode } from 'typescript';
import { MAdmin, MSalon } from '../API';

//import awsExports from '../aws-exports';
//Amplify.configure(awsExports);
let nextToken;
export const useCognito = () => {

    /**
      * ユーザーの取得
      * 
      * @param   {string} username 取得するユーザ名(email) 
      * @returns {object} ユーザオブジェクト、存在しない場合はnull 
      */
    async function getUser(username: string) {

        try {
            let apiName = 'AdminQueries';
            let path = '/getUser';
            let myInit = {
                queryStringParameters: {
                    "username": username,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            const result = await API.get(apiName, path, myInit);
            return result;
        } catch (e) {
            console.log(e)
            return (null);
        }
    }

    async function listAdmins() {

        try {
            let apiName = 'AdminQueries';
            let path = '/listUsersInGroup';
            let myInit = {
                queryStringParameters: {
                    "groupname": "Admins",
                    "token": nextToken
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            const { NextToken, ...rest } = await API.get(apiName, path, myInit);
            nextToken = NextToken;
            return rest;
        } catch (e) {
            console.log(e)
            return (e);
        }

    }

    const setUserPassword = async (username: string, password: string) => {
        try {

            const apiName = 'AdminQueries';
            const path = '/setUserPassword';
            const params = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                },
                body: {
                    username,
                    password,
                },
            };

            const result = await API.post(apiName, path, params);
            return result;
        } catch (e) {
            console.log(e);
            return (e)
        }
    };

    const addUserToGroup = async (username: string, groupname: string) => {
        try {

            const apiName = 'AdminQueries';
            const path = '/addUserToGroup';
            const params = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                },
                body: {
                    username,
                    groupname,
                },
            };

            const result = await API.post(apiName, path, params);
            return result;
        } catch (e) {
            console.log(e);
            return (e)
        }
    };

    type UserAttribute = {
        Name: string;
        Value: string
    }

    const createUser = async (username: string, userAttributes?: UserAttribute[]) => {
        try {

            const apiName = 'AdminQueries';
            const path = '/createUser';
            const params = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                },
                body: {
                    username,
                    userAttributes,
                },
            };

            const result = await API.post(apiName, path, params);
            return result;
        } catch (e) {
            console.log(e);
            return (e)
        }
    };

    const updateUser = async (username: string, userAttributes?: UserAttribute[]) => {
        const apiName = 'AdminQueries';
        const path = '/updateUser';
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
            body: {
                username,
                userAttributes,
            },

        };
        return await API.post(apiName, path, params);
    };

    const deleteUser = async (username: string) => {

        try {
            const apiName = 'AdminQueries';
            const path = '/deleteUser';
            const params = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                },
                body: { username }
            };
            return await API.post(apiName, path, params);
        } catch (e) {
            console.log(e);
        }
    }

    // サインアップ
    const signUp = async (username: string, password: string) => {
        try {
            const { user } = await Auth.signUp({
                username,
                password
                , attributes: {
                    email_verified: 'true'
                }
            });
            console.log(user);
            return (user);
        } catch (error) {
            console.log('error signing up', error);
        }
        return (null);
    }

    // サインアップ確認
    const confirmSignUp = async (username: string, code: string) => {
        try {
            await Auth.confirmSignUp(username, code)

        } catch (error) {
            console.log('error confirming sign up', error);
        };
    }

    // サインイン
    const signIn = async (username: string, password: string) => {
        try {
            const user = await Auth.signIn(username, password);
            console.log(user);
            return (user);
        } catch (err) {
            console.log('error resending code: ', err);
            return (null);
        }
    }

    // 確認コード再送信
    const resendConfirmationCode = async (username: string) => {
        try {
            await Auth.resendSignUp(username);
            console.log('code resending successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    // サインアウト
    const signOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out:', error);
        }
    }

    // パスワード変更
    const changePassword = async (oldPassword: string, newPassword: string) => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    // パスワード紛失
    // Send confirmation code to user's email
    const forgotPassword = async (username: string) => {
        Auth.forgotPassword(username)
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    // Collect confirmation code and new password , then 
    const forgotPasswordSubmit = async (username: string, code: string, new_password: string) => {
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(data => console.log(data))
            .catch(err => console.log(err))
    }
    /**
    * MSalonから担当者3名のCognitoユーザーを一括削除
    * 
    * @param    {MSalon}    salonAccount    一括生成するMSalonオブジェクト 
    * @returns  {string[]}                  生成した３名のCognitoユーザID配列
    *
    */
    const deleteSalonCognitoUserAll = async (salon: MSalon) => {

        if (salon.personelCognitoUserId1 !== null && salon.personelCognitoUserId1 !== 'null' && salon.personelMail1 !== '') {
            await deleteUser(salon.personelCognitoUserId1);
        }
        if (salon.personelCognitoUserId2 !== null && salon.personelCognitoUserId2 !== 'null' && salon.personelMail2 !== '') {
            await deleteUser(salon.personelCognitoUserId2);
        }
        if (salon.personelCognitoUserId2 !== null && salon.personelCognitoUserId3 !== 'null' && salon.personelMail3 !== '') {
            await deleteUser(salon.personelCognitoUserId3);
        }

    }

    /**
    * MSalonから担当者3名のCognitoユーザーを一括生成
    * 
    * @param    {MSalon}    salonAccount    一括生成するMSalonオブジェクト 
    * @returns  {string[]}                  生成した３名のCognitoユーザID配列
    *
    */
    const createSalonCognitoUserAll = async (salonAccount: MSalon) => {

        const personel1Id = await createSalonCognitoUser(1, salonAccount);

        const personel2Id = await createSalonCognitoUser(2, salonAccount);

        const personel3Id = await createSalonCognitoUser(3, salonAccount);

        return ([personel1Id, personel2Id, personel3Id])
    }
    /**
    * MSalonから指定番号の担当者のCognitoユーザーを生成する。
    * 
    * @param    {number}    personalNo      生成対象の担当者番号 
    * @param    {MSalon}    salonAccount    サロンアカウントオブジェクト 
    * @returns  {string}                    生成したCognitoユーザID
    *
    */
    const createSalonCognitoUser = async (personelNo: number, salonAccount: MSalon) => {

        try {
            const { personelMail, password } = getTargetPersonelIdPass(personelNo, salonAccount);
            if (personelMail !== '') { // mailが設定されている場合のみ更新

                const attrs = [
                    { Name: 'email', Value: personelMail },
                    { Name: 'email_verified', Value: 'true' }
                ]

                const user = await createUser(personelMail, attrs);
                console.log(user);
                if (user.response !== undefined && user.response.status !== 200) {
                    throw new Error(user.response.data.message);
                }

                const pass = await setUserPassword(personelMail, password);
                console.log(pass);
                if (pass.response !== undefined && pass.response.status !== 200) {
                    throw new Error(pass.response.data.message);
                }

                return (user.User.Username);

            } else {
                return ('');
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    /**
    * MSalonから指定番号の担当者のId,Passを取得する。
    * 
    * @param    {number}    personalNo      生成対象の担当者番号 
    * @param    {MSalon}    salonAccount    サロンアカウントオブジェクト 
    * @returns  {string}                    生成したCognitoユーザID
    *
    */
    const getTargetPersonelIdPass = (personelNo: number, salonAccount: MSalon) => {

        let personelMail = '';
        let password = '';
        let cognitoId = '';

        switch (personelNo) {
            case 1:
                personelMail = salonAccount.personelMail1;
                password = salonAccount.personelPassword1;
                cognitoId = salonAccount.personelCognitoUserId1;
                break;
            case 2:
                personelMail = salonAccount.personelMail2;
                password = salonAccount.personelPassword2;
                cognitoId = salonAccount.personelCognitoUserId2;
                break;
            case 3:
                personelMail = salonAccount.personelMail3;
                password = salonAccount.personelPassword3;
                cognitoId = salonAccount.personelCognitoUserId3;
                break;
        }

        return ({ 'personelMail': personelMail, 'password': password, 'cognitoId': cognitoId });

    }
    /**
    * MSalonから指定番号の担当者のId,Passを取得する。
    * 
    * @param    {number}    personalNo      生成対象の担当者番号 
    * @param    {MSalon}    salon           サロンアカウントオブジェクト 
    * @param    {string}    cognitoId       セットするCognitoId 
    * @returns
    *
    */
    const setTargetPersonelCognitoId = (personelNo: number, salon: MSalon, cognitoId: string) => {

        switch (personelNo) {
            case 1:
                salon.personelCognitoUserId1 = cognitoId;
                if ( cognitoId === '' ){
                    salon.personelName1 = '' ;
                    salon.personelMail1 = '' ;
                    salon.personelPassword1 = '' ; 
                }
                break;
            case 2:
                salon.personelCognitoUserId2 = cognitoId;
                if ( cognitoId === '' ){
                    salon.personelName1 = '' ;
                    salon.personelMail1 = '' ;
                    salon.personelPassword1 = '' ; 
                }
                break;
            case 3:
                salon.personelCognitoUserId3 = cognitoId;
                if ( cognitoId === '' ){
                    salon.personelName1 = '' ;
                    salon.personelMail1 = '' ;
                    salon.personelPassword1 = '' ; 
                }
                break;
        }
    }

    /**
    * 変更前のMSalonと変更後のMSalonを比較して
    * サロン情報担当者の担当者情報をCognitoへアップデートする
    * 
    * @param {MSalon}salonOrg   変更前のMSalonオブジェクト 
    * @param {MSalon}salon      変更後のMSalonオブジェクト 
    * @returns          true  : 変更ありアップデート処理済み
    *                   false : 変更なしアップデートを実行しなかった
    *                   エラー時:エラーオブジェクト        
    */
    const updateSalonCognitoUser = async (salonOrg: MSalon, salon: MSalon) => {

        try {
            var updateMSalon = Object.assign( {}, salon); // 実体コピー

            for (let i = 1; i <= 3; i++) {

                const original = getTargetPersonelIdPass(i, salonOrg);
                const update = getTargetPersonelIdPass(i, salon);

                // メールアドレスが変更された（パスワードと両方変更含む)            
                if (original.personelMail !== update.personelMail) {
                    // 一度削除して再作成
                    if (original.cognitoId !== '') {
                        const user = await deleteUser(original.cognitoId);
                        setTargetPersonelCognitoId(i, salon, '');
                        console.log(user);
                    }

                    if (update.personelMail !== '') { // メールがブランクなら削除されたとして追加しない
                        const CognitoId = await createSalonCognitoUser(i, updateMSalon);
                        setTargetPersonelCognitoId(i, updateMSalon, CognitoId)
                        const pass = await setUserPassword(CognitoId, update.password);
                        console.log(pass);
                        if (pass.response !== undefined && pass.response.status !== 200) {
                            throw new Error(pass.response.data.message);
                        }
                    }

                } else if (original.password !== update.password) {
                    // パスワードのみ変更
                    const pass = await setUserPassword(update.cognitoId, update.password);
                    console.log(pass);
                    if (pass.response !== undefined && pass.response.status !== 200) {
                        throw new Error(pass.response.data.message);
                    }
                } else {
                    // 変更なし
                    console.log(`pernanel${i} no change skip`);
                }
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    /**
        * 変更前のMAdminと変更後のMAdminを比較して
        * 管理担当者情報をCognitoへアップデートする
        * 
        * @param {MAdmin}adminOrg   変更前のMAdminオブジェクト 
        * @param {MAdmin}admin      変更後のMAdminオブジェクト 
        * @returns          true  : 変更ありアップデート処理済み
        *                   false : 変更なしアップデートを実行しなかった
        *                   エラー時:エラーオブジェクト        
        */
    const updateAdminCognitoUser = async (adminOrg: MAdmin, admin: MAdmin) => {

        try {
            // メールアドレスが変更された（パスワードと両方変更含む)            
            if (adminOrg.mail !== admin.mail) {
                // 一度削除して再作成
                if (adminOrg.cognitoUserId !== '') {
                    const user = await deleteUser(adminOrg.cognitoUserId);
                    console.log(user);
                }

                const attrs = [
                    { Name: 'email', Value: admin.mail },
                    { Name: 'email_verified', Value: 'true' }
                  ]
            
                const user = await createUser(admin.mail, attrs);
                console.log(user);
                const pass = await setUserPassword(user.User.Username, admin.password );
                console.log(pass);
                const group = await addUserToGroup(user.User.Username, 'Admins');
                console.log(group);
                
                admin.cognitoUserId = user.User.Username

                return ( true );

            } else if (adminOrg.password !== admin.password) {
                // パスワードのみ変更
                const pass = await setUserPassword(admin.cognitoUserId, admin.password);
                console.log(pass);
                if (pass.response !== undefined && pass.response.status !== 200) {
                    throw new Error(pass.response.data.message);                
                }
                return ( true )
            } else {
                return ( false ) ; 
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        signUp
        , confirmSignUp
        , signIn
        , resendConfirmationCode
        , signOut
        , changePassword
        , forgotPassword
        , forgotPasswordSubmit
        , createUser
        , updateUser
        , deleteUser
        , listAdmins
        , setUserPassword
        , addUserToGroup
        , createSalonCognitoUserAll
        , updateSalonCognitoUser
        , deleteSalonCognitoUserAll
        , getUser
        , updateAdminCognitoUser
    }
}