import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { MSalonMember, MService, CreateMemberUseServicesInput, DeleteMemberUseServicesInput, TServicePeriod } from './../API';
import { createMemberUseServices, deleteMemberUseServices } from "../graphql/mutations";
import { useMemberUseServicePeriods } from './useMemberUseServicePeriods';

export const useMemberUseServices = () => {

    // useMemberUseServicePeriodsを読み込む
    const { createServicePeriod, deleteServicePeriod, updateServicePeriod } = useMemberUseServicePeriods();

    const findUsingService = (serviceMenuId: String, member: MSalonMember): string => {

        let usingServiceId: string = "";

        /*　ワーニング対応MAP削除
        member.useServices?.items.map((useService) => {
            if (serviceMenuId === useService?.mServiceID) {
                usingServiceId = useService.id;
            }
        })
        */

        for (const useService of member.useServices?.items) {
            if (serviceMenuId === useService?.mServiceID) {
                usingServiceId = useService.id;
            }
        }

        return usingServiceId;
    }

    const createMemberUseServiceMenus = async (memberId: string
        , useServices: MService[]) => {

        useServices.map(async (useService, index) => {
            await createUseServices(memberId, useService.id);
        })
    }

    const updateMemberUseServiceMenus = (member: MSalonMember
        , serviceMenus: MService[], checks: boolean[], servicePeriods: TServicePeriod[]) => {

        serviceMenus.map(async (serviceMenu, index) => {
            const usingServiceId = findUsingService(serviceMenu.id, member);
            if (checks[index]) {   // チェック有り                
                if (usingServiceId === "") {   // チェック有りで未利用（追加)                    // 何もしない
                    await createUseServices(member.id, serviceMenu.id)
                    const editPeriod = servicePeriods[index];
                    await createServicePeriod(member, editPeriod);
                } else {
                    //　利用中の場合は、servicePeriodを更新する
                    const editPeriod = servicePeriods[index];
                    const memberPeriod = findUsingPeriod(serviceMenu.serviceId, member);
                    if (memberPeriod) {
                        // 期間が変更されている場合は、更新する
                        if ((memberPeriod.startDate !== editPeriod.startDate)
                            || (memberPeriod.endDate !== editPeriod.endDate)) {
                            memberPeriod.startDate = editPeriod.startDate;
                            memberPeriod.endDate = editPeriod.endDate;
                            await updateServicePeriod(memberPeriod);
                        }
                    } else {
                        if (editPeriod.startDate !== "" || editPeriod.endDate !== "") {
                            await createServicePeriod(member, editPeriod);
                        }
                    }
                }
            } else { // チェック無し
                if (usingServiceId !== "") {   // チェック無しで利用中（削除)                    // 何もしない
                    await deleteUseServices(usingServiceId)
                    // memberのservicePeriodsから該当するものを取得して削除する
                    const period = member.useServicePeriod?.items.find((period) => period.serviceId === serviceMenu.serviceId);
                    if (period) {
                        await deleteServicePeriod(period);
                    };
                }
            }
        })
    }

    // memberのservicePeriodsから引数でしていされたserviceMenuIdに一致する
    // servicePeriodを取得する
    const findUsingPeriod = (serviceMenuId: String, member: MSalonMember) => {

        const servicePeriod = member.useServicePeriod?.items.find((period) => period.serviceId === serviceMenuId);
        return servicePeriod;
    }
    // memberのservicePeriodsと引数のservicePeriodsを比較して、
    // 変更のあったものを更新する
    //const updatePeriods = async (member: MSalonMember, servicePeriods: TServicePeriod[]) => {

    //}

    const createUseServices = async (memberId: string, serviceMenuId: string) => {
        const res = (await API.graphql(graphqlOperation(createMemberUseServices, {
            //input:{
            //    mSalonMemberID : `${memberId}` ,
            //    mServiceID : `${serviceMenuId}` 
            //} ,
            input: {
                mServiceID: `${serviceMenuId}`,
                mSalonMemberID: `${memberId}`
            }

        })) as GraphQLResult<CreateMemberUseServicesInput>)

        console.log(res)

    }

    const deleteUseServices = async (usingServiceId: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(deleteMemberUseServices, {
                input: {
                    id: `${usingServiceId}`
                },
            })) as GraphQLResult<DeleteMemberUseServicesInput>)

            console.log(res)

        } catch (error) {
            console.log(error)
        }
    }

    return { createMemberUseServiceMenus, updateMemberUseServiceMenus, deleteUseServices }
}
