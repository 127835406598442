import loading from "../images/loading.gif" ;

export const Loading = () => {
    return (
        <>
            <div style={{position:'absolute' , top:'50%' , left:'50%', transform:'translateY(-50%) translateX(-50%)' }}>
                <img src={loading} alt='loading animation' />
            </div>
        </>
    )
} 