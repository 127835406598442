import { useEffect, useState } from 'react';
import { /*useLocation, */useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal} from 'react-bootstrap';
import { useBillingUploads } from '../../hooks/useBillingUploads';
import { TUploadHeader, TUploadHeadUUIDs } from '../../API';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateTUploadHeadUUIDs } from '../../graphql/subscriptions';

export const BillResultCSVInfo = (props) => {

    const { setUser, setAuthState, userName } = props;

    const { getUploadHeaders } = useBillingUploads();
    const [uploadHeaders, setUploadHeaders] = useState<TUploadHeader[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uuids, setUuids] = useState<string[]>([]);

    const ids = [];
    //const { search } = useLocation();
    //const query = new URLSearchParams(search);
    //    const idstring = query.get("uploadIds");
    //let idstring = "" ;

    useEffect(() => {
        //const ids = idstring.split(',') ; 

        // サブスクリプションでIDが格納されるの待つ
        // API.subscripton
        //init(ids);

        // unsubscribe(購読解除)で型エラーが出るので今回はanyで受ける
        const onCreate: any = API.graphql(graphqlOperation(onCreateTUploadHeadUUIDs));

        onCreate.subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value });
                // 更新されたデータをリストに追加

                const newMessage = value.data.onCreateTUploadHeadUUIDs as TUploadHeadUUIDs;
                ids.push(newMessage.UUID);

                if (newMessage.status === "last") {
                    //onCreate.unsubscribe();
                    init(ids);
                    setUuids(ids);
                }
            },
            error: error => console.warn(error)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const init = async (headIds: string[]) => {

        const headers = await getUploadHeaders(headIds)
        console.log("headers");
        console.log(headers);
        setUploadHeaders(headers);
        setIsLoading(false);
    }

    const navigate = useNavigate();
    const OnClickRegist = () => {
        navigate(`/BillResultCSVUploadResult?uploadIds=${uuids}`)
    }
    const OnClickReturn = () => navigate('/BillResultCSVUpload')

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <>
                    <div className="fs-1 m-5 align-items-center text-center">アップロードファイルの解析中</div>
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">結果データファイルアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col">
                            <header className="pb-2 mb-2 d-flex align-items-center ">
                                <h1 className="fs-4 m-0 ">アップロードファイル情報</h1>
                            </header>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-12">
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">アップロード日時</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders[0].uploadDateTime}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">アップロードファイル名</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders[0].uploadFileName}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">種別コード（91:固定）</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders[0].typeCode}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">請求対象区分コード</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders.reduce((p, x) => p + ` , ${x.consignorCode.substring(6, 8)}`, '').substring(2)}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">委託者名</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders[0].consignorName}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">振替月日(YYDD)</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders[0].transferDate}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">データ件数</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders.reduce((p, x) => p + Number(x.totalNumber), 0)} 件</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">合計金額</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">&yen; {uploadHeaders.reduce((p, x) => p + Number(x.totalFee), 0).toLocaleString()}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">振替完了件数</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders.reduce((p, x) => p + Number(x.totalNumberOfSuccess), 0)} 件</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">振替完了金額</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">&yen; {uploadHeaders.reduce((p, x) => p + Number(x.totalFeeOfSuccess), 0).toLocaleString()}</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">振替失敗件数</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">{uploadHeaders.reduce((p, x) => p + Number(x.totalNumberOfFailure), 0)} 件</span>}
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">振替失敗金額</h6>
                                    </div>
                                    {uploadHeaders[0] && <span className="text-muted">&yen; {uploadHeaders.reduce((p, x) => p + Number(x.totalFeeOfFailure), 0).toLocaleString()}</span>}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-7">
                        </div>
                        <div className="col-md-5 d-flex">
                            <button className="btn btn-lg btn-primary ms-auto shadow-sm m-1" style={{ width: 140 }} type="button" onClick={OnClickRegist}>登録</button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};