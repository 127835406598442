import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
//import { useSalonPayment } from '../../hooks/useSalonPayment';
import { TSalonPayment, TBillingData } from '../../API';
import { useSalonPayment } from '../../hooks/useSalonPayment';
import { useBilling } from '../../hooks/useBilling';
//import { inherits } from 'util';
import { useCommonTools } from '../../hooks/useCommonTools';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const PayCallHistoryDetailResult = ( props ) => {

    const { setUser, setAuthState , userName } = props;
    const { getSalonPayment } = useSalonPayment();
    const { getTransferResultString, dateFormatString } = useCommonTools();
    const { listBillingDataByTransferDateWithStoreId } = useBilling();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const transferDate_storeId = query.get('transferDate_StoreId')
    const paymentId = query.get('paymentId')

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [billingData, setBillingData] = useState<TBillingData[]>([]);
    const [furikaeSelect, setFurikaeSelect] = useState<number>(0);
    const [salonPayment, setSalonPayment] = useState<TSalonPayment>();


    const FurikaeFilter = async (type: number) => {
        setIsLoading(true);

        const resultBD = await listBillingDataByTransferDateWithStoreId(transferDate_storeId);

        switch (type) {
            case 1:
                // 振替済み
                setBillingData(resultBD.filter(el => (el.transferResultCode === '0')));
                break;
            case 2:
                // 振替済み以外
                setBillingData(resultBD.filter(el => (el.transferResultCode !== '0')));
                break;
            default:
                // 全て
                setBillingData(resultBD);
                break;
        }
        setFurikaeSelect(type);
        setIsLoading(false);
    }

    const init = async () => {
        const resultSP = await getSalonPayment(paymentId);
        setSalonPayment(resultSP.data.getTSalonPayment);

        const result = await listBillingDataByTransferDateWithStoreId(transferDate_storeId);
        setBillingData(result);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    const OnClickReturn = () => {
        navigate(`/PayCallHistoryDetail?paymentId=${paymentId}`)
    }
    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            { isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">支払い通知書　請求明細</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3 mt-0 mb-2">
                        <div className="col-md-12">
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">契約店舗</h6>
                                    </div>
                                    <span>{salonPayment.storeName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替日付</h6>
                                    </div>
                                    <span>{dateFormatString(salonPayment.transferDate)}</span>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                        <label className="btn btn-secondary active">
                            <input type="radio" name="options" id="option1" autoComplete="off" onClick={() => FurikaeFilter(0)} checked={furikaeSelect === 0}/> すべて
                        </label>
                        <label className="btn btn-secondary">
                            <input type="radio" name="options" id="option2" autoComplete="off" onClick={() => FurikaeFilter(1)} checked={furikaeSelect === 1}/> 振替済み
                        </label>
                        <label className="btn btn-secondary">
                            <input type="radio" name="options" id="option3" autoComplete="off" onClick={() => FurikaeFilter(2)} checked={furikaeSelect === 2}/> 振替済み以外
                        </label>
                    </div>

                    <section className="row mb-5">
                        <section className="col">
                            <table className="table table-striped table-hover table-bordered shadow-sm">
                                <thead>
                                    <tr className="text-center">
                                        <th>会員番号</th>
                                        <th>会員名</th>
                                        <th>銀行コード</th>
                                        {/*<th>銀行名</th>*/}
                                        <th>支店コード</th>
                                        {/*<th>支店名</th>*/}
                                        <th>金額</th>
                                        <th>振替結果</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {billingData.length === 0 &&
                                        <tr>
                                            <td colSpan={8}>データはありません</td>
                                        </tr>
                                        }
                                        {billingData.map((bill, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{bill.salonMember?.memberId}</td>
                                            <td>{bill.salonMember?.memberName}</td>
                                            <td className="text-center">{bill?.transferBankCode}</td>
                                            {/*<td>{bill.salonMember.bankName}</td>*/}
                                            <td className="text-center">{bill?.transferBankBranchCode}</td>
                                            {/*<td>{bill.salonMember.bankBranchName}</td>*/}
                                            <td className="d-flex justify-content-end">&yen;{Number(bill?.transferAmount)?.toLocaleString()}</td>
                                            <td>{getTransferResultString(bill?.transferResultCode)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};