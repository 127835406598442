import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom" ;
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { useSalon } from "../../hooks/useSalon";
import { useService } from "../../hooks/useService";
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Button, Modal } from 'react-bootstrap';
import { MAX_SERVICE_NUM } from '../../global-define';
import '../../css/common.css';
import { MSalon } from '../../API';

export const StoreServiceMenuList = ( props ) => {
    
    const { setUser, setAuthState , userName } = props;

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const storeId = query.get("storeId");
 
//    const [nextToken, setNextToken] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getSalonAccount } = useSalon();
    const { serviceMenus, getServiceMenus } = useService();
    const [ salonAccount , setSalonAccount ] = useState<MSalon>();


    const navigate = useNavigate(); 
    
    const OnClickEdit = ( id: string ) => navigate(`/StoreServiceMenuEdit?mServiceId=${id}&storeId=${storeId}`)
    const OnClickReturn = () => navigate(`/StoreMenu?storeId=${storeId}`)
    const OnClickAddNew = () => {

        // サービス登録数が上限以内の時のみ追加可能
        if (MAX_SERVICE_NUM <= serviceMenus.length) {
            // サービス登録数上限
            setShowCantAddConfirm(true) ;    //削除不能メッセージを表示
        } else {
            // サービス登録可能

            let nextId = 0 
            
            /* ワーニング対応Map削除
            serviceMenus.map(( service ) => {
                if ( nextId < Number(service.serviceId) ){
                    nextId = Number(service.serviceId)
                }
            })
            */

            for ( const service of serviceMenus ){
                if ( nextId < Number(service.serviceId) ){
                    nextId = Number(service.serviceId)
                }
            }

            nextId ++;
            navigate(`/StoreServiceMenuAdd?storeId=${storeId}&nextId=${nextId.toString()}`) ;
        }
    }

    /**
     * サービス追加不能モーダルダイアログの制御用
     */
     const [showCantAddConfirm, setShowCantAddConfirm] = useState(false);
     const handleCantAddConfirmClose = () => setShowCantAddConfirm(false);

    const getData = async () => {
        const result = await getSalonAccount(storeId);
        setSalonAccount( result ) ; 

        await getServiceMenus(storeId);
        setIsLoading(false);
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
            <div className="container mt-5">
                <section className="row mb-4">
                    <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">サービスメニュー　一覧</h1>
                            </header>
                        </div>
                    </section>
                </section>

                <section className="row">
                    <section className="col-8 col-md-6">
                        <div className="input-group mb-3">
                            <h1 className="fs-4 m-0 ">選択店舗：{salonAccount.storeName}</h1>
                        </div>
                    </section>
                    <section className="col-4 col-md-6">
                        <div className="d-flex mb-3"> 
                            <button className="btn btn-primary ms-auto shadow-sm" id="addon2" style={{ width: 110 }} onClick={OnClickAddNew}>新規登録</button>
                        </div>
                    </section>
                </section>

                <section className="row mb-5">
                    <section className="col">
                        <table className="table table-hover table-bordered shadow-sm">
                            <thead>
                            <tr>
                                <th className="text-center" style={{width: 350}}>コース名称</th>
                                <th className="text-center" style={{width: 150}}>月額（税込み）</th>
                                <th className="text-center" style={{width: 100}}>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                                {((serviceMenus == null) || (serviceMenus.length === 0)) &&
                                    <tr><td colSpan={3}>データが登録されていません</td></tr>
                                }
                                { serviceMenus.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1 ).map((serviceMenu , index ) => (
                                    <tr key={index}>
                                        <td className="align-middle">{serviceMenu.courseName}</td>
                                        <td className="align-middle text-end">&yen;{serviceMenu.monthlyPrice.toLocaleString()}</td>
                                        <td className="align-middle">
                                            <div className="btn-toolbar">
                                                <div className="btn-group text-center mx-auto">
                                                    <button className="btn btn-primary shadow-sm" id="change" style={{width: 90}} onClick={() => OnClickEdit(serviceMenu.id)}>編集</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>

                    {/*サービス登録最大数ダイアログ*/}
                    <Modal show={showCantAddConfirm} onHide={handleCantAddConfirmClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>サービスメニューが最大の{MAX_SERVICE_NUM}件登録されています。<br/>これ以上サービスメニューは登録できません。</Modal.Body>
                        <Modal.Footer>
                            <Button variant="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={handleCantAddConfirmClose}>確認</Button>
                        </Modal.Footer>
                    </Modal>

                </section>
            </div>
            )}
            <Footer />
        </>
    ); 
};