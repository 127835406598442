import React, { useEffect, useState , useContext } from 'react';
import { SystemSettingContext } from '../../providers/SystemSettingProvider';
import { API, graphqlOperation } from 'aws-amplify';
import { useNavigate } from "react-router-dom" ;
import { ListMSystemChargesQuery, MSystemCharge } from '../../API';
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { listMSystemCharges } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { CONSIGNOR_CODE , CONSIGNOR_NAME } from '../../global-define';
import '../../css/common.css';

//import { Button, Modal} from 'react-bootstrap';

export const SystemSettingDetail = ( props ) => {
    
    const { setUser, setAuthState , userName } = props;

    const navigate = useNavigate(); 
    const OnClickChange = () => navigate('/SystemSettingEdit')
    const OnClickReturn = () => navigate('/MainMenu')
    
    const { setSystemChargeContext } = useContext( SystemSettingContext ); 

    const [systemCharge, setSystemCharge] = useState<MSystemCharge>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [nextToken, setNextToken] = useState<string>(null);

    const getSystemCharge = async () => {
        let res = null;
        try{
            res = (await API.graphql(graphqlOperation(listMSystemCharges, {
                limit: 999999,
                nextToken: nextToken,
            })) as GraphQLResult<ListMSystemChargesQuery>);
        } catch (e) {
            console.log(`エラー発生 ${e.errors}`);
        }

        console.log(res);

        // 未登録（一度も登録していない、もしくはdeleteで削除され0件のとき）は登録を促すためEditに遷移
        if ((res === null) || (res.data.listMSystemCharges.items.length === 0)) {
            navigate('/SystemSettingEdit')
            return;
        }

        // 取得したデータをセット
        const mServices = res.data.listMSystemCharges.items as MSystemCharge[];
        setSystemCharge( mServices[0] ); 
        console.log( mServices[0] );
        setSystemChargeContext( mServices[0] )
        setNextToken(res.data.listMSystemCharges.nextToken);

        // ローディング解除
        setIsLoading(false);
    }

    useEffect(() => {
        getSystemCharge();
        // useEffectは起動時に引数なしを想定しているので第二引数は除外する（以下のコメントでVSCodeでの警告出力を抑制）
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            { isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">システム設定</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    
                    <div className="row g-3">

                        <div className="col-md-6">
                            <section className="row">
                                <section className="col">
                                    <header className="pb-2 mb-1 d-flex align-items-center">
                                        <h1 className="fs-4 m-0 ">＜料金情報＞</h1>
                                    </header>
                                </section>
                            </section>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label htmlFor="basicCharge" className="form-label">サブスクリプションサービス基本利用料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="basicCharge" value={systemCharge.basicCharge.toLocaleString()} disabled />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="pricePerCase" className="form-label">サブスクリプションサービス手数料</label>
                                    <div className="input-group">  
                                        <span className="input-group-text">１件あたり</span>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="pricePerCase" value={systemCharge.pricePerCase.toLocaleString()} disabled />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="bankTransferFee" className="form-label">振替手数料</label>
                                    <div className="input-group">  
                                    <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="bankTransferFee" value={systemCharge.bankTransferFee.toLocaleString()} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <section className="row">
                                <section className="col">
                                    <header className="pb-2 mb-1 d-flex align-items-center">
                                        <h1 className="fs-4 m-0 ">＜アプラス情報＞</h1>
                                    </header>
                                </section>
                            </section>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label htmlFor="basicCharge" className="form-label">委託者コード</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="consignorCode" value={CONSIGNOR_CODE} disabled />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="pricePerCase" className="form-label">委託者名</label>
                                    <div className="input-group">  
                                        <input type="text" className="form-control" id="consignorName" value={CONSIGNOR_NAME} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <button className="btn btn-lg btn-success mx-auto m-1" style={{ width: 150 }} type="button" onClick={OnClickChange}>編集する</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    ); 
};