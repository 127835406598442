import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { API, graphqlOperation } from 'aws-amplify';
import { listMSalons } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { ListMSalonsQuery, MSalon } from '../../API';
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

type Inputs = {
    search: string ;
}

export const StoreList = ( props ) => {
   
    const { setUser, setAuthState , userName } = props;
    const {
        register, 
        handleSubmit
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 
    
    const [salonAccounts, setSalonAccounts] = useState<MSalon[]>([]);
    const [nextToken, setNextToken] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');

    const navigate = useNavigate();
    const OnClickDetail = (salonAccount:MSalon) => { 
        navigate(`/StoreMenu?storeId=${salonAccount.storeId}`);
    }
    const OnClickReturn = () => navigate('/MainMenu')
    const onChangeSearch = (event) => setSearch(event.target.value);

    const getSalonAccounts = async () => {
        const res = (await API.graphql(graphqlOperation(listMSalons, {
            limit: 999999,
            nextToken: nextToken,
        })) as GraphQLResult<ListMSalonsQuery>);

        console.log(res);
        const mSalons = res.data.listMSalons.items as MSalon[];
        // 加盟店番号順にソートする
        mSalons.sort(function(a, b) {
            var storeIdA = a.storeId.toUpperCase(); // 大文字と小文字を無視する
            var storeIdB = b.storeId.toUpperCase(); // 大文字と小文字を無視する
            if (storeIdA < storeIdB) {
                return -1;
            }
            if (storeIdA > storeIdB) {
                return 1;
            }
        
            // accountNos must be equal
            return 0;
        });
        setSalonAccounts(mSalons)
        setNextToken(res.data.listMSalons.nextToken);
        setIsLoading(false);
    }

    const onSubmit = async () => {
        //----------------
        // 検索
        //----------------
        setIsLoading(true);
        console.log(search);
    
        // 登録済みのデータを抽出
        let res = null;
        try {
            res = (await API.graphql(graphqlOperation(listMSalons, {
                limit: 999999,
                nextToken: nextToken,
            })) as GraphQLResult<ListMSalonsQuery>);
        } catch (e) {
            console.log(`エラー発生 ${e.errors}`);
        }
        console.log(res);
        
        if ((res == null) || (res.data.listMSalons.items.length === 0))
        {
            // データ0件
            ;
        }
        else
        {
            if (0 < res.data.listMSalons.items.length) {
                // いずれかから部分一致に該当するデータを抽出する
                const mSalons = res.data.listMSalons.items.filter(el =>
                    (el.storeId.indexOf(`${search}`) !== -1) ||
                    (el.storeName.indexOf(`${search}`) !== -1) ||
                    (el.storeAddress1.indexOf(`${search}`) !== -1) ||
                    (el.storeAddress2.indexOf(`${search}`) !== -1) ||
                    (el.phone.indexOf(`${search}`) !== -1) ||
                    (el.personelName1.indexOf(`${search}`) !== -1) ||
                    (el.personelMail1.indexOf(`${search}`) !== -1) ||
                    (el.personelPassword1.indexOf(`${search}`) !== -1) ||
                    (el.personelName2.indexOf(`${search}`) !== -1) ||
                    (el.personelMail2.indexOf(`${search}`) !== -1) ||
                    (el.personelPassword2.indexOf(`${search}`) !== -1) ||
                    (el.personelName3.indexOf(`${search}`) !== -1) ||
                    (el.personelMail3.indexOf(`${search}`) !== -1) ||
                    (el.personelPassword3.indexOf(`${search}`) !== -1) ||
                    (el.bankCode.indexOf(`${search}`) !== -1) ||
                    (el.bankBranchCode.indexOf(`${search}`) !== -1) ||
                    (el.bankDepositType.indexOf(`${search}`) !== -1) ||
                    (el.bankAccountNumber.indexOf(`${search}`) !== -1) ||
                    (el.bankDepositerName.indexOf(`${search}`) !== -1)
                );
                // 加盟店番号順にソートする
                mSalons.sort(function(a, b) {
                    var storeIdA = a.storeId.toUpperCase(); // 大文字と小文字を無視する
                    var storeIdB = b.storeId.toUpperCase(); // 大文字と小文字を無視する
                    if (storeIdA < storeIdB) {
                        return -1;
                    }
                    if (storeIdA > storeIdB) {
                        return 1;
                    }
                
                    // accountNos must be equal
                    return 0;
                });

                setSalonAccounts(mSalons)
                setNextToken(res.data.listMSalons.nextToken);
            }
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        getSalonAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
            <div className="container mt-5">
                <section className="row mb-4">
                    <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">店舗選択</h1>
                            </header>
                        </div>
                    </section>
                </section>

                <section className="row">
                    <section className="col-8 col-md-6">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group mb-3 shadow-sm">
                                <input type="text" {...register('search')} className="form-control shadow-none" id="search" value={search} onChange={onChangeSearch} placeholder="検索キーワード" aria-label="検索キーワード" aria-describedby="search"/>
                                <span className="input-group-btn">
                                    <button type="submit" className="btn btn-primary shadow-none" >
                                    <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                        </form>
                    </section>
                </section>
                <section className="row mb-5">
                    <section className="col">
                        <table className="table table-hover table-bordered shadow-sm">
                            <thead>
                                <tr>
                                    <th className="text-center" style={{ width: 100 }}>店舗番号</th>
                                    <th className="text-center">店舗名</th>
                                    <th className="text-center" style={{ width: 110 }}>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {((salonAccounts == null) || (salonAccounts.length === 0)) &&
                                    <tr><td colSpan={3}>データが登録されていません</td></tr>
                                }
                                {salonAccounts.map((storeAccount, index) => (
                                    <tr key={index}>
                                        <th className="text-center align-middle">{storeAccount.storeId}</th>
                                        <td className="align-middle">{storeAccount.storeName}</td>
                                        <td>
                                            <button className="btn btn-primary shadow-sm" id="detail" style={{ width: 90 }} onClick={() => OnClickDetail(storeAccount)}>選択</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </section>
            </div>
            )}
            <Footer />
        </>
    );
};