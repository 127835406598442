import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { API, graphqlOperation } from 'aws-amplify';
import { listMAdmins } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { ListMAdminsQuery, CreateMAdminInput } from '../../API';
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

type Inputs = {
  search: string ;
}

export const AccountList = ( props ) => {
    
  const { setUser, setAuthState , userName } = props;

  const {
    register, 
    handleSubmit, 
  } = useForm<Inputs>({
      criteriaMode: "all",
      shouldFocusError: true,
  }); 

  const navigate = useNavigate();
  const OnClickAddNew = () => navigate('/AccountRegister')
  const OnClickDetail = (id) => navigate(`/AccountDetail/${id}`)
  const OnClickReturn = () => navigate('/MainMenu')
  const onChangeSearch = (event) => setSearch(event.target.value);

  const [admins, setAdmins] = useState<CreateMAdminInput[]>([]);
  const [search, setSearch] = useState<string>('');
  const [nextToken, setNextToken] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  const getAdmins = async () => {

    let res = null;
    try {
      res = (await API.graphql(graphqlOperation(listMAdmins, {
        limit: 999999,
        nextToken: nextToken,
      })) as GraphQLResult<ListMAdminsQuery>);
    } catch (e) {
      console.log(`エラー発生 ${e.errors}`);
    }
    console.log(res);

    if ((res === null) || (res.data.listMAdmins.items.length === 0))
    {
      // データ0件
      ;
    }
    else
    {
      const mAdmins = res.data.listMAdmins.items as CreateMAdminInput[];
      // 社員番号順にソートする
      mAdmins.sort(function(a, b) {
        var accountNoA = a.accountNo.toUpperCase(); // 大文字と小文字を無視する
        var accountNoB = b.accountNo.toUpperCase(); // 大文字と小文字を無視する
        if (accountNoA < accountNoB) {
          return -1;
        }
        if (accountNoA > accountNoB) {
          return 1;
        }
      
        // accountNos must be equal
        return 0;
      });
      setAdmins(mAdmins)
      setNextToken(res.data.listMAdmins.nextToken);
    }

    setIsLoading(false);
  }

  const onSubmit = async () => {
    //----------------
    // 検索
    //----------------
    setIsLoading(true);
    console.log(search);

    // 登録済みのデータを抽出
    let res = null;
    try {
        res = (await API.graphql(graphqlOperation(listMAdmins, {
            limit: 999999,
            nextToken: nextToken,
        })) as GraphQLResult<ListMAdminsQuery>);
    } catch (e) {
        console.log(`エラー発生 ${e.errors}`);
    }
    console.log(res);
    
    if (0 < res.data.listMAdmins.items.length) {
      // 社員番号、メールアドレス、名前、電話番号のいずれかから部分一致に該当するデータを抽出する
      const mAdmins = res.data.listMAdmins.items.filter(el =>
        (el.accountNo.indexOf(`${search}`) !== -1) ||
        (el.mail.indexOf(`${search}`) !== -1) ||
        (el.name.indexOf(`${search}`) !== -1) || 
        (el.contact.indexOf(`${search}`) !== -1)
      );
      // 社員番号順にソートする
      mAdmins.sort(function(a, b) {
        var accountNoA = a.accountNo.toUpperCase(); // 大文字と小文字を無視する
        var accountNoB = b.accountNo.toUpperCase(); // 大文字と小文字を無視する
        if (accountNoA < accountNoB) {
          return -1;
        }
        if (accountNoA > accountNoB) {
          return 1;
        }
      
        // accountNos must be equal
        return 0;
      });
      setAdmins(mAdmins)
      setNextToken(res.data.listMAdmins.nextToken);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mt-5">
          <section className="row mb-4">
            <section className="col">
              <div className="d-flex col-12 border-bottom">
                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                <header className="d-flex align-items-center">
                  <h1 className="fs-4 m-2">管理者アカウント　一覧</h1>
                </header>
              </div>
            </section>
          </section>
          
          <section className="row">
            <section className="col-8 col-md-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group mb-3">
                  <input type="text" {...register('search')} className="form-control shadow-none" id="search" value={search} onChange={onChangeSearch} placeholder="検索キーワード" aria-label="検索キーワード" aria-describedby="search"/>
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-primary shadow-none" >
                      <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                    </button>
                  </span>
                </div>
              </form>
            </section>

            <section className="col-4 col-md-6">
              <div className="d-flex mb-3">
                <button className="btn btn-primary ms-auto" id="addon2" style={{ width: 110 }} onClick={OnClickAddNew}>新規登録</button>
              </div>
            </section>

          </section>

          <section className="row mb-5">
            <section className="col">
              <table className="table table-hover table-bordered shadow-sm">
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: 100 }}>社員番号</th>
                    <th className="text-center">氏名</th>
                    <th className="text-center">メールアドレス</th>
                    <th className="text-center" style={{ width: 110 }}>操作</th>
                  </tr>
                </thead>
                <tbody>
                  {((admins == null) || (admins.length === 0)) &&
                    <tr><td colSpan={4}>データが登録されていません</td></tr>
                  }
                  {admins != null ? (admins.map((admin, index) => (
                    <tr key={index}>
                      <td className="text-center align-middle">{admin.accountNo}</td>
                      <td className="align-middle">{admin.name}</td>
                      <td className="align-middle">{admin.mail}</td>
                      <td><button className="btn btn-primary" id="detail" style={{ width: 90 }} onClick={() => OnClickDetail(admin.id)}>詳細</button></td>
                    </tr>
                  ))):(null)}
                </tbody>
              </table>
            </section>
          </section>
        </div>
      )}
      <Footer />
    </>
  );
};