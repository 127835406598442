import { useNavigate, useLocation } from "react-router-dom"
import { Header } from "../../atoms/Header"
import { Footer } from "../../atoms/Footer"
import React, { useContext, useEffect, useState } from "react";
import { SelectedSalonContext } from "../../providers/SelectedSalonProvider";
//import { useSalon } from "../../hooks/useSalon";
import { useSalonMembers } from "../../hooks/useSalonMembers";
import { useService } from "../../hooks/useService";
import { useMemberUseServices } from "../../hooks/useMemberUseServices";
import { useMemberUseServicePeriods } from "../../hooks/useMemberUseServicePeriods";
import { MSalonMember, MService, MSalon, TServicePeriod } from "../../API";
import { Loading } from '../../atoms/Loading';
import '../../css/common.css';
import { Button, Modal } from 'react-bootstrap';
import { DEPOSIT_TYPE_ORDINARY } from '../../global-define';
import { BsArrowLeftCircle } from 'react-icons/bs';

export const StoreMemberRegisterConfirm = (props) => {

    const { setUser, setAuthState, userName } = props;

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [salonAccount, setSalonAccount] = useState<MSalon>();

    const { selectedSalonContext, selectedMemberContext, selectedServiceMenusContext, selectedServicePeriodsContext, setSelectedMemberContext } = useContext(SelectedSalonContext);
    //const { salonAccount, setSalonAccount } = useSalon();
    const { createSalonMember } = useSalonMembers();
    const { isLoading, setIsLoading } = useService();
    const { createMemberUseServiceMenus } = useMemberUseServices();
    const { createServicePeriods } = useMemberUseServicePeriods();

    const [salonMember, setSalonMember] = useState<MSalonMember>();
    const [selectedMenus, setSelectedMenus] = useState<MService[]>();
    const [servicePeriods, setServicePeriods] = useState<TServicePeriod[]>([]);
    const [classNameTypeBank, setClassNameTypeBank] = useState<string>(null);
    const [classNameTypePost, setClassNameTypePost] = useState<string>(null);
    const [totalServiceCount, setTotalServiceCount] = useState<number>();

    useEffect(() => {

        setIsLoading(true);
        setSalonAccount(selectedSalonContext as MSalon);
        setSalonMember(selectedMemberContext as MSalonMember);
        setSelectedMenus(selectedServiceMenusContext as MService[]);
        setServicePeriods(selectedServicePeriodsContext as TServicePeriod[]);

        // 月額コースの選択数を取得
        setTotalServiceCount(selectedServiceMenusContext.length);

        if (selectedMemberContext.accountNumber != null && selectedMemberContext.accountNumber.length === 14) {
            // 郵便局
            setClassNameTypeBank("list-group mb-3 shadow-sm d-none");
            setClassNameTypePost("list-group mb-3 shadow-sm");
        } else {
            // 銀行
            setClassNameTypeBank("list-group mb-3 shadow-sm");
            setClassNameTypePost("list-group mb-3 shadow-sm d-none");
        }

        setIsLoading(false);

        // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 月額支払総額の計算（HTML内から呼び出される）
     * @returns 
     */
    const getMonthlyServicePrice = () => {
        let servicePrice = 0;
        let count = 0;
        selectedMenus?.map((service, index) => {
            servicePrice += Number(service.monthlyPrice);
            count += 1;
            return servicePrice;
        }
        )

        if (0 < count) {
            // １個以上のコースが選択されている場合は手数料を追加する
            servicePrice += selectedSalonContext.bankTransferFee;
        }

        return servicePrice;
    }

    const navigate = useNavigate();
    const OnClickRegister = async () => {

        setIsLoading(true);

        handleCreateConfiumClose();//ダイアログ閉じる

        const res = await createSalonMember(salonMember);

        await createMemberUseServiceMenus(String(res.data?.createMSalonMember?.id), selectedMenus as MService[]);
        await createServicePeriods(res.data?.createMSalonMember as MSalonMember, selectedMenus, servicePeriods);

        // 登録に成功した時点でselectedMemberContextをクリアする
        setSelectedMemberContext(null);
        setIsLoading(false);

        navigate(`/StoreMemberList?storeId=${query.get("storeId")}`);
    }
    /**
     * 戻るボタン押下
     */
    const OnClickReturn = () => {
        setSalonAccount(selectedSalonContext as MSalon);
        setSalonMember(selectedMemberContext as MSalonMember);
        setSelectedMenus(selectedServiceMenusContext as MService[]);
        navigate(`/StoreMemberRegister?storeId=${query.get("storeId")}`)
    }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showCreateConfium, setShowCreateConfium] = useState(false);
    const handleCreateConfiumClose = () => setShowCreateConfium(false);
    const handleCreateConfiumShow = () => setShowCreateConfium(true);

    /**
     * リロード時のイベント
     * @param event 
     */
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
        // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
        event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
    };
/*
    const getServiceStartDate = (serviceId: string) => {

        // selectedServicePeriodsContextからserviceIdをキーにして、開始日を取得する
        const servicePeriod = selectedServicePeriodsContext.find((servicePeriod) => servicePeriod.serviceId === serviceId);
        if (servicePeriod != null) {
            return servicePeriod.startDate;
        }

        return '';
    }
*/
    const getServicePeriod = (serviceId: string) => {
        // selectedServicePeriodsContextからserviceIdをキーにして,servicePeriodを取得する
        const servicePeriod = selectedServicePeriodsContext.find(
            (servicePeriod) => {
                if (servicePeriod) {
                    return servicePeriod.serviceId === serviceId
                } else {
                    return false;
                }
            }
        );
        if (servicePeriod != null) {
            return servicePeriod;
        }
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">登録内容確認</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-7 ">
                            <h5>会員情報</h5>
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">契約店舗</h6>
                                    </div>
                                    <span className="text-muted">{String(salonAccount?.storeName)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">申込日</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.applicationDate)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">会員番号</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.memberId)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">お名前</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.memberName)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">フリガナ</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.furigana)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">生年月日</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.dateOfBirth)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">郵便番号</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.zipCode)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">住所１</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.address1)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">住所２</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.address2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">電話番号</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.phoneNumber)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">携帯番号</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.phoneNumberMobile)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">メールアドレス</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.mailAddress)}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5 order-md-last">
                            {/*申込コース*/}
                            <h5 className="d-flex justify-content-between align-items-center">
                                申込コース
                            </h5>
                            <ul className="list-group mb-3 shadow-sm">
                                {selectedMenus?.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1).map((serviceMenu, index) => (
                                    <>
                                        <li className="list-group-item d-flex justify-content-between lh-sm" key={index}>
                                            <div>
                                                <h6 className="my-0">
                                                    <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu?.courseName}</label>
                                                </h6>
                                            </div>
                                            <span className="text-muted">&yen;{serviceMenu?.monthlyPrice.toLocaleString()}</span>
                                        </li>
                                        <li className="list-group-item">
                                            <p style={{ fontSize: 13 }} className="ms-4 mb-0 text-end">振替開始：{getServicePeriod(serviceMenu.serviceId).startDate}　～　振替終了：{getServicePeriod(serviceMenu.serviceId).endDate}</p>
                                        </li>
                                    </>
                                ))}
                                {0 < totalServiceCount &&
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0"><b>振替手数料</b></h6>
                                        </div>
                                        <span className="text-muted"><b>&yen;{selectedSalonContext.bankTransferFee.toLocaleString()}</b></span>
                                    </li>
                                }
                                <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                    <div>
                                        <h6 className="my-0"><b>毎月振替金額</b></h6>
                                    </div>
                                    <span className="text-muted"><b>&yen;{getMonthlyServicePrice().toLocaleString()}</b></span>
                                </li>
                            </ul>

                            {/*預金口座振替情報*/}
                            <h4 className="d-flex justify-content-between align-items-center mt-4">
                                預金口座振替情報
                            </h4>
                            <ul className={classNameTypeBank}>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">口座登録日</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.bankSetDate)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">銀行名</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.bankCode} {salonMember?.bankName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">支店名</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.bankBranchCode} {salonMember?.bankBranchName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">預金種別</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.depositType === DEPOSIT_TYPE_ORDINARY ? <span>普通</span> : <span>当座</span>}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">口座番号</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.accountNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">預金者名（カナ）</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.depositerName}</span>
                                </li>
                            </ul>
                            <ul className={classNameTypePost}>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">ゆうちょ銀行</h6>
                                    </div>
                                    <span className="text-muted"></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">記号番号</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.accountNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">預金者名（カナ）</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.depositerName}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-7">
                        </div>
                        <div className="col-md-5 d-flex">
                            <button className="btn btn-lg btn-primary ms-auto shadow-sm m-1" style={{ width: 120 }} type="button" onClick={handleCreateConfiumShow}>登録</button>
                        </div>
                    </div>

                    {/*登録確認ダイアログ*/}
                    <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>登録します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ width: 150 }} onClick={handleCreateConfiumClose}>いいえ</Button>
                            <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnClickRegister}>はい</button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};