import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { useCommonTools } from '../../hooks/useCommonTools';
//import { Button, Modal } from 'react-bootstrap';
import { TSalonPayment } from '../../API';
import { useSalonPayment } from '../../hooks/useSalonPayment';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const PayCallHistoryDetail = (props) => {

    const { setUser, setAuthState, userName } = props;
    const { getSalonPayment } = useSalonPayment();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const paymentId = query.get("paymentId");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [salonPayment, setSalonPayment] = useState<TSalonPayment>();
    const { dateFormatString } = useCommonTools();

    const init = async () => {
        const result = await getSalonPayment(paymentId);

        const payment = result.data.getTSalonPayment as TSalonPayment;
        //paymentにbankRegistFeeとBankResitNumberが無ければ0をセットする
        if (!payment.bankRegistFee) {
            payment.bankRegistFee = 0;
        }
        if (!payment.bankRegistNumber) {
            payment.bankRegistNumber = 0;
        }

        setSalonPayment(payment);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    const OnClickDetail = (transferDate_StoreId: string) => {
        navigate(`/PayCallHIstoryDetailResult?transferDate_StoreId=${transferDate_StoreId}&paymentId=${salonPayment.id}`)
    }
    const OnClickReturn = () => navigate('/PayCallList')

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">支払い通知書　詳細</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">契約店舗</h6>
                                    </div>
                                    <span>{salonPayment.storeName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替日付</h6>
                                    </div>
                                    <span>{dateFormatString(salonPayment.transferDate)}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h5 className="mb-3 item-title">Ａ.請求結果</h5>
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替済合計件数</h6>
                                    </div>
                                    <span>{salonPayment.totalNumberOfSuccess.toLocaleString()}件</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替済合計金額</h6>
                                    </div>
                                    <span>&yen;{salonPayment.totalFeeOfSuccess.toLocaleString()}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替不能合計件数</h6>
                                    </div>
                                    <span>{salonPayment.totalNumberOfFailure.toLocaleString()}件</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替不能合計金額</h6>
                                    </div>
                                    <span>&yen;{salonPayment.totalFeeOfFailure.toLocaleString()}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h5 className="mb-3 item-title">Ｂ.諸経費</h5>
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">基本料</h6>
                                    </div>
                                    <span>&yen;{salonPayment.basicCharge.toLocaleString()}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">サブスクリプションサービス手数料</h6>
                                    </div>
                                    <span></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">＠&yen;{salonPayment.pricePerCase.toLocaleString()} × {salonPayment.totalNumberOfSuccess.toLocaleString()} 件</span>
                                    </div>
                                    <span>&yen;{(salonPayment.pricePerCase * salonPayment.totalNumberOfSuccess).toLocaleString()}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">口座新規登録・変更手数料</h6>
                                    </div>
                                    <span></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">＠&yen;{salonPayment.bankRegistFee.toLocaleString()} × {salonPayment.bankRegistNumber.toLocaleString()} 件</span>
                                    </div>
                                    <span>&yen;{(salonPayment.bankRegistFee * salonPayment.bankRegistNumber).toLocaleString()}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-12">
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">入金金額（Ａ－Ｂ）</span>
                                    </div>
                                    <span>&yen;{(salonPayment.totalFeeOfSuccess - (salonPayment.basicCharge + (salonPayment.pricePerCase * salonPayment.totalNumberOfSuccess) + (salonPayment.bankRegistFee * salonPayment.bankRegistNumber))).toLocaleString()}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-7">
                        </div>
                        <div className="col-md-5 d-flex">
                            <button className="btn btn-lg btn-primary ms-auto shadow-sm m-1" style={{ width: 120 }}
                                type="button" onClick={() => OnClickDetail(`${salonPayment.transferDate}_${salonPayment.storeId}`)}>請求明細</button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};