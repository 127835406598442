import React, { useEffect, useState } from 'react';
//import { useNavigate } from "react-router";
import { Link } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { BiBank2 } from "../atoms/icons/BiBank2"
import { BiBookFill } from "../atoms/icons/BiBookFill"
import { BiGearFill } from "../atoms/icons/BiGearFill"
import { BiCloudDownloadFill } from "../atoms/icons/BiCloudDownloadFill"
import { BiCloudUploadFill } from "../atoms/icons/BiCloudUploadFill"
//import { BiFileEarmarkBarGraphFill } from "../atoms/icons/BiFileEarmarkBarGraphFill"
//import { BiHouseFill } from "../atoms/icons/BiHouseFill"
import { BiPersonFill } from "../atoms/icons/BiPersonFill"
import { BiShieldLockFill } from "../atoms/icons/BiShieldLockFill"
import { Loading } from '../atoms/Loading';
import '../css/common.css';

export const MainMenu = (props) => {

    const { setUser, setAuthState , userName } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const init = async() => {
    } 

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            { isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">MAIN MENU</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row mb-5">
                        <section className="col">
                            <div className="list-group fs-5 shadow-sm">
                                <Link to="/SystemSettingDetail" className="list-group-item list-group-item-action">
                                    <BiGearFill />
                                    システム設定
                                </Link>
                                <Link to="/AccountList" className="list-group-item list-group-item-action">
                                    <BiShieldLockFill />
                                    管理者アカウント管理
                                </Link>
                                <Link to="/StoreAccountList" className="list-group-item list-group-item-action">
                                    <BiPersonFill />
                                    店舗アカウント管理
                                </Link>
                                <Link to="/StoreList" className="list-group-item list-group-item-action">
                                    <BiBookFill />
                                    店舗管理
                                </Link>
                                <Link to={'/AnyWhereCSVUpload'} className="list-group-item list-group-item-action">
                                    <BiGearFill />
                                    AnyWhereCSVアップロード
                                </Link>
                                <Link to="/TransInfoCSVDownload" className="list-group-item list-group-item-action">
                                    <BiCloudDownloadFill />
                                    請求データファイルダウンロード
                                </Link>
                                <Link to="/BillResultCSVUploadHistory" className="list-group-item list-group-item-action">
                                    <BiCloudUploadFill />
                                    結果データファイルアップロード
                                </Link>
                                <Link to="/PayCallList" className="list-group-item list-group-item-action">
                                    <BiBank2 />
                                    支払い通知書
                                </Link>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );



};
