import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom" ;
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { useSalon } from "../../hooks/useSalon";
import { useService } from "../../hooks/useService";
import { useForm } from 'react-hook-form'
import '../../css/common.css';
import { Button, Modal} from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { MSalon } from '../../API';

type Inputs = {
    courseName: string;
    monthlyPrice: number;
}

export const StoreServiceMenuAdd = ( props ) => {

    const { setUser, setAuthState , userName } = props;

    const {
        register, 
        handleSubmit, 
        formState: { errors },
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 
    
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const storeId = query.get("storeId");
    const nextId = query.get("nextId");

    const { initServiceMenu, serviceMenu , setServiceMenu, createServiceMenu } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getSalonAccount } = useSalon();
    const [ salonAccount , setSalonAccount ] = useState<MSalon>();

    const navigate = useNavigate(); 

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate(`/StoreServiceMenuList?storeId=${storeId}`)

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        // 登録
        await createServiceMenu(serviceMenu) ; 
        // 遷移
        navigate(`/StoreServiceMenuList?storeId=${storeId}`)
    }

    const onChangeCourseName = (event) => { setServiceMenu({ ...serviceMenu, courseName: event.target.value }); }
    const onChangeMonthlyPrice = (event) => { setServiceMenu({ ...serviceMenu, monthlyPrice: event.target.value }); }

    /**
     * 登録確認モーダルダイアログの制御用
     */
     const [showCreateConfium, setShowCreateConfium] = useState(false);
     const handleCreateConfiumClose = () => setShowCreateConfium(false);
     const handleCreateConfiumShow = () => setShowCreateConfium(true);
    
    const getData = async () => {
        const result = await getSalonAccount(storeId);
        setSalonAccount( result ) ; 

        initServiceMenu(storeId, nextId);
        setIsLoading(false);
    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">サービスメニュー　新規登録</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col-8 col-md-6">
                            <div className="input-group mb-3">
                                <h1 className="fs-4 m-0 ">選択店舗：{salonAccount.storeName}</h1>
                            </div>
                        </section>
                    </section>

                    <section className="row g-3">
                        <section className="col">
                            <div className="col-md-12">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label htmlFor="courseName" className="form-label required">コース名称</label>
                                        <input type="text" {...register('courseName' , { required:true, })} className="form-control" id="courseName" value={serviceMenu.courseName} onChange={onChangeCourseName} autoFocus/>
                                        {errors.courseName?.types?.required && <div className="text-danger">コース名称を入力してください</div>}
                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="monthlyPrice" className="form-label required">月額（税込）</label>
                                        <div className="input-group" style={{width: 300}}>
                                            <span className="input-group-text">&yen;</span>
                                            <input type="number" style={{textAlign: 'right'}} {...register('monthlyPrice' , { required:true, min:1, })} className="form-control" id="monthlyPrice" value={serviceMenu.monthlyPrice} onChange={onChangeMonthlyPrice} placeholder="" />
                                        </div>
                                        {errors.monthlyPrice?.types?.required && <div className="text-danger">必須入力です。</div>}
                                        {errors.monthlyPrice?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3 mb-5">
                                <div className="col-md-6">
                                    <div className="d-flex">
                                        <button className="btn btn-lg btn-success mx-auto m-1" style={{ width: 150 }} type="button" onClick={handleCreateConfiumShow}>登録する</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>

                            {/*登録確認ダイアログ*/}
                            <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>確認</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>登録します。よろしいですか？</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" style={{ width: 150 }} onClick={handleCreateConfiumClose}>いいえ</Button>
                                        <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="submit" onClick={handleCreateConfiumClose}>はい</button>
                                    </Modal.Footer>
                                </form>
                            </Modal>

                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    ); 
};