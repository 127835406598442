import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";

import { useSalon } from "../../hooks/useSalon";
//import { useMemberUseServices } from "../../hooks/useMemberUseServices";
import { useSalonMembers } from "../../hooks/useSalonMembers";
import { MSalon, MSalonMember, TServicePeriod } from "../../API";

import { Button, Modal } from 'react-bootstrap';
import { DEPOSIT_TYPE_ORDINARY, CONFIRM_PAPER_YES, FORCE_CONFIRM_YES, ANYWHERE_CONFIRMED } from '../../global-define';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import { useCommonTools } from '../../hooks/useCommonTools';

export const StoreMemberDetail = (props) => {

    const { setUser, setAuthState, userName } = props;
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { getSalonMember, deleteSalonMember } = useSalonMembers();
    //const { deleteUseServices } = useMemberUseServices();
    const { getSalonAccount } = useSalon();
    const { getTransferDateString, dateFormatString } = useCommonTools();

    const [classNameTypeBank, setClassNameTypeBank] = useState<string>(null);
    const [classNameTypePost, setClassNameTypePost] = useState<string>(null);

    const [salonMember, setSalonMember] = useState<MSalonMember>();

    const [salonAccount, setSalonAccount] = useState<MSalon>();

    const getMonthlyServicePrice = () => {

        let totalPrice = 0;
        let count = 0;

        /* ワーニング対応MAP削除
        salonMember?.useServices?.items.map((service, index) => {
            totalPrice += Number(service?.mService.monthlyPrice);
            count += 1;
        }
        );
        */

        for (const service of salonMember?.useServices?.items) {
            const currentPeriod = getServicePeriod(service?.mService.serviceId);
            // 翌月以降も有効なメニューのみ足し合わせる
            if (isServicePeriodValid(currentPeriod, 1)) {
                totalPrice += Number(service?.mService.monthlyPrice);
                count += 1;
            }
        }

        if (0 < count) {
            totalPrice += salonAccount.bankTransferFee; // 振替手数料
        }

        return totalPrice
    }

    /**
     * 履歴ボタン押下
     * @returns 
     */
    const OnClickHistory = () => navigate(`/StoreMemberHistory?memberPrimaryId=${salonMember.id}&storeId=${salonMember.storeId}&memberId=${salonMember.memberId}`)

    /**
     * 編集ボタン押下
     * @param storeId 
     * @param memberId 
     * @returns 
     */
    const OnClickEdit = (storeId: string, memberId: string) => navigate(`/StoreMemberEdit?storeId=${storeId}&memberId=${memberId}`)

    /**
     * 削除ボタン押下
     */
    const OnClickDelete = async () => {

        setIsLoading(true);

        // 登録済みの月額サービスを削除する（deleteSalonMemberに移動)
        //await salonMember?.useServices?.items.map(async (service, index) => (
        //    await deleteUseServices((service as MemberUseServices).id)
        //))

        // 登録済みの月額サービスも削除する
        // 会員を削除する(salonMember.idが内部で利用される)
        await deleteSalonMember(salonMember);

        setIsLoading(false);
        navigate(`/StoreMemberList?storeId=${salonMember.storeId}`)
    }

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate(`/StoreMemberList?storeId=${salonMember.storeId}`)

    /**
     * 削除確認モーダルダイアログの制御用
     */
    const [showDeleteConfium, setShowDeleteConfium] = useState(false);
    const handleDeleteConfiumClose = () => setShowDeleteConfium(false);
    const handleDeleteConfiumShow = () => setShowDeleteConfium(true);

    const [totalServiceCount, setTotalServiceCount] = useState<number>();

    /**
     * １件データ取得
     */
    const getData = async () => {
        // 店舗情報を取得
        const result = await getSalonAccount(query.get("storeId"));
        setSalonAccount(result);

        // 店舗会員情報を取得
        var member = await getSalonMember(query.get("storeId"), query.get("memberId"));
        setSalonMember(member);
        // 月額コースの選択数を取得
        setTotalServiceCount(member.useServices.items.length);

        if (member.accountNumber != null && member.accountNumber.length === 14) {
            // 郵便局
            setClassNameTypeBank("list-group mb-3 shadow-sm d-none");
            setClassNameTypePost("list-group mb-3 shadow-sm");
        } else {
            // 銀行
            setClassNameTypeBank("list-group mb-3 shadow-sm");
            setClassNameTypePost("list-group mb-3 shadow-sm d-none");
        }

        setIsLoading(false);
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // MSalonMemberのuseServicePeriodの中から指定されたserviceIdのものを取得する
    const getServicePeriod = (serviceId: string) => {
        const servicePeriod = salonMember?.useServicePeriod?.items.find((period) => period.serviceId === serviceId);
        return servicePeriod;
    }

    // サービス終了日からサービスが終了しているかを判定する
    const getClassNameByServiceEnd = (servicePeriod: TServicePeriod) => {

        const endDate = servicePeriod?.endDate;

        const baseClass = "list-group-item d-flex justify-content-between lh-sm"

        if (endDate === null || endDate === "") {
            return baseClass;
        }
        const td = dateFormatString(getTransferDateString(8, 0))
        if (endDate <= td) {
            return baseClass + " bg-secondary text-white";
        }
        return baseClass;
    }

    // Endボタンの表示条件を確認する
    // チェック有りでサービス終了日が未入力または未来日の場合はEndボタンを表示する
    // 当月なら、サービス終了予定を表示する
    // 過去なら、サービス終了済みを表示する
    //
    const getEndStatus = (startDate: string, endDate: string) => {
        if (endDate === null || (startDate <= dateFormatString(getTransferDateString(8, 0)))) {
            if (endDate === dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-warning">サービス終了予定</span>;
            }
            if (endDate < dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-info">サービス終了済　</span>;
            }
        }
        return "";
    }

    const isServicePeriodValid = (servicePeriod: TServicePeriod, offset: number) => {

        // 指定していない場合は有効とする
        if (!servicePeriod) {
            return true;
        }
        // 翌月の振替日時を取得
        const nextTransferDate = getTransferDateString(8, offset);

        //const startDate = servicePeriod.startDate.replace(/\//g, '');
        const endDate = servicePeriod.endDate.replace(/\//g, '');

        return ( /*startDate <= nextTransferDate &&*/ nextTransferDate <= endDate);
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">会員　詳細</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="row g-3">
                                <div className="col-6">
                                    <label htmlFor="storeName" className="form-label">契約店舗</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="storeName" value={salonAccount.storeName} disabled />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label htmlFor="StoreNumber" className="form-label">申込日</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="applicationDate" value={salonMember.applicationDate} disabled />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label htmlFor="memberId" className="form-label">会員番号</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="memberId" value={salonMember.memberId} disabled />
                                    </div>
                                </div>

                                <div className="col-6">
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="memberName" className="form-label">お名前</label>
                                    <input type="text" className="form-control" id="memberName" value={salonMember.memberName} disabled />
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="furigana" className="form-label">フリガナ</label>
                                    <input type="text" className="form-control" id="furigana" value={salonMember.furigana} disabled />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="dateOfBirth" className="form-label">生年月日</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="dateOfBirth" value={salonMember.dateOfBirth} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="zipCode" className="form-label">郵便番号</label>
                                    <input type="text" className="form-control" id="zipCode" value={salonMember.zipCode} disabled />
                                </div>
                                <div className="col-6">
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="address1" className="form-label">住所１</label>
                                    <input type="text" className="form-control" id="address1" value={salonMember.address1} disabled />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="address2" className="form-label">住所２</label>
                                    <input type="text" className="form-control" id="address2" value={salonMember.address2} disabled />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="phoneNumber" className="form-label">電話番号</label>
                                    <input type="text" className="form-control" id="phoneNumber" value={salonMember.phoneNumber} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="phoneNumberMobile" className="form-label">携帯番号</label>
                                    <input type="text" className="form-control" id="phoneNumberMobile" value={salonMember.phoneNumberMobile} disabled />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="mailAddress" className="form-label">メールアドレス</label>
                                    <input type="text" className="form-control" id="mailAddress" value={salonMember.mailAddress} disabled />
                                </div>
                                <div className="col-6">
                                </div>

                                {/*申込コース*/}
                                <div className="col-md-12">
                                    <h4 className="d-flex justify-content-between align-items-center">
                                        申込コース
                                        {/*
                                        <button className="btn btn-primary ms-auto shadow-sm" style={{ width: 120 }} type="button" onClick={OnClickHistory}>
                                            支払い履歴
                                        </button>
                                        */}
                                    </h4>
                                </div>
                                <ul className="list-group mb-3 mt-0 shadow-sm">
                                    {salonMember?.useServices?.items.sort((a, b) => Number(a.mService.serviceId) > Number(b.mService.serviceId) ? 1 : -1).map((service, index) => (
                                        <li className={getClassNameByServiceEnd(getServicePeriod(service?.mService.serviceId))} key={index}>
                                            { /*<li className="list-group-item d-flex justify-content-between lh-sm" key={index}> */}
                                            <div style={{ width: 40 + '%' }}>
                                                <h6 className="my-0">
                                                    <label className="form-check-label" htmlFor="Course1">{service?.mService.courseName}
                                                        {getEndStatus(getServicePeriod(service?.mService.serviceId)?.startDate, getServicePeriod(service?.mService.serviceId)?.endDate)}
                                                    </label>
                                                </h6>
                                            </div>
                                            {getServicePeriod(service?.mService.serviceId) &&

                                                <div className="d-flex" style={{ width: 40 + '%' }} >
                                                    <div>
                                                        <p className="my-0">振替開始日 : {getServicePeriod(service?.mService.serviceId).startDate}　～</p>
                                                    </div>
                                                    <div>
                                                        <p className="my-0">　終了日 : {getServicePeriod(service?.mService.serviceId).endDate}</p>
                                                    </div>
                                                </div>
                                            }
                                            <span className="text-muted">&yen;{service?.mService.monthlyPrice.toLocaleString()}</span>
                                        </li>
                                    ))}
                                    {0 < totalServiceCount &&
                                        <li className="list-group-item d-flex justify-content-between lh-sm">
                                            <div>
                                                <h6 className="my-0"><b>振替手数料</b></h6>
                                            </div>
                                            <span className="text-muted"><b>&yen;{salonAccount.bankTransferFee.toLocaleString()}</b></span>
                                        </li>
                                    }
                                    <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                        <div>
                                            <h6 className="my-0"><b>毎月振替金額</b></h6>
                                        </div>
                                        <span className="text-muted"><b>&yen;{getMonthlyServicePrice().toLocaleString()}</b></span>
                                    </li>
                                </ul>

                                {/*預金口座振替情報*/}
                                <h5 className="mb-0">
                                    預金口座振替情報
                                </h5>
                                <ul className={classNameTypeBank}>
                                    <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success " : " list-group-item-danger ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                        <div style={{ margin: "0 auto" }}>
                                            <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span>カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">口座登録日</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.bankSetDate != null ? salonMember?.bankSetDate : ''}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">銀行名</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.bankCode} {salonMember?.bankName}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">支店名</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.bankBranchCode} {salonMember?.bankBranchName}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">預金種別</h6>
                                        </div>
                                        <span className="text-muted">
                                            {salonMember?.depositType === DEPOSIT_TYPE_ORDINARY ? <span>普通</span> : <span>当座</span>}
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">口座番号</h6>
                                        </div>
                                        <span className="text-muted">
                                            {salonMember?.accountNumber}
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">預金者名（カナ）</h6>
                                        </div>
                                        <span className="text-muted">
                                            {salonMember?.depositerName}
                                        </span>
                                    </li>
                                </ul>
                                <ul className={classNameTypePost}>
                                    <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success " : " list-group-item-danger ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                        <div style={{ margin: "0 auto" }}>
                                            <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span>カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">口座登録日</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.bankSetDate != null ? salonMember?.bankSetDate : ''}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">ゆうちょ銀行</h6>
                                        </div>
                                        <span className="text-muted"></span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">記号番号</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.accountNumber}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">預金者名（カナ）</h6>
                                        </div>
                                        <span className="text-muted">{salonMember?.depositerName}</span>
                                    </li>
                                </ul>
                                <div className="row mt-0">
                                    <div className="col-12">
                                        <h6 className="mt-0">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="forceConfirm" checked={salonMember?.forceConfirm === FORCE_CONFIRM_YES ? true : false} disabled />
                                                <label className="form-check-label" htmlFor="forceConfirm">カード強制確認済み</label>
                                            </div>
                                        </h6>
                                    </div>
                                    <div className="col-12">
                                        <h6 className="mt-0">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="confirmPaper" checked={salonMember?.confirmPaper === CONFIRM_PAPER_YES ? true : false} disabled />
                                                <label className="form-check-label" htmlFor="confirmPaper">振替用紙チェック済み</label>
                                            </div>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-primary m-1" style={{ width: 150 }} type="button" onClick={OnClickHistory}>振替履歴</button>
                                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={() => OnClickEdit(salonMember.storeId, salonMember.memberId)}>編集する</button>
                                <button className="btn btn-lg btn-danger m-1" style={{ width: 150 }} type="button" onClick={handleDeleteConfiumShow}>削除する</button>
                            </div>
                        </div>
                    </div>

                    {/*削除確認ダイアログ*/}
                    <Modal show={showDeleteConfium} onHide={handleDeleteConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>削除します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ width: 150 }} onClick={handleDeleteConfiumClose}>いいえ</Button>
                            <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnClickDelete}>はい</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
            <Footer />
        </>
    );
};