import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import React, { useEffect, useState } from 'react';
import { useSalon } from "../../hooks/useSalon";
import { MSalon } from '../../API';
import { useForm } from 'react-hook-form'
import { Loading } from "../../atoms/Loading";
import { Button, Modal} from 'react-bootstrap';
import '../../css/common.css';
// get our fontawesome imports
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { EMAIL_CHECK_PATTERN } from '../../global-define';

type Inputs = {
    storeId: string;
    storeName: string;
    storeAddress1: string;
    storeAddress2: string;
    phone: string;
    personelName1: string;
    personelMail1: string;
    personelPassword1: string;
    personelName2: string;
    personelMail2: string;
    personelPassword2: string;
    personelName3: string;
    personelMail3: string;
    personelPassword3: string;
    bankCode: string;
    bankBranchCode: string;
    bankDepositType: string;
    bankAccountNumber: string;
    bankDepositerName: string;
    bankConfirmStatus: string;
    basicCharge: number;
    pricePerCase: number;
    bankTransferFee: number;
    classification: string;
}
  
export const StoreAccountEdit = ( props ) => {

  const { setUser, setAuthState , userName } = props;

  const {
      register, 
      handleSubmit, 
      formState: { errors },
      reset
  } = useForm<Inputs>({
      criteriaMode: "all",
      shouldFocusError: true,
  }); 

  const { storeId } = useParams();

  const { getSalonAccount, updateSalonAccount } = useSalon();
  const { updateSalonCognitoUser, getUser } = useCognito() ; 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ originalSalonAccount , setOriginalSalonAccount ] = useState<MSalon>();
  const [ salonAccount , setSalonAccount ] = useState<MSalon>();

  // Validate
  const [isPersonelMail1NotUse, setIsPersonelMail1NotUse] = useState<boolean>(true);
  const [isPersonelMail2NotUse, setIsPersonelMail2NotUse] = useState<boolean>(true);
  const [isPersonelMail3NotUse, setIsPersonelMail3NotUse] = useState<boolean>(true);
  const [isSameMail, setIsSameMail] = useState<boolean>(true);
  const [cognitoError, setCognitoError] = useState<string>("");

  // Password Toggle
  const [isRevealPassword1, setIsRevealPassword1] = useState(false);
  const togglePassword1 = () => {
    setIsRevealPassword1((prevState) => !prevState);
  }
  const [isRevealPassword2, setIsRevealPassword2] = useState(false);
  const togglePassword2 = () => {
    setIsRevealPassword2((prevState) => !prevState);
  }
  const [isRevealPassword3, setIsRevealPassword3] = useState(false);
  const togglePassword3 = () => {
    setIsRevealPassword3((prevState) => !prevState);
  }
  
  const navigate = useNavigate();

  /**
   * リセットボタン押下（同じページを再読み込み）
   * @returns 
   */
  const OnReset = () => {
    getData();
    handleResetConfiumClose();
    // フォームデータのクリア
    reset();
  }

  /**
   * 戻るボタン押下
   * @returns 
   */
  const OnClickReturn = () => navigate(`/StoreAccountDetail/${storeId}`)

  /**
   * １件データ取得
   */
  const getData = async () => {

    const mSalon  = await getSalonAccount(storeId);
    setSalonAccount( mSalon );
    setOriginalSalonAccount( {...mSalon} );
    setIsLoading(false);

  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 登録するボタン押下
   */
  const onSubmit = async () => {

      setIsLoading( true );
      var err = false;
      // 初期化
      setCognitoError("");
      setIsPersonelMail1NotUse(true);
      setIsPersonelMail2NotUse(true);
      setIsPersonelMail3NotUse(true);
      setIsSameMail(true);

      // 担当者１～３で同じアドレスが無いか確認
      if (((0 < salonAccount.personelMail1.length && 0 < salonAccount.personelMail2.length) && (salonAccount.personelMail1 === salonAccount.personelMail2)) || 
          ((0 < salonAccount.personelMail1.length && 0 < salonAccount.personelMail3.length) && (salonAccount.personelMail1 === salonAccount.personelMail3)) ||
          ((0 < salonAccount.personelMail2.length && 0 < salonAccount.personelMail3.length) && (salonAccount.personelMail2 === salonAccount.personelMail3)) )
      {
        // 担当者１～３で同じアドレスが存在する
          err = true;
          setIsSameMail(false);
      }
      else
      {
        //------------------------------------
        // Cognitoに対する事前チェック
        //------------------------------------
        // 担当者１メールアドレスが変更の場合は登録済みメールアドレスか確認する
        if (originalSalonAccount.personelMail1 !== salonAccount.personelMail1)
        {
          const result = await getUser(salonAccount.personelMail1);
          if (result != null) {
              err = true;
              // 変更しようとしているメールアドレスは登録済み
              setIsPersonelMail1NotUse(false);
          }
        }
        if (originalSalonAccount.personelMail2 !== salonAccount.personelMail2)
        {
          const result = await getUser(salonAccount.personelMail2);
          if (result != null) {
              err = true;
              // 変更しようとしているメールアドレスは登録済み
              setIsPersonelMail2NotUse(false);
          }
        }
        if (originalSalonAccount.personelMail3 !== salonAccount.personelMail3)
        {
          const result = await getUser(salonAccount.personelMail3);
          if (result != null) {
              err = true;
              // 変更しようとしているメールアドレスは登録済み
              setIsPersonelMail3NotUse(false);
          }
        }
      }

      if (err === false)
      {
        // Cognitoの更新
        await updateSalonCognitoUser( originalSalonAccount , salonAccount ); 
        // DynamoDBの更新
        await updateSalonAccount( salonAccount );
        // 遷移
        navigate(`/StoreAccountDetail/${storeId}`);
      } else {
        setIsLoading( false );
      }
  }

  const onChangeStoreId = (event) => { setSalonAccount({ ...salonAccount, storeId: event.target.value }); }
  const onChangeStoreName = (event) => { setSalonAccount({ ...salonAccount, storeName: event.target.value }); }
  const onChangePhone = (event) => { setSalonAccount({ ...salonAccount, phone: event.target.value }); }
  const onChangeStoreAddress1 = (event) => { setSalonAccount({ ...salonAccount, storeAddress1: event.target.value }); }
  const onChangeStoreAddress2 = (event) => { setSalonAccount({ ...salonAccount, storeAddress2: event.target.value }); }
  
  const onChangePersonelName1 = (event) => { setSalonAccount({ ...salonAccount, personelName1: event.target.value }); }
  const onChangePersonelMail1 = (event) => { setSalonAccount({ ...salonAccount, personelMail1: event.target.value }); }
  const onChangePersonelPassword1 = (event) => { setSalonAccount({ ...salonAccount, personelPassword1: event.target.value }); }
  
  const onChangePersonelName2 = (event) => { setSalonAccount({ ...salonAccount, personelName2: event.target.value }); }
  const onChangePersonelMail2 = (event) => { setSalonAccount({ ...salonAccount, personelMail2: event.target.value }); }
  const onChangePersonelPassword2 = (event) => { setSalonAccount({ ...salonAccount, personelPassword2: event.target.value }); }
  
  const onChangePersonelName3 = (event) => { setSalonAccount({ ...salonAccount, personelName3: event.target.value }); }
  const onChangePersonelMail3 = (event) => { setSalonAccount({ ...salonAccount, personelMail3: event.target.value }); }
  const onChangePersonelPassword3 = (event) => { setSalonAccount({ ...salonAccount, personelPassword3: event.target.value }); }

  const onChangeBasicCharge = (event) => { setSalonAccount({ ...salonAccount, basicCharge: event.target.value }); }
  const onChangePricePerCase = (event) => { setSalonAccount({ ...salonAccount, pricePerCase: event.target.value }); }
  const onChangeBankTransferFee = (event) => { setSalonAccount({ ...salonAccount, bankTransferFee: event.target.value }); }
  
  const onChangeBankCode = (event) => { setSalonAccount({ ...salonAccount, bankCode: event.target.value }); }
  const onChangeBankBranchCode = (event) => { setSalonAccount({ ...salonAccount, bankBranchCode: event.target.value }); }
  const onChangeBankDepositType = (event) => { setSalonAccount({ ...salonAccount, bankDepositType: event.target.value }); }
  const onChangeBankAccountNumber = (event) => { setSalonAccount({ ...salonAccount, bankAccountNumber: event.target.value }); }
  const onChangeBankDepositerName = (event) => { setSalonAccount({ ...salonAccount, bankDepositerName: event.target.value }); }

  const onChangeClassification = (event) => { setSalonAccount({ ...salonAccount, classification: event.target.value }); }

  const onChangeTermsOfService = (event) => { setSalonAccount({ ...salonAccount, termsOfService: event.target.value }); }
  /**
   * 更新確認モーダルダイアログの制御用
   */
  const [showEditConfium, setShowEditConfium] = useState(false);
  const handleEditConfiumClose = () => setShowEditConfium(false);
  const handleEditConfiumShow = () => setShowEditConfium(true);

  /**
   * リセット確認モーダルダイアログの制御用
   */
  const [showResetConfium, setShowResetConfium] = useState(false);
  const handleResetConfiumClose = () => setShowResetConfium(false);
  const handleResetConfiumShow = () => setShowResetConfium(true);
 
  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mt-5">
          <section className="row mb-4">
            <section className="col">
              <div className="d-flex col-12 border-bottom">
                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                <header className="d-flex align-items-center">
                  <h1 className="fs-4 m-2">店舗アカウント　編集</h1>
                </header>
              </div>
            </section>
          </section>

          <div className="row g-3">
            <div className="col-md-12">
              <div className="row g-3">
                <div className="col-6">
                  <label htmlFor="storeNumber" className="form-label">加盟店番号</label>
                  <div className="input-group">
                    <input type="text" {...register('storeId' , { required:false, })} className="form-control" id="storeNumber" value={salonAccount.storeId} onChange={onChangeStoreId} disabled/>
                  </div>
                  {errors.storeId?.types?.required && <div className="text-danger">加盟店番号を入力してください</div>}
                </div>
                <div className="col-6">
                </div>

                <div className="col-sm-6">
                  <label htmlFor="storeName" className="form-label required">店舗名</label>
                  <input type="text" {...register('storeName' , { required:true, })} className="form-control" id="storeName" value={salonAccount.storeName} onChange={onChangeStoreName} autoFocus/>
                  {errors.storeName?.types?.required && <div className="text-danger">店舗名を入力してください</div>}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="phone" className="form-label required">電話番号</label>
                  <input type="text" {...register('phone' , { required:true, pattern: /^[0-9-]+$/ })} className="form-control" id="phone" value={salonAccount.phone} onChange={onChangePhone} placeholder="半角0～9およびハイフン"/>
                  {errors.phone?.types?.required && <div className="text-danger">電話番号を入力してください</div>}
                  {errors.phone?.types?.pattern && <div className="text-danger">半角0～9およびハイフンで入力してください</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="storeAddress1" className="form-label required">住所１</label>
                  <input type="text" {...register('storeAddress1' , { required:true, })} className="form-control" id="storeAddress1" value={salonAccount.storeAddress1} onChange={onChangeStoreAddress1}/>
                  {errors.storeAddress1?.types?.required && <div className="text-danger">住所１を入力してください</div>}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="storeAddress2" className="form-label">住所２</label>
                  <input type="text" {...register('storeAddress2' , { required:false, })} className="form-control" id="storeAddress2" value={salonAccount.storeAddress2} onChange={onChangeStoreAddress2}/>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelName1" className="form-label required">担当者１氏名</label>
                  <input type="text" {...register('personelName1' , { required:true, })} className="form-control" id="personelName1" value={salonAccount.personelName1} onChange={onChangePersonelName1} autoComplete="new-password"/>
                  {errors.personelName1?.types?.required && <div className="text-danger">担当者１氏名を入力してください</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelMail1" className="form-label required">担当者１メールアドレス</label>
                  <input type="text" {...register('personelMail1' , { required:true, pattern: EMAIL_CHECK_PATTERN /*/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*[A-Za-z0-9]{1}@{1}[A-Za-z0-9]{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/*/ })} className="form-control" id="personelMail1" value={salonAccount.personelMail1} onChange={onChangePersonelMail1} placeholder="半角文字" autoComplete="new-password"/>
                  {errors.personelMail1?.types?.required && <div className="text-danger">担当者１メールアドレスを入力してください</div>}
                  {errors.personelMail1?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                  {isPersonelMail1NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                  {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelPassword1" className="form-label required">担当者１パスワード</label>
                  <div className="input-group">
                    <input type={isRevealPassword1 ? 'text' : 'password'} {...register('personelPassword1' , { required:true, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword1" value={salonAccount.personelPassword1} onChange={onChangePersonelPassword1} placeholder="半角8文字以上" autoComplete="new-password"/>
                    <span onClick={togglePassword1} role="presentation" className="PasswordReveal">
                    {isRevealPassword1 ? (
                      <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                    ) : (
                      <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                    )}
                    </span>
                  </div>
                  {errors.personelPassword1?.types?.required && <div className="text-danger">担当者１パスワードを入力してください</div>}
                  {errors.personelPassword1?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                  {errors.personelPassword1?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                </div>

                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                <div className="col-sm-6">
                  <label htmlFor="personelName2" className="form-label">担当者２氏名</label>
                  <input type="text" {...register('personelName2' , { required:false, })} className="form-control" id="personelName2" value={salonAccount.personelName2} onChange={onChangePersonelName2}/>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelMail2" className="form-label">担当者２メールアドレス</label>
                  <input type="text" {...register('personelMail2' , { required:false, pattern: EMAIL_CHECK_PATTERN })} className="form-control" id="personelMail2" value={salonAccount.personelMail2} onChange={onChangePersonelMail2} placeholder="半角文字"/>
                  {errors.personelMail2?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                  {isPersonelMail2NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                  {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelPassword2" className="form-label">担当者２パスワード</label>
                  <div className="input-group">
                    <input type={isRevealPassword2 ? 'text' : 'password'} {...register('personelPassword2' , { required:false, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword2" value={salonAccount.personelPassword2} onChange={onChangePersonelPassword2} placeholder="半角8文字以上"/>
                    <span onClick={togglePassword2} role="presentation" className="PasswordReveal">
                    {isRevealPassword2 ? (
                      <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                    ) : (
                      <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                    )}
                    </span>
                  </div>
                  {errors.personelPassword2?.types?.required && <div className="text-danger">担当者２パスワードを入力してください</div>}
                  {errors.personelPassword2?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                  {errors.personelPassword2?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                </div>

                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                <div className="col-sm-6">
                  <label htmlFor="personelName3" className="form-label">担当者３氏名</label>
                  <input type="text" {...register('personelName3' , { required:false, })} className="form-control" id="personelName3" value={salonAccount.personelName3} onChange={onChangePersonelName3}/>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelMail3" className="form-label">担当者３メールアドレス</label>
                  <input type="text" {...register('personelMail3' , { required:false, pattern: EMAIL_CHECK_PATTERN })} className="form-control" id="personelMail3" value={salonAccount.personelMail3} onChange={onChangePersonelMail3} placeholder="半角文字"/>
                  {errors.personelMail3?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                  {isPersonelMail3NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                  {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="personelPassword3" className="form-label">担当者３パスワード</label>
                  <div className="input-group">
                    <input type={isRevealPassword3 ? 'text' : 'password'} {...register('personelPassword3' , { required:false, minLength:8, pattern: /[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword3" value={salonAccount.personelPassword3} onChange={onChangePersonelPassword3} placeholder="半角8文字以上"/>
                    <span onClick={togglePassword3} role="presentation" className="PasswordReveal">
                    {isRevealPassword3 ? (
                        <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                    ) : (
                        <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                    )}
                    </span>
                  </div>
                  {errors.personelPassword3?.types?.required && <div className="text-danger">担当者３パスワードを入力してください</div>}
                  {errors.personelPassword3?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                  {errors.personelPassword3?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                </div>

                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                {/*利用料／手数料*/}
                <h4 className="d-flex justify-content-between align-items-center mt-4">
                    利用料／手数料
                </h4>
                <div className="col-sm-6">
                    <label htmlFor="basicCharge" className="form-label required">サブスクリプションサービス基本利用料</label>
                    <div className="input-group">
                        <span className="input-group-text">&yen;</span>
                        <input type="number" style={{textAlign: 'right'}} {...register('basicCharge' , { required:true, min:1, })} className="form-control" value={salonAccount.basicCharge} onChange={onChangeBasicCharge} placeholder="" />
                    </div>
                    {errors.basicCharge?.types?.required && <div className="text-danger">必須入力です。</div>}
                    {errors.basicCharge?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                </div>

                <div className="col-sm-6">
                    <label htmlFor="pricePerCase" className="form-label required">サブスクリプションサービス手数料</label>
                    <div className="input-group">
                        <span className="input-group-text">１件あたり</span>
                        <span className="input-group-text">&yen;</span>
                        <input type="number" style={{textAlign: 'right'}} {...register('pricePerCase' , { required:true, min:1, })} className="form-control" value={salonAccount.pricePerCase} onChange={onChangePricePerCase} placeholder="" />
                    </div>
                    {errors.pricePerCase?.types?.required && <div className="text-danger">必須入力です。</div>}
                    {errors.pricePerCase?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                </div>

                <div className="col-sm-6">
                    <label htmlFor="bankTransferFee" className="form-label required">振替手数料</label>
                    <div className="input-group">
                        <span className="input-group-text">&yen;</span>
                        <input type="number" style={{textAlign: 'right'}} {...register('bankTransferFee' , { required:true, min:1, })} className="form-control" value={salonAccount.bankTransferFee} onChange={onChangeBankTransferFee} />
                    </div>
                    {errors.bankTransferFee?.types?.required && <div className="text-danger">必須入力です。</div>}
                    {errors.bankTransferFee?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                </div>

                {/*オーナー預金口座情報*/}
                <h4 className="d-flex justify-content-between align-items-center">
                  オーナー預金口座情報
                </h4>

                <div className="col-sm-6">
                  <label htmlFor="bankCode" className="form-label">銀行名</label>
                  <div className="input-group">
                    <input type="text" {...register('bankCode' , { required:false, })} className="form-control" id="bankCode" value={salonAccount.bankCode} onChange={onChangeBankCode} placeholder="自由入力"/>
                  </div>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="bankBranchCode" className="form-label">支店名</label>
                  <input type="text" {...register('bankBranchCode' , { required:false, })} className="form-control" id="bankBranchCode" value={salonAccount.bankBranchCode} onChange={onChangeBankBranchCode} placeholder="自由入力"/>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="bankDepositType" className="form-label">預金種別</label>
                  <input type="text" {...register('bankDepositType' , { required:false, })} className="form-control" id="bankDepositType" value={salonAccount.bankDepositType} onChange={onChangeBankDepositType} placeholder="自由入力"/>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="bankAccountNumber" className="form-label">口座番号</label>
                  <input type="text" {...register('bankAccountNumber' , { required:false, pattern: /^[0-9]+$/, minLength:7, maxLength:7 })} className="form-control" id="bankAccountNumber" value={salonAccount.bankAccountNumber} onChange={onChangeBankAccountNumber} placeholder="半角7桁0～9の数字"/>
                  {errors.bankAccountNumber?.types?.pattern && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                  {errors.bankAccountNumber?.types?.minLength && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                  {errors.bankAccountNumber?.types?.maxLength && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="bankDepositerName" className="form-label">預金者名</label>
                  <input type="text" {...register('bankDepositerName' , { required:false, })} className="form-control" id="bankDepositerName" value={salonAccount.bankDepositerName} onChange={onChangeBankDepositerName} placeholder="自由入力"/>
                </div>

                {/*その他情報*/}
                <h4 className="d-flex justify-content-between align-items-center">
                  その他情報
                </h4>
                <div className="col-sm-6">
                  <label htmlFor="classification" className="form-label required">請求区分コード</label>
                  <div className="input-group">
                      <input type="text" {...register('classification' , {required: true, pattern: /^[0-9]{2}$/i})} className="form-control" id="classification" value={salonAccount.classification} onChange={onChangeClassification} placeholder="00～99で入力"/>
                  </div>
                  {errors.classification?.types?.required && <div className="text-danger">請求区分コードを 00～99 で入力してください</div>}
                  {errors.classification?.types?.pattern && <div className="text-danger">請求区分コードは 00～99 で入力してください</div>}
                </div>
                <div className="col-sm-12">
                  <label className="form-label required">利用規約</label>
                  <div className="input-group">
                      <textarea className="form-control"  rows={5} id="classification" value={ salonAccount.termsOfService } onChange={onChangeTermsOfService} placeholder="利用規約を入力"/>
                  </div>
                </div>

                {/*Cognitoでのその他エラー*/}
                {0 < cognitoError.length && <div className="text-danger">{cognitoError}</div>}

              </div>
            </div>
          </div>

          <div className="row mt-3 mb-5">
            <div className="col">
              <div className="d-flex justify-content-end">
                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={handleEditConfiumShow}>更新する</button>
                <button className="btn btn-lg btn-primary m-1" style={{ width: 150 }} type="reset" onClick={handleResetConfiumShow}>リセット</button>
              </div>
            </div>
          </div>
          
          {/*更新確認ダイアログ*/}
          <Modal show={showEditConfium} onHide={handleEditConfiumClose}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Modal.Header closeButton>
                <Modal.Title>確認</Modal.Title>
              </Modal.Header>
              <Modal.Body>更新します。よろしいですか？</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" style={{ width: 150 }} onClick={handleEditConfiumClose}>いいえ</Button>
                <button className="btn btn-primary ms-auto m-1" style={{ width: 150 }} type="submit" onClick={handleEditConfiumClose}>はい</button>
              </Modal.Footer>
            </form>
          </Modal>

          {/*リセット確認ダイアログ*/}
          <Modal show={showResetConfium} onHide={handleResetConfiumClose}>
            <Modal.Header closeButton>
              <Modal.Title>確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>内容をリセットします。よろしいですか？</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" style={{ width: 150 }} onClick={handleResetConfiumClose}>いいえ</Button>
              <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnReset}>はい</button>
            </Modal.Footer>
          </Modal>
          
        </div>
      )}
      <Footer />
    </>
  );
};