import { listMemberByConfirmStatus } from './../graphql/queries';
import { useState } from "react";

import { CreateMSalonMemberInput, ListMemberByBankInfoQuery, ListMemberByConfirmStatusQuery, UpdateMSalonMemberInput } from '../API';
import { createMSalonMember, updateMSalonMember } from '../graphql/mutations';
import { listMemberByBankInfo, listMemberByStoreId } from '../graphql/queries';
import { MSalonMember, ListMemberByStoreIdQuery } from '../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { DEPOSIT_TYPE_ORDINARY, ANYWHERE_UNCONFIRMED } from '../global-define';

export const useSalonMember = () => {

    const [salonMember, setSalonMember] = useState<CreateMSalonMemberInput>(
        {
            storeId: "",
            memberId: "",
            applicationDate: "",
            memberName: "",
            furigana: "",
            dateOfBirth: "",
            zipCode: "",
            address1: "",
            address2: "",
            phoneNumber: "",
            phoneNumberMobile: "",
            mailAddress: "",
            bankCode: "",
            bankBranchCode: "",
            depositType: "",
            accountNumber: "",
            depositerName: "",
            confirmStatus: "",
            updateDate: "",
            confirmDate: "",
            deleteFlag: "",
            deleteDate: "",
            lastUpdater: "",
            lastUpdateDate: ""
        });

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const initSalonMember = (sId: string, memId: string) => {
        setSalonMember({ ...salonMember, storeId: sId, memberId: memId, depositType: DEPOSIT_TYPE_ORDINARY /*1:普通 2:当座*/, confirmStatus: ANYWHERE_UNCONFIRMED /* 0:未確認 1:NG 2:OK */ } as MSalonMember);
    }

    const getSalonMember = async (storeId: string, memberId: string) => {
        console.log(storeId);
        console.log(memberId);

        const res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
            storeId: `${storeId}`,
            memberId: { eq: `${memberId}` },
            limit: 999999
        })) as GraphQLResult<ListMemberByStoreIdQuery>);

        console.log(res);
        const mSalonMember = res.data.ListMemberByStoreId.items[0] as MSalonMember;
        setSalonMember(mSalonMember)
        setIsLoading(false);
    }

    const listSalonMembersByStoreId = async (storeId: string) => {
        console.log('listSalonMembersByStoreId');
        console.log(storeId);

        const res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
            storeId: `${storeId}`,
            limit: 999999
        })) as GraphQLResult<ListMemberByStoreIdQuery>);

        console.log(res);
        const mSalonMembers = res.data.ListMemberByStoreId.items as MSalonMember[];

        return (mSalonMembers);
    }

    const listSalonMembersByBankInfo = async (bankCode: string, bankBranchCode: string
        , depositType: string, accountNumber: string) => {
        console.log(bankCode);
        console.log(bankBranchCode);
        /*
                const filter = {
                    depositType: {
                        'eq' : `${depositType}`
                    },
                    accountNumber: {
                        'eq' : `${accountNumber}`
                    }            
                }
        */
        const res = (await API.graphql(graphqlOperation(listMemberByBankInfo, {
            bankCode: bankCode,
            bankBranchCode: { eq: bankBranchCode },
            limit: 999999,

            filter: {
                depositType: { eq: (depositType === '普通' ? 1 : 2) },
                accountNumber: { eq: accountNumber }
            }
            /*filter : filter*/
        })) as GraphQLResult<ListMemberByBankInfoQuery>);

        console.log(res);

        let mSalonMembers = null;
        if (res.data.ListMemberByBankInfo.items.length > 0) {
            mSalonMembers = res.data.ListMemberByBankInfo.items as MSalonMember[];
        }
        //setSalonMember(mSalonMember)
        //setIsLoading(false);

        return (mSalonMembers)
    }

    // 未確認会員の取得
    const listSalonMembersByConfirmStatus = async (confirmStatus: string, storeId: string) => {
        console.log(`confirmStatus:${confirmStatus}`);
        console.log(`storeId:${storeId}`);

        let conditions = {};
        if (storeId === '') {
            conditions = {
                confirmStatus: confirmStatus,
                limit: 999999
            }
        } else {
            conditions = {
                confirmStatus: confirmStatus,
                storeId: { eq: storeId },
                limit: 999999
            }
        }

        const res = (await API.graphql(graphqlOperation(
            listMemberByConfirmStatus, conditions)) as GraphQLResult<ListMemberByConfirmStatusQuery>);

        console.log(res);

        let mSalonMembers = null;
        if (res.data.ListMemberByConfirmStatus.items.length > 0) {
            mSalonMembers = res.data.ListMemberByConfirmStatus.items as MSalonMember[];
        }
        //setSalonMember(mSalonMember)
        //setIsLoading(false);
        return (mSalonMembers)
    }

    const updateSalonMember = async () => {
        setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(updateMSalonMember, {
            input: {
                id: `${salonMember.id}`,
                memberId: `${salonMember.memberId}`,
                applicationDate: `${salonMember.applicationDate}`,
                memberName: `${salonMember.memberName}`,
                furigana: `${salonMember.furigana}`,
                dateOfBirth: `${salonMember.dateOfBirth}`,
                zipCode: `${salonMember.zipCode}`,
                address1: `${salonMember.address1}`,
                address2: `${salonMember.address2}`,
                phoneNumber: `${salonMember.phoneNumber}`,
                phoneNumberMobile: `${salonMember.phoneNumberMobile}`,
                mailAddress: `${salonMember.mailAddress}`,
                bankSetDate: `${salonMember.bankSetDate}`,
                bankCode: `${salonMember.bankCode}`,
                bankBranchCode: `${salonMember.bankBranchCode}`,
                depositType: `${salonMember.depositType}`,
                accountNumber: `${salonMember.accountNumber}`,
                depositerName: `${salonMember.depositerName}`,
                confirmStatus: `${salonMember.confirmStatus}`,
                updateDate: `${salonMember.updateDate}`,
                confirmDate: `${salonMember.confirmDate}`,
                deleteFlag: `${salonMember.deleteFlag}`,
                deleteDate: `${salonMember.deleteDate}`,
                lastUpdater: `${salonMember.lastUpdater}`,
                lastUpdateDate: `${salonMember.lastUpdateDate}`
            },
        })) as GraphQLResult<UpdateMSalonMemberInput>);

        console.log(res);
    }

    const updateSalonMemberDirect = async (member: MSalonMember) => {
        setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(updateMSalonMember, {
            input: {
                id: `${member.id}`,
                memberId: `${member.memberId}`,
                applicationDate: `${member.applicationDate}`,
                memberName: `${member.memberName}`,
                furigana: `${member.furigana}`,
                dateOfBirth: `${member.dateOfBirth}`,
                zipCode: `${member.zipCode}`,
                address1: `${member.address1}`,
                address2: `${member.address2}`,
                phoneNumber: `${member.phoneNumber}`,
                phoneNumberMobile: `${member.phoneNumberMobile}`,
                mailAddress: `${member.mailAddress}`,
                bankCode: `${member.bankCode}`,
                bankBranchCode: `${member.bankBranchCode}`,
                depositType: `${member.depositType}`,
                accountNumber: `${member.accountNumber}`,
                depositerName: `${member.depositerName}`,
                confirmStatus: `${member.confirmStatus}`,
                updateDate: `${member.updateDate}`,
                confirmDate: `${member.confirmDate}`,
                deleteFlag: `${member.deleteFlag}`,
                deleteDate: `${member.deleteDate}`,
                lastUpdater: `${member.lastUpdater}`,
                lastUpdateDate: `${member.lastUpdateDate}`
            },
        })) as GraphQLResult<UpdateMSalonMemberInput>);

        console.log(res);
    }



    const createSalonMember = async () => {
        console.log(salonMember);

        setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(createMSalonMember, {
            input: {
                storeId: `${salonMember.storeId}`,
                memberId: `${salonMember.memberId}`,
                applicationDate: `${salonMember.applicationDate}`,
                memberName: `${salonMember.memberName}`,
                furigana: `${salonMember.furigana}`,
                dateOfBirth: `${salonMember.dateOfBirth}`,
                zipCode: `${salonMember.zipCode}`,
                address1: `${salonMember.address1}`,
                address2: `${salonMember.address2}`,
                phoneNumber: `${salonMember.phoneNumber}`,
                phoneNumberMobile: `${salonMember.phoneNumberMobile}`,
                mailAddress: `${salonMember.mailAddress}`,
                bankSetDate: `${salonMember.bankSetDate}`,
                bankCode: `${salonMember.bankCode}`,
                bankBranchCode: `${salonMember.bankBranchCode}`,
                depositType: `${salonMember.depositType}`,
                accountNumber: `${salonMember.accountNumber}`,
                depositerName: `${salonMember.depositerName}`,
                confirmStatus: `${salonMember.confirmStatus}`,
                updateDate: `${salonMember.updateDate}`,
                confirmDate: `${salonMember.confirmDate}`,
                deleteFlag: `${salonMember.deleteFlag}`,
                deleteDate: `${salonMember.deleteDate}`,
                lastUpdater: `${salonMember.lastUpdater}`,
                lastUpdateDate: `${salonMember.lastUpdateDate}`
            },
        })) as GraphQLResult<CreateMSalonMemberInput>);

        console.log(res);
    }

    return {
        listSalonMembersByConfirmStatus, updateSalonMemberDirect, listSalonMembersByBankInfo
        , initSalonMember, getSalonMember, updateSalonMember, createSalonMember, salonMember
        , setSalonMember, isLoading, listSalonMembersByStoreId
    };

}
