import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
import { useCommonTools } from '../../hooks/useCommonTools';
import { useSalonPayment } from '../../hooks/useSalonPayment';
import { TSalonPayment } from '../../API';
//import { inherits } from 'util';
import { Loading } from '../../atoms/Loading';
import { Button, Modal } from 'react-bootstrap';
import { BILLING_RESULT_UPLOADED } from '../../global-define';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const PayCallList = (props) => {

  const { setUser, setAuthState, userName } = props;
  const { getTransferDateString, dateFormatString } = useCommonTools();
  const { listPaymentByTransferDate, makePaymentData, deletePaymentByTransferDate } = useSalonPayment();

  let defaultYear = getTransferDateString(8, 0).substring(0, 4)
  if (!(new Date().getMonth() > 0)) {
    // defaultyearを１年前にする
    defaultYear = String(Number(defaultYear) - 1);
  }

  //  const defaultDay = `${getTransferDateString(4).substring(0, 2)}/27`
  const dMonth = (new Date().getMonth() > 0) ? new Date().getMonth() : 12; // 0月の場合は12月とする
  const defaultDay = `${dMonth}/27`; // getMonth = 0～12 で自動的に前月 

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTransferDay, setCurrentTransferDay] = useState<string>(getTransferDateString(8, -1));// 前月の振替日をディフォルト設定
  const [selectedYear, setSelectedYear] = useState<string>(defaultYear);
  const [selectedDay, setSelectedDay] = useState<string>(defaultDay);
  const [salonPayments, setSalonPayments] = useState<TSalonPayment[]>([]);
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [progressMessageDetail, setProgressMessageDetail] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);

  const navigate = useNavigate();

  const getYearList = () => {
    const startYear = new Date().getFullYear();
    const yearList = [...Array(3)].map((x, i) => String(startYear - i))
    return (yearList)
  }

  const [yearList /*, setYearList*/] = useState<string[]>(getYearList());

  const getDayList = () => {
    const dayList = [...Array(12)].map((x, i) => `${String(i + 1).padStart(2, '0')}/27`)
    return (dayList)
  }

  const [dayList /*, setDayList*/] = useState<string[]>(getDayList());

  const OnClickDetail = (paymentId: string) => {
    navigate(`/PayCallHistoryDetail?paymentId=${paymentId}`)
  }
  const OnClickReturn = () => navigate('/MainMenu')
  const OnClickSetDay = (day: string) => {
    setSelectedDay(day);
  }
  const OnClickSetYear = (year: string) => {
    setSelectedYear(year);
  }

  const OnClickDisplay = () => {
    const transferDay = `${selectedYear}${selectedDay.slice(0, 2)}${selectedDay.slice(3, 5)}`
    changeTransferDate(transferDay);
  }

  const OnClickMakePayment = async () => {
    setIsLoading(true);

    setProgressMessage("前回出力データの削除中");
    setProgressMessageDetail("");    // 前回支払い情報の削除
    await deletePaymentByTransferDate(currentTransferDay);

    // 支払い情報の作成
    setProgressMessage("支払い情報データの作成中");
    setProgressMessageDetail("");
    const result = await makePaymentData(currentTransferDay);

    if (result !== BILLING_RESULT_UPLOADED) {
      handleNotUploadShow();
    } else {
      console.log(result);
      changeTransferDate(currentTransferDay);
    }
    setIsLoading(false);
  }


  const changeTransferDate = async (transferDay: string) => {
    const result = await listPaymentByTransferDate(transferDay)
    setSalonPayments(result);
    setCurrentTransferDay(transferDay);
    // 当月か否か判定
    let currentMonth = false;
    let dt = new Date();
    let year = dt.getFullYear();
    let month = dt.getMonth() + 1;
    if (month === 1) {
      month = 13;
      year = year - 1;
    }
    var ym = String(year) + ("00" + String(month - 1)).slice(-2);
    // 前月が選択されていたらCurrentMouthをtrueにする
    if (transferDay.substring(0, 6) === ym) {
      currentMonth = true;
    }
    currentMonth = true;
    setCurrentMonth(currentMonth);

  }

  // 支払い履歴の初期表示
  const init = async () => {

    let offsetMouth = -1;
    // 本日が31日の場合のみ、offsetMonthを0にする

    if (new Date().getDate() === 31) {
      offsetMouth = 0;
    }

    const transferDay = getTransferDateString(8, offsetMouth);
    await changeTransferDate(transferDay);
    setIsLoading(false);
  }

  /**
  * 未アップロード警告モーダルダイアログの制御用
  */
  const [showNotUpload, setShowNotUpload] = useState(false);
  const handleNotUploadClose = () => setShowNotUpload(false);
  const handleNotUploadShow = () => setShowNotUpload(true);

  /**
  * 再作成警告モーダルダイアログの制御用
  */
  const [showWarningRemake, setShowWarningRemake] = useState(false);
  const handleWarningRemakeClose = () => setShowWarningRemake(false);
  const handleWarningRemakeShow = () => setShowWarningRemake(true);

  const handleWarningRemakeDone = () => {
    setShowWarningRemake(false);
    OnClickMakePayment();
  }
  const OnClickRemake = () => handleWarningRemakeShow();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
      {isLoading ? (
        <>
          <div className="fs-1 m-5 align-items-center text-center">{progressMessage}</div>
          <div className="fs-5 m-5 align-items-center text-center">{progressMessageDetail}</div>
          <Loading />
        </>
      ) : (
        <div className="container mt-5">
          <section className="row mb-4">
            <section className="col">
              <div className="d-flex col-12 border-bottom">
                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                <header className="d-flex align-items-center">
                  <h1 className="fs-4 m-2">支払い通知書</h1>
                </header>
              </div>
            </section>
          </section>

          <section className="row mb-3">
            <section className="col-8 col-md-6">
              <div className="input-group d-inline">
                <div className="d-flex align-items-center mx-2 d-inline">振替年月日：
                  {currentTransferDay.substring(0, 4)}
                  /{currentTransferDay.substring(4, 6)}
                  /{currentTransferDay.substring(6, 8)}
                </div>
                <div className="dropdown d-inline">
                  <button className="btn btn-outline-primary dropdown-toggle" type="button" id="selectYear" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedYear}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="selectYear">
                    {yearList.map((year) => (
                      <li>
                        <button className="dropdown-item" type="button" onClick={() => OnClickSetYear(year)} >{year}</button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="dropdown d-inline">
                  <button className="btn btn-outline-primary dropdown-toggle" type="button" id="selectDay" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedDay}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="selectDay">
                    {dayList.map((day) => (
                      <li>
                        <button className="dropdown-item" type="button" onClick={() => OnClickSetDay(day)}  >{day}</button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="input-group-append d-inline">
                  <button className="btn btn-primary" id="search" onClick={OnClickDisplay}>変更</button>
                </div>
              </div>
            </section>

            <section className="col-4 col-md-6 d-flex justify-content-end">
              {salonPayments.length > 0 && <button className="btn btn-primary shadow-sm" onClick={OnClickRemake}>支払い通知書再作成</button>}
              {/*
              <div className="input-group mb-3 shadow-sm">
                
                <input type="text" className="form-control" placeholder="検索キーワード" aria-label="検索キーワード" aria-describedby="search" />
                <div className="input-group-append">
                  <button className="btn btn-primary" id="search">検索</button>
                    </div>
              </div>*/}
            </section>
          </section>

          {salonPayments.length > 0 ?

            <section className="row mb-5">
              <section className="col">
                <table className="table table-bordered shadow-sm">
                  <thead>
                    {/*
                    <tr className="align-middle text-center">
                      <th style={{ width: 100 }}>店舗番号</th>
                      <th>店舗名</th>
                      <th>振替日</th>
                      <th>総件数</th>
                      <th>総金額</th>
                      <th>完了件数</th>
                      <th>失敗件数</th>
                      <th>完了金額</th>
                      <th>失敗金額</th>
                      <th style={{ width: 100 }}>詳細</th>
                    </tr>
                    */}
                    <tr className="align-middle text-center">
                      <th rowSpan={2}>店舗<br />番号</th>
                      <th rowSpan={2}>店舗名</th>
                      <th rowSpan={2}>振替日</th>
                      <th rowSpan={2}>合計件数</th>
                      <th className="text-muted small">完了件数</th>
                      <th rowSpan={2}>合計金額</th>
                      <th className="text-muted small">完了件数</th>
                      <th rowSpan={2}>詳細</th>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-muted small">失敗件数</th>
                      <th className="text-muted small">失敗件数</th>
                    </tr>


                  </thead>
                  <tbody>
                    {salonPayments.map((salonPayment, index) => (
                      <>
                        <tr key={index} className="align-middle">
                          <th rowSpan={2}>{salonPayment.storeId}</th>
                          <td rowSpan={2}>{salonPayment.storeName}</td>
                          <td rowSpan={2} className="text-center">{dateFormatString(salonPayment.transferDate)}</td>
                          <td rowSpan={2} className="text-end">{salonPayment.totalNumber.toLocaleString()}</td>
                          <td className="text-muted text-end small">{salonPayment.totalNumberOfSuccess.toLocaleString()}</td>
                          <td rowSpan={2} className="text-end">&yen;{salonPayment.totalFee.toLocaleString()}</td>
                          <td className="text-muted text-end small">&yen;{salonPayment.totalFeeOfSuccess.toLocaleString()}</td>
                          <td rowSpan={2}>
                            <div className="d-flex">
                              <button className="btn btn-primary shadow-sm mx-auto" id="detail" onClick={() => OnClickDetail(salonPayment.id)}>詳細</button>
                            </div>
                          </td>
                        </tr>
                        <tr key={index} className="align-middle">
                          <td className="text-muted text-end small">{salonPayment.totalNumberOfFailure.toLocaleString()}</td>
                          <td className="text-muted text-end small">&yen;{salonPayment.totalFeeOfFailure.toLocaleString()}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </section>
            </section>
            :
            (currentMonth === true ?
              <section className="row mb-5">
                <section className="col">
                  <div className="text-center my-5">対象年月の支払い情報はまだ作成されていません。</div>
                  <div className="text-center">
                    <button className="btn btn-primary shadow-sm" onClick={OnClickMakePayment}>支払い通知書作成</button>
                  </div>
                </section>
              </section>
              :
              <section className="row mb-5">
                <section className="col">
                  <div className="text-center my-5">対象年月の支払い情報はありません。</div>
                </section>
              </section>
            )

          }

          {/*未アップロード警告ダイアログ*/}
          <Modal show={showNotUpload} onHide={handleNotUploadClose}>
            <Modal.Header closeButton>
              <Modal.Title>支払い通知書作成エラー</Modal.Title>
            </Modal.Header>
            <Modal.Body>振替年月日の結果データがアップロードされていません。</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" style={{ width: 150 }} onClick={handleNotUploadClose}>確認</Button>
            </Modal.Footer>
          </Modal>

          {/*支払い通知再生成警告ダイアログ*/}
          <Modal show={showWarningRemake} onHide={handleWarningRemakeClose}>
            <Modal.Header closeButton>
              <Modal.Title>支払い通知書再作成</Modal.Title>
            </Modal.Header>
            <Modal.Body>支払い通知書を再作成すると以前のデータは上書きされます。<br />よろしいですか？</Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary ms-auto m-1" style={{ width: 150 }} type="submit" onClick={handleWarningRemakeDone}>はい</button>
              <button className="btn btn-primary ms-auto m-1" style={{ width: 150 }} type="submit" onClick={handleWarningRemakeClose}>いいえ</button>
            </Modal.Footer>
          </Modal>

        </div>
      )}
      <Footer />
    </>
  );
};