import { useState } from "react";
import { listServiceByStoreId, getMService } from '../graphql/queries';
import { MService, ListServiceByStoreIdQuery, DeleteMServiceInput, UpdateMServiceInput, CreateMServiceInput, GetMServiceQuery } from '../API';

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { createMService, deleteMService, updateMService } from "../graphql/mutations";

export const useService = () => {

    const [serviceMenus, setServiceMenus] = useState<MService[]>([]);
    const [serviceMenu, setServiceMenu] = useState<MService>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initServiceMenu = (storeId: string, serviceId: string) => {
        setServiceMenu({
            ...serviceMenu,
            storeId: storeId,
            serviceId: serviceId,
            courseName: "",
            monthlyPrice: 0
        });
    }

    /**
     * 一覧データ取得
     * @param storeId 
     * @returns 
     */
    const getServiceMenus = async (storeId: string): Promise<MService[]> => {
        const res = (await API.graphql(graphqlOperation(listServiceByStoreId, {
            storeId: `${storeId}`,
            limit: 999999,
            //nextToken: nextToken,
            sortDirection: 'ASC', // 'ASC' は昇順、'DESC' は降順
            sortField: 'serviceId' // serviceId でソート
        })) as GraphQLResult<ListServiceByStoreIdQuery>);

        console.log(res);
        const mServices = res.data?.ListServiceByStoreId?.items as MService[];
        setServiceMenus(mServices)
        //setNextToken(res.data.ListServiceByStoreId.nextToken);
        //setIsLoading(false);
        return (mServices);
    }

    /**
     * １件データ取得
     */
    const getServiceMenu = async (id: string) => {
        const res = (await API.graphql(graphqlOperation(getMService, {
            id: id,
        })) as GraphQLResult<GetMServiceQuery>);

        console.log(res);
        const mService = res.data.getMService as MService;
        setServiceMenu(mService)
        //setNextToken(res.data.listMAdmins.nextToken);

        //setIsLoading(false);
    }

    /**
     * １件データ削除
     * @param index 
     * @param menuId 
     */
    const deleteServiceMenu = async (menuId: string) => {

        console.log('deleteServiceMenu');
        const res = (await API.graphql(graphqlOperation(deleteMService, {
            input: { id: `${menuId}` }
        })) as GraphQLResult<DeleteMServiceInput>);

        //serviceMenus.splice( index , 1 ) ; 
        console.log(res);
    }

    const updateServiceMenu = async (service: MService) => {
        const res = (await API.graphql(graphqlOperation(updateMService, {
            input: {
                id: `${service.id}`,
                courseName: `${service.courseName}`,
                monthlyPrice: service.monthlyPrice,
            },
        })) as GraphQLResult<UpdateMServiceInput>);

        console.log(res);
        //navigate(`/StoreAccountDetail/${storeAccount.storeId}`);
        //const mAdmin = res.data as CreateMAdminInput ; 
        //setAdmin( mAdmin )
        //setNextToken(res.data.listMAdmins.nextToken);
        //setIsLoading(false);4
    }

    const createServiceMenu = async (service: MService) => {
        const res = (await API.graphql(graphqlOperation(createMService, {
            input: {
                storeId: `${service.storeId}`,
                serviceId: `${service.serviceId}`,
                courseName: `${service.courseName}`,
                monthlyPrice: service.monthlyPrice,
            },
        })) as GraphQLResult<CreateMServiceInput>);

        console.log(res);
    }

    const newServiceMenu = (storeId: string) => {
        let nextId = 0

        /* ワーニング対応map削除
        serviceMenus.map(( service ) => {
            if ( nextId < Number(service.serviceId) ){
                nextId = Number(service.serviceId)
            }
        })
        */

        for (const service of serviceMenus) {
            if (nextId < Number(service.serviceId)) {
                nextId = Number(service.serviceId)
            }
        }

        nextId++;

        return ({
            ...serviceMenu as MService
            , storeId: storeId
            , serviceId: String(nextId)
            , courseName: ""
        })
    }

    return {
        initServiceMenu,
        serviceMenus,
        setServiceMenus,
        serviceMenu,
        setServiceMenu,
        isLoading,
        setIsLoading,
        getServiceMenus,
        getServiceMenu,
        deleteServiceMenu,
        updateServiceMenu,
        createServiceMenu,
        newServiceMenu
    };

}
