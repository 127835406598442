import React, { useEffect, useState } from 'react';
//import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate } from "react-router-dom";
import { Footer } from "../atoms/Footer"
import { Header } from "../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
import { MSalonMember, MSalon, TBillingHeader } from '../API';
import { useSalonMember } from '../hooks/useSalonMember';

import { Loading } from '../atoms/Loading';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { makeTransferCSVData } from '../graphql/queries';
import { useSalon } from '../hooks/useSalon';
import { useBilling } from '../hooks/useBilling';
import { useCommonTools } from '../hooks/useCommonTools';
import { CONFIRM_PAPER_NO, CONSIGNOR_CODE, FORCE_CONFIRM_NO, FORCE_CONFIRM_YES } from '../global-define'
import { CONFIRM_PAPER_YES, ANYWHERE_UNCONFIRMED } from '../global-define';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';

export const TransInfoCSVDownload = (props) => {

  const { setUser, setAuthState, userName } = props;

  const navigate = useNavigate();
  const OnClickReturn = () => navigate('/MainMenu')

  const { listSalonAccounts, listSalonByClassification, getSalonAccount } = useSalon();
  const { listSalonMembersByStoreId, listSalonMembersByConfirmStatus } = useSalonMember();
  const { createBillingHeader, createBillingData, updateBillingHeader, getBillingHeadersByTransferDate, deleteBillingHeader, deleteBillingData } = useBilling();
  const { getTransferDateString, /*getNumberFromDateString, getNumberOfTransferDate,*/ dateFormatString, isValidTransferData, /*setTransferDate*/ } = useCommonTools();

  const [salonAccounts, setSalonAccounts] = useState<MSalon[]>([]);
  const [salonMembers, setSalonMembers] = useState<MSalonMember[]>([]);
  //  const [nextToken, setNextToken] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);   // ローディング中フラグ
  const [downLoadURL, setDownLoadURL] = useState<string>(''); // CSV生成完了フラグ
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [progressMessageDetail, setProgressMessageDetail] = useState<string>('');

  const [fullTotalNumber, setFullTotalNumber] = useState<number>(-1);
  const [fullTotalFee, setFullTotalFee] = useState<number>(-1);

  //const [ forceTransferDate , setForceTransferDate ] = useState<string>(getTransferDateString(8, 0));
  /*
    const onClickSetTransferDate = () => {
      setTransferDate(forceTransferDate);
    }
    const onChangeForceTransferDate = (event) => { setForceTransferDate( event.target.value ); }
  */

  const OnClickDownload = async (props) => {

    try {
      // ファイル名（アプラス指定) = S+委託者コード上５桁+振替年月日.txt
      const fileName = `S${CONSIGNOR_CODE.substring(0, 5)}${getTransferDateString(8, 0)}.txt`;
      //navigate('/MainMenu')
      setIsLoading(true);
      /* TransferDateが同じデータがあれば事前に削除 */
      setProgressMessage("前回出力データの削除中");
      setProgressMessageDetail("");
      await deleteTransferDataByTransferDate(getTransferDateString(4, 0));
      setProgressMessage("振替請求データの作成中");
      setProgressMessageDetail("");
      const headerIds = await makeTransferData(fileName);
      console.log('Call lambda!');
      setProgressMessage("振替請求データのファイル出力中");
      setProgressMessageDetail("");
      const result = await API.graphql(graphqlOperation(makeTransferCSVData
        , { fileName: fileName, BillingHeaderIds: headerIds })) as any
      console.log(result);

      const signedURL = await Storage.get(fileName);
      setDownLoadURL(signedURL.toString());
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  }

  const deleteTransferDataByTransferDate = async (transferDate) => {

    const d = new Date();
    const transferYear = String(d.getFullYear());

    //    const billingHeaders = await getBillingHeadersByTransferDate(transferYear, transferDate);
    const billingHeaders = await getBillingHeadersByTransferDate(transferYear, transferDate);
    console.log(billingHeaders);

    // 同一振替日データが既に存在する
    if (billingHeaders != null) {

      let headerCount = billingHeaders.length;
      let headerIndex = 1;

      for (const billingHeader of billingHeaders) {
        const billingDatas = billingHeader.billingData.items;
        let dataCount = billingDatas.length;
        let dataIndex = 1;

        // ヘッダに含まれるデータを順次削除
        for (const billingData of billingDatas) {
          setProgressMessageDetail(`ヘッダ削除：${headerIndex.toString()} / ${headerCount.toString()}　データ削除：${dataIndex.toString()} / ${dataCount.toString()}`);
          await deleteBillingData(billingData);
          dataIndex++;
        }
        await deleteBillingHeader(billingHeader);
        headerIndex++;
      }
    }
  }

  /**
     * MSalonから存在する区分のリストを生成
     * 
     * @returns  {string[]}  MSalonに存在する区分の配列
     *
   */
  const getClassificationList = async () => {

    let listOfClassification = [];
    const salons = await listSalonAccounts();

    for (const salon of salons) {
      if (listOfClassification.indexOf(salon.classification) === -1) { // 見つからない
        listOfClassification.push(salon.classification);
      }
    }

    listOfClassification.sort(function (first, second) {
      if (Number(first) > Number(second)) {
        return 1;
      } else if (Number(first) < Number(second)) {
        return -1;
      } else {
        return 0;
      }
    })

    return (listOfClassification);
  }

  // 引き落とし情報の作成
  const makeTransferData = async (fileName: string) => {
    // 有効な請求区分コードの一覧を抽出
    const listOfClassification = await getClassificationList();
    let classCount = listOfClassification.length;
    let classIndex = 1;

    // 有効な区分コードに対して順次データを生成
    let headerIds = [];
    let allTotalNumber = 0;
    let allTotalFee = 0;

    for (const classification of listOfClassification) {
      // 区分コードに該当するヘッダを生成
      const header = await createBillingHeader(fileName, classification);
      // 区分コードに該当するサロンを取得
      const salons = await listSalonByClassification(classification);
      let totalNumber = 0;
      let totalFee = 0;

      let salonCount = salons.length;
      let salonIndex = 0;

      // サロン一覧の請求情報を順次生成
      for (const salon of salons) {
        salonIndex++;
        // サロンの有効会員情報を取得
        const members = await listSalonMembersByStoreId(salon.storeId);
        let memberCount = members.length;
        let memberIndex = 0;

        // 各会員の請求情報を作製
        for (const member of members) {
          memberIndex++;
          setProgressMessageDetail(`区分：${classIndex.toString()} / ${classCount.toString()}　サロン：${salonIndex.toString()} / ${salonCount.toString()}　メンバー：${memberIndex.toString()} / ${memberCount.toString()}`);

          // １件以上の有効サービスがあること
          if (member.useServices.items.length > 0) {
            // 契約月と同月でないこと（申込月は現金集金)
            //const transferDate = getNumberOfTransferDate();
            //const applicationDate = getNumberFromDateString(member.applicationDate);

            // 請求対象除外ルール
            // いずれのフラグも立っていなければ無条件に請求対象外
            if ((member.confirmStatus === ANYWHERE_UNCONFIRMED)
              && (member.confirmPaper === CONFIRM_PAPER_NO)
              && (member.forceConfirm === FORCE_CONFIRM_NO)) {
              continue;
            }

            // 日付の有効チェック
            if (isValidTransferData(member) === false) {
              continue;
            }

            // 請求明細の作成  
            const result = await createBillingData(header.data.createTBillingHeader as TBillingHeader, header.data.createTBillingHeader.downloadDateTime, totalNumber, member, salon);
            console.log(result);
            if (result !== null) {
              totalNumber++;
              totalFee += Number(result.data.createTBillingData.transferAmount);
            }
          }
        }
      }

      if (totalNumber > 0) {  // 請求データが１件以上存在した場合のみ
        // 結果データでヘッダ情報を更新
        const updateHeader = header.data.createTBillingHeader as TBillingHeader;
        updateHeader.totalNumber = totalNumber.toString();
        updateHeader.totalFee = totalFee.toString();
        await updateBillingHeader(updateHeader);

        allTotalNumber += totalNumber;
        allTotalFee += totalFee

        console.log(`classification:${classification}`);
        console.log(`allTotalNumber:${allTotalNumber}`);
        console.log(`allTotalFee:${allTotalFee}`);

        headerIds.push(header.data.createTBillingHeader.id);
      }
      classIndex++;
    }
    setFullTotalNumber(allTotalNumber);
    setFullTotalFee(allTotalFee);

    return (headerIds);
  }
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    const unconfirmedMembers = await listSalonMembersByConfirmStatus(ANYWHERE_UNCONFIRMED, '') as MSalonMember[];

    if (unconfirmedMembers !== null) {
      const notPaperCheckedUnconfirmedMembers = unconfirmedMembers.filter(member => (member.confirmPaper !== CONFIRM_PAPER_YES) // 振替用紙ならカード確認不要
        && (member.forceConfirm !== FORCE_CONFIRM_YES)
        && (isValidTransferData(member)));
      setSalonMembers(notPaperCheckedUnconfirmedMembers);

      for (const member of notPaperCheckedUnconfirmedMembers) {
        const salon = await getSalonAccount(member.storeId);
        salonAccounts.push(salon);
      }
      setSalonAccounts([...salonAccounts]);

      console.log(salonAccounts);
      console.log(notPaperCheckedUnconfirmedMembers);


    }


    setIsLoading(false);
  }

  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
      {isLoading ? (
        <>
          <div className="fs-1 m-5 align-items-center text-center">{progressMessage}</div>
          <div className="fs-5 m-5 align-items-center text-center">{progressMessageDetail}</div>
          <Loading />
        </>
      ) : (
        <div className="container mt-5">
          <section className="row mb-4">
            <section className="col">
              <div className="d-flex col-12 border-bottom">
                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                <header className="d-flex align-items-center">
                  <h1 className="fs-4 m-2">請求データファイルダウンロード</h1>
                </header>
              </div>
            </section>
          </section>
          {downLoadURL === '' ? (
            <>
              <section className="row">
                <section className="col">
                  <header className=" pb-2 mb-2 d-flex align-items-center ">
                    <h1 className="fs-4 mb-3 ">※ダウンロードボタンを押すと請求データファイルを作成します。
                      <br />　作成対象振替日時：{dateFormatString(getTransferDateString(8, 0))}</h1>
                  </header>
                </section>
              </section>
              {/*<input type="text" className="form-control" id="forceTransferDate" value={forceTransferDate} onChange={onChangeForceTransferDate}/>
              <input type="button" className="btn btn-primary" value="設定" onClick={onClickSetTransferDate} /> */}

              {(salonMembers.length > 0) &&
                <>
                  <section className="row">
                    <section className="col">
                      <header className=" pb-2 m-0 d-flex align-items-center ">
                        <h1 className="fs-6 m-1 ">下記会員のカード情報が未確認です。未確認会員の請求データは作成されません。
                        </h1>
                      </header>
                    </section>
                  </section>

                  <section className="row">
                    <section className="col">
                      <table className="table table-striped table-hover table-bordered shadow-sm">
                        <thead>
                          <tr>
                            <th style={{ width: 100 }}>加盟店舗</th>
                            <th style={{ width: 100 }}>会員ID</th>
                            <th style={{ width: 150 }}>氏名</th>
                            <th style={{ width: 150 }}>フリガナ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {salonMembers.map((member, index) => (
                            <tr key={index}>
                              <td>{salonAccounts[index].storeName}</td>
                              <td>{member.memberId}</td>
                              <td>{member.memberName}</td>
                              <td>{member.furigana}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </section>
                  </section>
                </>
              }
              <section className="row g-3 mb-5">
                <section className="col">
                  <div className="row mt-3">
                    <div className="col-md-12 d-flex">
                      <button className="btn btn-lg btn-primary shadow-sm ms-auto" style={{ width: 160 }} type="button" onClick={OnClickDownload}>ダウンロード</button>
                    </div>
                  </div>
                </section>
              </section>
            </>
          ) : (
            <>
              <div className="fs-2 mt-5 p-2 align-items-center text-center">請求データファイルの作成が完了しました。<br />下記リンクからダウンロードして下さい。</div>
              <div className="fs-2 mt-5 p-4 align-items-center text-center"><a href={downLoadURL} target="_blank" rel="noreferrer">請 求 デ ー タ フ ァ イ ル</a></div>
              <div className="row mt-4">
                <div className="col-3"></div>
                <div className="col-6 mt-5 align-items-center text-center">
                  <table className="table table-bordered shadow-sm fs-6">
                    <tbody>
                      <tr>
                        <th>請求件数</th><td><div className=" text-end">{fullTotalNumber} 件</div></td>
                      </tr>
                      <tr>
                        <th>請求金額</th><td><div className=" text-end">{fullTotalFee.toLocaleString()} 円</div></td>
                      </tr>
                      <tr>
                        <th>改行コード</th><td>有り</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>※上記の請求件数と請求金額をs-webの画面に入力して下さい。</div>
                </div>
                <div className="col-3"></div>
              </div>
              <section className="row g-3 mb-5">
                <section className="col">
                  <div className="row mt-3">
                    <div className="col-md-12 mt-4 align-items-center text-center">
                      <button className="btn btn-lg btn-primary" style={{ width: 240 }} type="button" onClick={OnClickReturn}>メインメニューへ</button>
                    </div>
                  </div>
                </section>
              </section>
            </>

          )}
        </div>
      )}
      <Footer />
    </>
  );
};