import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
// import { Button, Modal } from 'react-bootstrap';
import { useAnyWhere } from '../../hooks/useAnyWhere';
import { useSalon } from '../../hooks/useSalon';

import { Loading } from '../../atoms/Loading';
import { useSalonMember } from '../../hooks/useSalonMember';
import { MSalon, TAnyWhereDetail } from '../../API';
import { ANYWHERE_CONFIRMED } from '../../global-define';
//import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const AnyWhereCSVUploadComplete = ( props ) => {

    const { setUser, setAuthState , userName } = props;

    const [ salonAccounts, setSalonAccounts ] = useState<MSalon[]>([]);
    const { getSalonAccount } = useSalon();
    const { listAnyWhereDetail , updateDetailSalonMember } = useAnyWhere();
    const { listSalonMembersByBankInfo , updateSalonMemberDirect } = useSalonMember() ; 

    const navigate = useNavigate();
    const OnClickReturn = () => navigate('/MainMenu')

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const uploadDateTime = query.get("uploadDateTime");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ anyWhereDetails , setAnyWhereDetils ] = useState<TAnyWhereDetail[]>([]); 

    useEffect(() => {
        updateConfirmStatus(uploadDateTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateConfirmStatus = async (uploadDateTime: string) => {
        const details = await listAnyWhereDetail(uploadDateTime);
        let errorCount = 0 ; 
        
        for( let detail of details ) {
            let { bankCode , bankBranchCode , depositType , accountNumber } = detail ;  
            // 明細に対応づく会員を探す
            if ( bankCode === "9900"){
                bankBranchCode = accountNumber.substring(0,4);
                depositType = "普通" ; 
            }

            const members = await listSalonMembersByBankInfo( bankCode , bankBranchCode.substring(1) // 支店コードの頭１桁は不要
                 , depositType , accountNumber )

            // 該当メンバーがいた場合
            if ( members !== null ){                
                //if ( members.length > 1 ){ 
                //    console.log(`メンバー重複エラー LENGTH:${members.length.toString()}`)
                //    errorCount++ ;
                //}else{
                for ( let member of members ){
                
                    // AnyWhereDetailの関連メンバーを更新する
                    //const member = members[0];
 //                   if ( member.confirmStatus === ANYWHERE_UNCONFIRMED){
                        await updateDetailSalonMember( detail , member.id ) ;    
                        // メンバーを確認済みにする。
                        member.confirmStatus = ANYWHERE_CONFIRMED ; // 0:未確認 1:NG 2:OK
                        await updateSalonMemberDirect(member); 
//                    }
                }
            } else {
                errorCount++ ;
                console.log (`member not found error count(${errorCount.toString()})`) ;
            }
        }

        // 更新後の再取得
        const results = await listAnyWhereDetail(uploadDateTime);
        setAnyWhereDetils( results );

        for ( const detail of results ) {
            let salon = null ; 
            if ( detail.salonMember !== null ){
                salon = await getSalonAccount( detail.salonMember.storeId ) ;
            }
            salonAccounts.push(salon); 
        }
        setSalonAccounts([...salonAccounts]) ;
          
        console.log( results );
        setIsLoading(false);
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <>
                    <div className="fs-1 m-5 align-items-center text-center">デ ー タ 更 新 中 . . .</div>
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                {/*
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                */}
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">AnyWhereCSVアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    {/*
                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mb-4 d-flex align-items-center">
                                <h1 className="fs-4 m-0 ">選択店舗名：バル</h1>
                            </header>
                        </section>
                    </section>
                    */}
                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mb-2 d-flex align-items-center ">
                                <h1 className="fs-4 m-0 ">全データのマッチング登録が完了しました。</h1>
                            </header>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col">
                            <table className="table table-striped table-hover table-bordered shadow-sm">
                                <thead>
                                    <tr>
                                        <th colSpan={6}>AnyWhereデータ</th>
                                        <th colSpan={5}>マッチング会員</th>
                                    </tr>
                                    <tr>
                                        <th style={{ width: 200 }}>トランザクションID</th>
                                        <th style={{ width: 150 }}>銀行名</th>
                                        <th style={{ width: 100 }}>支店<br/>コード</th>
                                        <th style={{ width: 100 }}>預金種別</th>
                                        <th style={{ width: 100 }}>口座番号</th>
                                        <th style={{ width: 200 }}>口座名義</th>
                                        <th style={{ width: 100 }}>店舗ID</th>
                                        <th style={{ width: 100 }}>店舗名</th>
                                        <th style={{ width: 100 }}>会員ID</th>
                                        <th style={{ width: 150 }}>会員名</th>
                                        <th style={{ width: 150 }}>結果</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {anyWhereDetails.map(( detail , index ) =>(
                                        <tr key={index}>
                                            <td>{detail.transactionId}</td>
                                            <td>{detail.bankName}</td>
                                            <td>{detail.bankBranchCode}</td>
                                            <td>{detail.depositType}</td>
                                            <td>{detail.accountNumber}</td>
                                            <td>{detail.accountHolder}</td>
                                            <td>{ ( detail.salonMember !== null ) && detail.salonMember.storeId }</td>
                                            <td>{ ( detail.salonMember !== null ) && salonAccounts[index].storeName }</td>
                                            <td>{ ( detail.salonMember !== null ) && detail.salonMember.memberId }</td>
                                            <td>{ ( detail.salonMember !== null ) && detail.salonMember.memberName }</td>
                                            <td>{ ( detail.salonMember !== null ) ?  `確認完了` : `該当者なし`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </section>
                    <section className="row g-3 mb-5">
                        <section className="col">
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm ms-auto" style={{ width: 160 }} type="button"
                                        onClick={OnClickReturn}>完了</button>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};
