/**
 * BankCode-JP API
 * https://api.docs.bankcode-jp.com/#api-v1
 */
import axios from 'axios';
//import { useState } from "react";
/*
interface jsonType {
    id:number,
    data:string,
    flag:boolean
}
*/
export type Mbank = {
    __typename: "Mbanks",
    code: string | null,
    name: string | null,
    hiragana: string | null,
    fullWidthKana: string | null,
};

export type Mbranch = {
    __typename: "Mbranch",
    code: string | null,
    name: string | null,
    hiragana: string | null,
    fullWidthKana: string | null,
};

export const useBankcodejp = () => {

    const BASE_URL = 'https://apis.bankcode-jp.com/v1/';
    const API_KEY = 'bk_8329c67f6e8a46c599800c93d5a1125b';

    /**
     * 銀行リストの取得
     * 
     * https://api.docs.bankcode-jp.com/#90aa7c4e14
     * @param url 
     * @returns 
     */
    const getBanks = (async (filter:string) => {

        const API = "banks";
        const param_fields="code,name,hiragana,fullWidthKana"
        const param_apikey=API_KEY;
        const param_limit=2000;

        try {

            const url = BASE_URL+API+"?apikey="+param_apikey+"&limit="+param_limit+"&fields="+param_fields+"&filter="+filter;

            // 指定URLにGET
            const res = await axios.get(url);
            // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
            // 動作が完了して、リターンしてきたjsonを返す
            return res.data.data;
        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    });

    /**
     * 銀行の取得
     * 
     * https://api.docs.bankcode-jp.com/#90aa7c4e14
     * @param url 
     * @returns 
     */
    const getBank = (async (code:string) => {

        const API = "banks";
        const param_fields="code,name,hiragana,fullWidthKana"
        const param_apikey=API_KEY;

        try {

            const url = BASE_URL+API+"/"+code+"?apikey="+param_apikey+"&fields="+param_fields;

            // 指定URLにGET
            const res = await axios.get(url);
            // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
            // 動作が完了して、リターンしてきたjsonを返す
            return res.data;
        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    });
    
    /**
     * 支店名リストの取得
     * 
     * https://api.docs.bankcode-jp.com/#api-8
     * @param url 
     * @returns 
     */
    const getBranches = (async (bankCode:string, filter:string) => {

        const API = "branches";
        const param_fields="code,name,hiragana,fullWidthKana"
        const param_apikey=API_KEY;
        const param_limit=2000;

        try {

            const url = BASE_URL+"banks/"+bankCode+"/"+API+"?apikey="+param_apikey+"&limit="+param_limit+"&fields="+param_fields+"&filter="+filter;

            // 指定URLにGET
            const res = await axios.get(url);
            // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
            // 動作が完了して、リターンしてきたjsonを返す
            return res.data.data;
        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    });

    /**
     * 支店名の取得
     * 
     * https://api.docs.bankcode-jp.com/#api-8
     * @param url 
     * @returns 
     */
    const getBranch = (async (bankCode:string, code:string) => {

        const API = "branches";
        const param_fields="code,name,hiragana,fullWidthKana"
        const param_apikey=API_KEY;

        try {

            const url = BASE_URL+"banks/"+bankCode+"/"+API+"/"+code+"?apikey="+param_apikey+"&fields="+param_fields;

            // 指定URLにGET
            const res = await axios.get(url);
            // (↑のawaitがついていると関数が実行完了するまで↓を動作しない仕組み)
            // 動作が完了して、リターンしてきたjsonを返す
            return res.data[0];
        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    });
    
    return { getBanks, getBranches, getBank, getBranch };
}