import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";

import { API, graphqlOperation } from 'aws-amplify';
import { getMAdmin } from '../../graphql/queries';
import { deleteMAdmin } from '../../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { GetMAdminQuery, CreateMAdminInput ,DeleteMAdminMutation } from '../../API';
import '../../css/common.css';
import { Button, Modal} from 'react-bootstrap';
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';

export const AccountDetail = (props) => {

    const { setUser, setAuthState , userName } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    
    const { deleteUser } = useCognito();
    
    const OnClickEdit = () => {
        navigate(`/AccountEdit/${admin.id}`)
    }
    const OnClickDelete = () => {
        deleteAdmin() ; 
    }

    const OnClickReturn = () => navigate('/AccountList')

    console.log(id);
    const [admin, setAdmin] = useState<CreateMAdminInput>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getAdmin = async () => {
        const res = (await API.graphql(graphqlOperation(getMAdmin, {
            id: `${id}`,
        })) as GraphQLResult<GetMAdminQuery>);

        console.log(res);
        const mAdmin = res.data.getMAdmin as CreateMAdminInput;
        setAdmin(mAdmin)
        //setNextToken(res.data.listMAdmins.nextToken);
        setIsLoading(false);
    }

    const deleteAdmin = async () => {        
        try {
            setIsLoading(true);

            console.log('deleteAdmin');
            const res = (await API.graphql(graphqlOperation(deleteMAdmin, {
                input : { id: admin.id }
            })) as GraphQLResult<DeleteMAdminMutation>);

            console.log(res);
            const user = await deleteUser( admin.cognitoUserId );
            console.log( user ) ;  

            setIsLoading(false);
            navigate('/AccountList');

        } catch (e){
            console.log(e);
        }  
    }

    /**
     * 削除確認モーダルダイアログの制御用
     */
    const [showDeleteConfium, setShowDeleteConfium] = useState(false);
    const handleDeleteConfiumClose = () => setShowDeleteConfium(false);
    const handleDeleteConfiumShow = () => setShowDeleteConfium(true);
 
    useEffect(() => {
        getAdmin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">管理者アカウント　詳細</h1>
                            </header>
                        </div>
                        </section>
                    </section>

                    <div className="row row-cols-1 g-3">
                        <div className="col">
                            <label htmlFor="accountNo" className="form-label">社員番号</label>
                            <div className="input-group">
                                <input type="text" className="form-control shadow-sm" id="accountNo" value={admin.accountNo} disabled/>
                            </div>
                        </div>
                        <div className="col">
                            <label htmlFor="mail" className="form-label">メールアドレス</label>
                            <input type="text" className="form-control shadow-sm" id="mail" value={admin.mail} disabled/>
                        </div>
                        <div className="col">
                            <label htmlFor="name" className="form-label">氏名</label>
                            <input type="text" className="form-control shadow-sm" id="name" value={admin.name} disabled/>
                        </div>
                        <div className="col">
                            <label htmlFor="contact" className="form-label">連絡先</label>
                            <input type="text" className="form-control shadow-sm" id="contact" value={admin.contact} disabled/>
                        </div>
                        <div className="col">
                            <label htmlFor="password" className="form-label">パスワード</label>
                            <div className="input-group">
                                <input type="password" className="form-control shadow-sm" id="password" value={admin.password} disabled/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-5">
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={OnClickEdit}>編集する</button>
                                <button className="btn btn-lg btn-danger m-1" style={{ width: 150 }} type="button" onClick={handleDeleteConfiumShow}>削除する</button>
                            </div>
                        </div>
                    </div>

                    {/*削除確認ダイアログ*/}
                    <Modal show={showDeleteConfium} onHide={handleDeleteConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>削除します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ width: 150 }} onClick={handleDeleteConfiumClose}>いいえ</Button>
                            <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnClickDelete}>はい</button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};