import React from 'react';
import '../css/common.css';

export const Footer = () => {
    return (
        <>
            <footer className="position-fixed bottom-0 w-100 bg-dark bg-gradient text-center text-light p-2 footer-custom">
                <div className="container">
                    <span>(c) 2022 EN.beauty works</span>
                </div>        
            </footer>
        </>
    )
}