import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { useSalon } from "../../hooks/useSalon";
import { useService } from "../../hooks/useService";
import { useForm } from 'react-hook-form'
import '../../css/common.css';
import { Button, Modal } from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { MSalon } from '../../API';

type Inputs = {
    courseName: string;
    monthlyPrice: number;
}

export const StoreServiceMenuEdit = (props) => {

    const { setUser, setAuthState, userName } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { serviceMenu, setServiceMenu, getServiceMenu, updateServiceMenu, deleteServiceMenu } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getSalonAccount } = useSalon();
    const [ salonAccount , setSalonAccount ] = useState<MSalon>();

    const navigate = useNavigate();

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate(`/StoreServiceMenuList?storeId=${query.get("storeId")}`)
    /**
     * 削除ボタン押下
     */
    const OnClickDelete = async (menuId: string) => {

        setIsLoading(true);
        setShowDeleteConfirm(false);
        await deleteServiceMenu(menuId);
        setIsLoading(false);
        navigate(`/StoreServiceMenuList?storeId=${query.get("storeId")}`);
    }
    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        // 更新
        await updateServiceMenu(serviceMenu);
        // 遷移
        navigate(`/StoreServiceMenuList?storeId=${query.get("storeId")}`);
    }

    const onChangeCourseName = (event) => { setServiceMenu({ ...serviceMenu, courseName: event.target.value }); }
    const onChangeMonthlyPrice = (event) => { setServiceMenu({ ...serviceMenu, monthlyPrice: event.target.valueAsNumber }); }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showEditConfirm, setShowEditConfirm] = useState(false);
    const handleEditConfirmClose = () => setShowEditConfirm(false);
    const handleEditConfirmShow = () => setShowEditConfirm(true);
    /**
     * 削除確認モーダルダイアログの制御用
     */
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
    const handleDeleteConfirmShow = () => {
        // 誰も利用者がいなかった時のみ削除可能
        if (serviceMenu.salonMembers.items.length === 0) {
            setShowDeleteConfirm(true) ;        //削除警告ダイアログを表示
        } else {
            setShowCantDeleteConfirm(true) ;    //削除不能メッセージを表示
        }
    }
    /**
     * 削除不能モーダルダイアログの制御用
     */
    const [showCantDeleteConfirm, setShowCantDeleteConfirm] = useState(false);
    const handleCantDeleteConfirmClose = () => setShowCantDeleteConfirm(false);
    //const handleCantDeleteConfirmShow = () => setShowCantDeleteConfirm(true);
 
    const getData = async () => {
        const result = await getSalonAccount(query.get("storeId"));
        setSalonAccount( result );
        await getServiceMenu(query.get("mServiceId"));

        setIsLoading(false);
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">サービスメニュー　編集</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col-8 col-md-6">
                            <div className="input-group mb-3">
                                <h1 className="fs-4 m-0 ">選択店舗：{salonAccount.storeName}</h1>
                            </div>
                        </section>
                    </section>

                    <section className="col">
                        <div className="col-md-12">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="CourseName" className="form-label required">コース名称</label>
                                    <input type="text" {...register('courseName', { required: true, })} className="form-control" id="courseName" value={serviceMenu.courseName} onChange={onChangeCourseName} autoFocus />
                                    {errors.courseName?.types?.required && <div className="text-danger">コース名称を入力してください</div>}
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="monthlyPrice" className="form-label required">月額（税込）</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{ textAlign: 'right' }} {...register('monthlyPrice', { required: true, min: 1, })} className="form-control" id="monthlyPrice" value={serviceMenu.monthlyPrice} onChange={onChangeMonthlyPrice} placeholder="" />
                                    </div>
                                    {errors.monthlyPrice?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.monthlyPrice?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-6">
                                <div className="d-flex">
                                    <button className="btn btn-lg btn-success mx-auto m-1" style={{ width: 150 }} type="button" onClick={handleEditConfirmShow}>更新する</button>
                                    <button className="btn btn-lg btn-danger m-1" id="delete" style={{ width: 150 }} onClick={handleDeleteConfirmShow}>削除する</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>

                        {/*更新確認ダイアログ*/}
                        <Modal show={showEditConfirm} onHide={handleEditConfirmClose}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>確認</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>更新します。よろしいですか？</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" style={{ width: 150 }} onClick={handleEditConfirmClose}>いいえ</Button>
                                    <button className="btn btn-primary ms-auto m-1" style={{ width: 150 }} type="submit" onClick={handleEditConfirmClose}>はい</button>
                                </Modal.Footer>
                            </form>
                        </Modal>

                        {/*削除確認ダイアログ*/}
                        <Modal show={showDeleteConfirm} onHide={handleDeleteConfirmClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>削除します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{ width: 150 }} onClick={handleDeleteConfirmClose}>いいえ</Button>
                                <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={() => OnClickDelete(serviceMenu.id)}>はい</button>
                            </Modal.Footer>
                        </Modal>

                        {/*削除不能ダイアログ*/}
                        <Modal show={showCantDeleteConfirm} onHide={handleCantDeleteConfirmClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>サービス利用中</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>サービス利用中のユーザがいる為、削除できません。</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{ width: 150 }} onClick={handleCantDeleteConfirmClose}>確認</Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};