import React ,{ createContext , Dispatch , ReactNode, SetStateAction , useState } from "react" ;
import { MSystemCharge } from "../API"; 

type SystemChargeType = {
    systemChargeContext: MSystemCharge | null 
    setSystemChargeContext: Dispatch<SetStateAction<MSystemCharge | null>>
}

export const SystemSettingContext = createContext<SystemChargeType>({} as SystemChargeType ) ;

export const SystemSettingProvider: React.FC = ( props : { children : ReactNode }) => {
    const { children } = props;
    
    const [ systemChargeContext , setSystemChargeContext ] = useState<MSystemCharge | null>(null);
    //const [ SystemChargeProvider , setSystemChargeProvider ] = useState(null);

    return (
        <SystemSettingContext.Provider value={{ systemChargeContext , setSystemChargeContext }}>
            {children}
        </SystemSettingContext.Provider>
    )
}