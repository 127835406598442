import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
import { useAnyWhere } from "../../hooks/useAnyWhere"
import { TAnyWhereHead } from '../../API';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const AnyWhereCSVUploadList = ( props ) => {

    const { setUser, setAuthState , userName } = props;

    const { getAnyWhereHead } = useAnyWhere();
    const [anyWhereHead, setAnyWhereHead] = useState<TAnyWhereHead>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const OnClickRegist = () => {        
        navigate(`/AnyWhereCSVUploadComplete?uploadDateTime=${ anyWhereHead.uploadDateTime }`);
    }
    const OnClickReturn = () => navigate('/AnyWhereCSVUpload');

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get("uploadId");

    useEffect(() => {
        init(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async (headId: string) => {
        await setAnyWhereHead(await getAnyWhereHead(headId));
        setIsLoading(false);
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <>
                    <div className="fs-1 m-5 align-items-center text-center">デ ー タ ア ッ プ ロ ー ド 中 . . .</div>
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">AnyWhereCSVアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>                
 
                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mb-2 d-flex align-items-center ">
                                <h1 className="fs-4 m-0 ">アップロードファイル情報</h1>
                            </header>
                        </section>
                    </section>
                    <div className="row g-3">
                        <div className="col-md-8">
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">ファイル名</h6>
                                    </div>
                                    <span className="text-muted" style={{ overflowWrap: "anywhere" }}>{anyWhereHead.fileName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">登録可能件数</h6>
                                    </div>
                                    <span className="text-muted">{anyWhereHead.dataCount}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">エラー件数</h6>
                                    </div>
                                    <span className="text-muted">{anyWhereHead.errorCount}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*
                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mt-2 mb-2 d-flex align-items-center ">
                                <h1 className="fs-4 m-0 ">不正データ</h1>
                            </header>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col">
                            <table className="table table-striped table-hover table-bordered shadow-sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 200 }}>トランザクションID</th>
                                        <th style={{ width: 200 }}>端末番号</th>
                                        <th style={{ width: 80 }}>企業コード</th>
                                        <th style={{ width: 50 }}>結果</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>00000000010</td>
                                        <td>12345-678-90123</td>
                                        <td>1004</td>
                                        <td>○</td>
                                    </tr>
                                    <tr>
                                        <td>00000000015</td>
                                        <td>12345-678-90123</td>
                                        <td>1004</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>00000000015</td>
                                        <td>12345-678-90123</td>
                                        <td>1004</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mt-2 mb-2 d-flex align-items-center ">
                                <h1 className="fs-4 m-0 ">銀行情報が存在しないデータ</h1>
                            </header>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col">
                            <table className="table table-striped table-hover table-bordered shadow-sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 200 }}>トランザクションID</th>
                                        <th style={{ width: 200 }}>端末番号</th>
                                        <th style={{ width: 80 }}>企業コード</th>
                                        <th style={{ width: 50 }}>結果</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>00000000003</td>
                                        <td>12345-678-90123</td>
                                        <td>1004</td>
                                        <td>○</td>
                                    </tr>
                                    <tr>
                                        <td>00000000011</td>
                                        <td>12345-678-90123</td>
                                        <td>1004</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </section>
                    */}
                    <section className="row g-3 mb-5">
                        <section className="col">
                            <div className="row mt-3">
                                <div className="col-md-12 d-flex">
                                    <p className="border-bottom pb-2 mb-4 d-flex align-items-center text-muted">※登録ボタンで登録します。</p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm ms-auto m-1" style={{ width: 160 }} type="button"
                                        onClick={OnClickRegist}>登録</button>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};