import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { useSalon } from "../../hooks/useSalon";
import { Button, Modal} from 'react-bootstrap';
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import { MSalon } from '../../API';
import { useSalonMembers } from '../../hooks/useSalonMembers';
import { useService } from '../../hooks/useService';

export const StoreAccountDetail = ( props ) => {

    const { setUser, setAuthState , userName } = props;
    const { storeId } = useParams();
    const navigate = useNavigate();

    const { deleteSalonCognitoUserAll } = useCognito();
    const { getSalonAccount, deleteSalonAccount } = useSalon();
    const { getSalonMembers , deleteSalonMember } = useSalonMembers();
    const { getServiceMenus , deleteServiceMenu } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ salonAccount , setSalonAccount ] = useState<MSalon>();

    const OnClickEdit = () => {
        navigate(`/StoreAccountEdit/${salonAccount.storeId}`)
    }
    const OnClickDelete = async () => {

        setIsLoading(true);

        // 店舗に紐づく会員の削除
        const members = await getSalonMembers(salonAccount.storeId);
        await members.forEach(async ( member ) => {
            console.log( `deleteMember:${ member.id }`); 
            await deleteSalonMember( member );
        });

        // 店舗に紐づくサービスの削除
        const services = await getServiceMenus(storeId);
        await services.forEach( async( service ) => {
            console.log( `deleteService:${ service.id }`); 
            await deleteServiceMenu(service.id) 
        }); 

        await deleteSalonCognitoUserAll( salonAccount );
        await deleteSalonAccount(salonAccount.id);
        navigate('/StoreAccountList');
    
        //setIsLoading(false);
    }
    
    const OnClickReturn = () => navigate('/StoreAccountList')

    /* サロン情報を取得 */
    const getData = async () => {
        
        const mSalon = await getSalonAccount(storeId);
        setSalonAccount( mSalon ); 
        console.log( mSalon );
        setIsLoading(false);
    }

    /**
     * 削除確認モーダルダイアログの制御用
     */
    const [showDeleteConfium, setShowDeleteConfium] = useState(false);
    const handleDeleteConfiumClose = () => setShowDeleteConfium(false);
    const handleDeleteConfiumShow = () => setShowDeleteConfium(true);
  
    /**
     * 画面ロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">店舗アカウント　詳細</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="row g-3">
                                <div className="col-6">
                                    <label htmlFor="storeNumber" className="form-label">加盟店番号</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="storeNumber" value={salonAccount.storeId} disabled/>
                                    </div>
                                </div>
                                <div className="col-6">
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="storeName" className="form-label required">店舗名</label>
                                    <input type="text" className="form-control" id="storeName" value={salonAccount.storeName} disabled/>
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="phone" className="form-label required">電話番号</label>
                                    <input type="text" className="form-control" id="phone" value={salonAccount.phone} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="storeAddress1" className="form-label required">住所１</label>
                                    <input type="text" className="form-control" id="storeAddress1" value={salonAccount.storeAddress1} disabled/>
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="storeAddress2" className="form-label">住所２</label>
                                    <input type="text" className="form-control" id="storeAddress2" value={salonAccount.storeAddress2} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName1" className="form-label required">担当者１氏名</label>
                                    <input type="text" className="form-control" id="personelName1" value={salonAccount.personelName1} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelMail1" className="form-label required">担当者１メールアドレス</label>
                                    <input type="text" className="form-control" id="personelMail1" value={salonAccount.personelMail1} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword1" className="form-label required">担当者１パスワード</label>
                                    <div className="input-group">
                                        <input type="password" className="form-control" id="personelPassword1" value={salonAccount.personelPassword1} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName2" className="form-label">担当者２氏名</label>
                                    <input type="text" className="form-control" id="personelName2" value={salonAccount.personelName2} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelMail2" className="form-label">担当者２メールアドレス</label>
                                    <input type="text" className="form-control" id="personelMail2" value={salonAccount.personelMail2} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword2" className="form-label">担当者２パスワード</label>
                                    <div className="input-group">
                                        <input type="password" className="form-control" id="personelPassword2" value={salonAccount.personelPassword2} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName3" className="form-label">担当者３氏名</label>
                                    <input type="text" className="form-control" id="personelName3" value={salonAccount.personelName3} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelMail3" className="form-label">担当者３メールアドレス</label>
                                    <input type="text" className="form-control" id="personelMail3" value={salonAccount.personelMail3} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword3" className="form-label">担当者３パスワード</label>
                                    <div className="input-group">
                                        <input type="password" className="form-control" id="personelPassword3" value={salonAccount.personelPassword3} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                {/*利用料／手数料*/}
                                <h4 className="d-flex justify-content-between align-items-center mt-4">
                                    利用料／手数料
                                </h4>
                                <div className="col-sm-6">
                                    <label htmlFor="basicCharge" className="form-label">サブスクリプションサービス基本利用料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="basicCharge" value={salonAccount.basicCharge.toLocaleString()} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="pricePerCase" className="form-label">サブスクリプションサービス手数料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">１件あたり</span>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="pricePerCase" value={salonAccount.pricePerCase.toLocaleString()} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankTransferFee" className="form-label">振替手数料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="text" style={{textAlign: 'right'}} className="form-control" id="bankTransferFee" value={salonAccount.bankTransferFee.toLocaleString()} disabled/>
                                    </div>
                                </div>

                                {/*オーナー預金口座情報*/}
                                <h4 className="d-flex justify-content-between align-items-center">
                                    オーナー預金口座情報
                                </h4>

                                <div className="col-sm-6">
                                    <label htmlFor="bankCode" className="form-label">銀行名</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="bankCode" value={salonAccount.bankCode} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankBranchCode" className="form-label">支店名</label>
                                    <input type="text" className="form-control" id="bankBranchCode" value={salonAccount.bankBranchCode} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankDepositType" className="form-label">預金種別</label>
                                    <input type="text" className="form-control" id="bankDepositType" value={salonAccount.bankDepositType} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankAccountNumber" className="form-label">口座番号</label>
                                    <input type="text" className="form-control" id="bankAccountNumber" value={salonAccount.bankAccountNumber} disabled/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankDepositerName" className="form-label">預金者名</label>
                                    <input type="text" className="form-control" id="bankDepositerName" value={salonAccount.bankDepositerName} disabled/>
                                </div>

                                {/*その他情報*/}
                                <h4 className="d-flex justify-content-between align-items-center">
                                    その他情報
                                </h4>

                                <div className="col-sm-6">
                                    <label htmlFor="classification" className="form-label">請求区分コード</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="classification" value={salonAccount.classification} disabled/>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <label htmlFor="classification" className="form-label">利用規約</label>
                                    <div className="input-group">
                                        <textarea className="form-control" rows={5} id="classification" value={ salonAccount.termsOfService } disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={OnClickEdit}>編集する</button>
                                <button className="btn btn-lg btn-danger m-1" style={{ width: 150 }} type="button" onClick={handleDeleteConfiumShow}>削除する</button>
                            </div>
                        </div>
                    </div>

                    {/*削除確認ダイアログ*/}
                    <Modal show={showDeleteConfium} onHide={handleDeleteConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>削除します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{ width: 150 }} onClick={handleDeleteConfiumClose}>いいえ</Button>
                            <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="button" onClick={OnClickDelete}>はい</button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};