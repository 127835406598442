import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Loading } from '../../atoms/Loading';
import { anyWhereCSVtoDynamoDB } from '../../graphql/queries';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import awsExports from '../../aws-exports';
//Amplify.configure(awsExports);{ aws_user_files_s3_bucket }


export const AnyWhereCSVUpload = ( props ) => {

    const { setUser, setAuthState , userName } = props;
    const [noSelect, setNoSelect] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const OnClickUpload = async () => {

        const fileInput = document.getElementById('file') as HTMLInputElement;
        //var files = e.target.files || e.dataTransfer.files
        const files = fileInput.files;
                   
        if (files.length === 0) {
            // ファイル未指定
            setNoSelect(true);
            return;
        }

        setIsLoading(true);
        
        console.log(`★${new Date().toLocaleString()}_転送開始`)

        await Storage.put(files[0].name, files[0])
            .then(result => console.log(result)) // {key: "test.txt"}
            .catch(err => console.log(err));

        const result = await API.graphql(graphqlOperation( anyWhereCSVtoDynamoDB , { bucketName: awsExports.aws_user_files_s3_bucket
                            , fileName: `public/${files[0].name}` })) as any 
        
        navigate(`/AnyWhereCSVUploadList?uploadId=${result.data.anyWhereCSVtoDynamoDB}`);
        setIsLoading(false);

    }
    const OnClickReturn = () => navigate('/MainMenu')
    const OnChangeUpload = (e) => {
        //        var files = e.target.files || e.dataTransfer.files
        //        console.log(`★${new Date().toLocaleString()}_転送開始`)

        //        Storage.put(files[0].name, files[0])
        //            .then(result => console.log(result)) // {key: "test.txt"}
        //            .catch(err => console.log(err));

        //        navigate('/AnyWhereCSVUploadList')
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <>
                    <div className="fs-1 m-5 align-items-center text-center">デ ー タ ア ッ プ ロ ー ド 中 . . .</div>
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">AnyWhereCSVアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col">
                            <header className="pb-2 mb-4 d-flex align-items-center">
                                <h1 className="fs-4 m-0 ">※AnyWhereCSVを選択してアップロードを押してください。</h1>
                            </header>
                        </section>
                    </section>

                    <section className="row g-3">
                        <section className="col">
                            <div className="col-md-12">
                                <div className="input-group">
                                    <input type="file" className="form-control" id="file" placeholder="" onChange={OnChangeUpload} />
                                </div>
                                {noSelect === true && <div className="text-danger">AnyWhereCSVを選択してください。</div>}
                            </div>

                            <div className="row mt-3 mb-5">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm ms-auto m-1" style={{ width: 160 }} type="button"
                                        onClick={OnClickUpload}>アップロード</button>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};