import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";

import { API, graphqlOperation } from 'aws-amplify';
import { listMSystemCharges } from '../../graphql/queries';
import { useSalon } from "../../hooks/useSalon";
import { GraphQLResult } from '@aws-amplify/api';
import { ListMSystemChargesQuery, MSalon , MSystemCharge } from '../../API';
import { useForm } from 'react-hook-form'
import '../../css/common.css';
// get our fontawesome imports
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal} from 'react-bootstrap';
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useCommonTools } from '../../hooks/useCommonTools';
import { EMAIL_CHECK_PATTERN } from '../../global-define';

type Inputs = {
    storeId: string;
    storeName: string;
    storeAddress1: string;
    storeAddress2: string;
    phone: string;
    personelName1: string;
    personelMail1: string;
    personelPassword1: string;
    personelName2: string;
    personelMail2: string;
    personelPassword2: string;
    personelName3: string;
    personelMail3: string;
    personelPassword3: string;
    bankCode: string;
    bankBranchCode: string;
    bankDepositType: string;
    bankAccountNumber: string;
    bankDepositerName: string;
    bankConfirmStatus: string;
    basicCharge: number;
    pricePerCase: number;
    bankTransferFee: number;
    classification: string;
}
  
export const StoreAccountRegister = ( props ) => {

    const { setUser, setAuthState , userName } = props;
    const {
        register, 
        handleSubmit, 
        formState: { errors },
        setValue,
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 
    
    const { initSalonAccount , getNewStoreId , createSalonAccount } = useSalon();
    //const [systemCharge, setSystemCharge] = useState<MSystemCharge>();
    const { createSalonCognitoUserAll, getUser } = useCognito() ; 
    const { disableAutoCompleteAll } = useCommonTools();


    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [salonAccount, setSalonAccount] = useState<MSalon>({ __typename: "MSalon", createdAt: "create", updatedAt: "update", storeId: "99999", classification: "00" });

    //const [ salonAccount , setSalonAccount ] = useState<MSalon>();

//    const [nextToken, setNextToken] = useState<string>(null);

    // Validate
    const [isPersonelMail1NotUse, setIsPersonelMail1NotUse] = useState<boolean>(true);
    const [isPersonel2Checks, setIsPersonel2Checks] = useState<boolean>(true);
    const [isPersonelName2Checks, setIsPersonelName2Checks] = useState<boolean>(true);
    const [isPersonelMail2Checks, setIsPersonelMail2Checks] = useState<boolean>(true);
    const [isPersonelMail2NotUse, setIsPersonelMail2NotUse] = useState<boolean>(true);
    const [isPersonelPassword2Checks, setIsPersonelPassword2Checks] = useState<boolean>(true);
    const [isPersonel3Checks, setIsPersonel3Checks] = useState<boolean>(true);
    const [isPersonelName3Checks, setIsPersonelName3Checks] = useState<boolean>(true);
    const [isPersonelMail3Checks, setIsPersonelMail3Checks] = useState<boolean>(true);
    const [isPersonelMail3NotUse, setIsPersonelMail3NotUse] = useState<boolean>(true);
    const [isPersonelPassword3Checks, setIsPersonelPassword3Checks] = useState<boolean>(true);
    const [isSameMail, setIsSameMail] = useState<boolean>(true);
    const [cognitoError, setCognitoError] = useState<string>("");

    // Password Toggle
    const [isRevealPassword1, setIsRevealPassword1] = useState(false);
    const togglePassword1 = () => {
      setIsRevealPassword1((prevState) => !prevState);
    }
    const [isRevealPassword2, setIsRevealPassword2] = useState(false);
    const togglePassword2 = () => {
      setIsRevealPassword2((prevState) => !prevState);
    }
    const [isRevealPassword3, setIsRevealPassword3] = useState(false);
    const togglePassword3 = () => {
      setIsRevealPassword3((prevState) => !prevState);
    }

    const navigate = useNavigate();

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate('/StoreAccountList')

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {

        setIsLoading(true);

        var err = false;

        // 初期化
        setCognitoError("");
        setIsPersonelMail1NotUse(true);
        setIsPersonel2Checks(true);
        setIsPersonelName2Checks(true);
        setIsPersonelMail2Checks(true);
        setIsPersonelMail2NotUse(true);
        setIsPersonelPassword2Checks(true);
        setIsPersonel3Checks(true);
        setIsPersonelName3Checks(true);
        setIsPersonelMail3Checks(true);
        setIsPersonelMail3NotUse(true);
        setIsPersonelPassword3Checks(true);
        setIsSameMail(true);

        // 担当者２のいずれかが未入力
        if ((0 < salonAccount.personelName2.length || 0 < salonAccount.personelMail2.length || 0 < salonAccount.personelPassword2.length) &&
            ((salonAccount.personelName2.length*salonAccount.personelMail2.length*salonAccount.personelPassword2.length) === 0)) 
        {
            // 担当者２名前、担当者２メールアドレス、担当者２パスワードのいずれかが未入力
            err = true;
            setIsPersonel2Checks(false)
            if (salonAccount.personelName2.length === 0) setIsPersonelName2Checks(false)
            if (salonAccount.personelMail2.length === 0) setIsPersonelMail2Checks(false)
            if (salonAccount.personelPassword2.length === 0) setIsPersonelPassword2Checks(false)
        }
        
        // 担当者３のいずれかが未入力
        if ((0 < salonAccount.personelName3.length || 0 < salonAccount.personelMail3.length || 0 < salonAccount.personelPassword3.length) &&
                ((salonAccount.personelName3.length*salonAccount.personelMail3.length*salonAccount.personelPassword3.length) === 0)) 
        {
            err = true;
            setIsPersonel3Checks(false)
            if (salonAccount.personelName3.length === 0) setIsPersonelName3Checks(false)
            if (salonAccount.personelMail3.length === 0) setIsPersonelMail3Checks(false)
            if (salonAccount.personelPassword3.length === 0) setIsPersonelPassword3Checks(false)
        }
        
        // 担当者１～３で同じアドレスが無いか確認
        if (((0 < salonAccount.personelMail1.length && 0 < salonAccount.personelMail2.length) && (salonAccount.personelMail1 === salonAccount.personelMail2)) || 
            ((0 < salonAccount.personelMail1.length && 0 < salonAccount.personelMail3.length) && (salonAccount.personelMail1 === salonAccount.personelMail3)) ||
            ((0 < salonAccount.personelMail2.length && 0 < salonAccount.personelMail3.length) && (salonAccount.personelMail2 === salonAccount.personelMail3)) )
        {
            // 担当者１～３で同じアドレスが存在する
            err = true;
            setIsSameMail(false);
        }
        else
        {
            // 担当者１のメールアドレスが登録済みかチェック
            if (0 < salonAccount.personelMail1.length) 
            {
                const result = await getUser(salonAccount.personelMail1);
                if (result != null) {
                    err = true;
                    // メールアドレス登録済み
                    setIsPersonelMail1NotUse(false);
                }
            } 
            // 担当者２のメールアドレスが登録済みかチェック
            if (0 < salonAccount.personelMail2.length) 
            {
                const result = await getUser(salonAccount.personelMail2);
                if (result != null) {
                    err = true;
                    // メールアドレス登録済み
                    setIsPersonelMail2NotUse(false);
                }
            } 
            // 担当者３のメールアドレスが登録済みかチェック
            if (0 < salonAccount.personelMail3.length) 
            {
                const result = await getUser(salonAccount.personelMail3);
                if (result != null) {
                    err = true;
                    // メールアドレス登録済み
                    setIsPersonelMail3NotUse(false);
                }
            } 
        }

        if (err === false)
        {
            var cognitoIds;
            try {
                //CognitoにUserを登録する
                cognitoIds = await createSalonCognitoUserAll( salonAccount );         
                // DynamoDBに店舗アカウント情報を登録する
                await createSalonAccount( cognitoIds , salonAccount );
                // 遷移
                navigate(`/StoreAccountList`);
            } catch (error) {
                // 例外（通常Cognitoでのエラー）
                console.log(error);
                setCognitoError(error.message);
                return;
            }finally{
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    }
    
    const onChangeStoreId = (event) => { setSalonAccount({ ...salonAccount, storeId: event.target.value }); }
    const onChangeStoreName = (event) => { setSalonAccount({ ...salonAccount, storeName: event.target.value }); }
    const onChangePhone = (event) => { setSalonAccount({ ...salonAccount, phone: event.target.value }); }
    const onChangeStoreAddress1 = (event) => { setSalonAccount({ ...salonAccount, storeAddress1: event.target.value }); }
    const onChangeStoreAddress2 = (event) => { setSalonAccount({ ...salonAccount, storeAddress2: event.target.value }); }
    
    const onChangePersonelName1 = (event) => { setSalonAccount({ ...salonAccount, personelName1: event.target.value }); }
    const onChangePersonelMail1 = (event) => { 
        if (!event.isTrusted) return;
        setSalonAccount({ ...salonAccount, personelMail1: event.target.value }); 
    }
    const onChangePersonelPassword1 = (event) => { setSalonAccount({ ...salonAccount, personelPassword1: event.target.value }); }
    
    const onChangePersonelName2 = (event) => { setSalonAccount({ ...salonAccount, personelName2: event.target.value }); }
    const onChangePersonelMail2 = (event) => { setSalonAccount({ ...salonAccount, personelMail2: event.target.value }); }
    const onChangePersonelPassword2 = (event) => { setSalonAccount({ ...salonAccount, personelPassword2: event.target.value }); }
    
    const onChangePersonelName3 = (event) => { setSalonAccount({ ...salonAccount, personelName3: event.target.value }); }
    const onChangePersonelMail3 = (event) => { setSalonAccount({ ...salonAccount, personelMail3: event.target.value }); }
    const onChangePersonelPassword3 = (event) => { setSalonAccount({ ...salonAccount, personelPassword3: event.target.value }); }
    
    const onChangeBasicCharge = (event) => { setSalonAccount({ ...salonAccount, basicCharge: event.target.value }); }
    const onChangePricePerCase = (event) => { setSalonAccount({ ...salonAccount, pricePerCase: event.target.value }); }
    const onChangeBankTransferFee = (event) => { setSalonAccount({ ...salonAccount, bankTransferFee: event.target.value }); }

    const onChangeBankCode = (event) => { setSalonAccount({ ...salonAccount, bankCode: event.target.value }); }
    const onChangeBankBranchCode = (event) => { setSalonAccount({ ...salonAccount, bankBranchCode: event.target.value }); }
    const onChangeBankDepositType = (event) => { setSalonAccount({ ...salonAccount, bankDepositType: event.target.value }); }
    const onChangeBankAccountNumber = (event) => { setSalonAccount({ ...salonAccount, bankAccountNumber: event.target.value }); }
    const onChangeBankDepositerName = (event) => { setSalonAccount({ ...salonAccount, bankDepositerName: event.target.value }); }

    const onChangeClassification = (event) => { setSalonAccount({ ...salonAccount, classification: event.target.value }); }
    const onChangeTermsOfService = (event) => { setSalonAccount({ ...salonAccount, termsOfService: event.target.value }); }

    /**
     * 登録確認モーダルダイアログの制御用
     */
     const [showCreateConfium, setShowCreateConfium] = useState(false);
     const handleCreateConfiumClose = () => setShowCreateConfium(false);
     const handleCreateConfiumShow = () => setShowCreateConfium(true);
 
    /**
     * 新しい店舗IDの取得と初期化
     */
     const createNewSalon = async () => {
        const newStoreId = await getNewStoreId(); // 新しい店舗IDを取得
        const salon = initSalonAccount(newStoreId);     // 店舗IDを指定して初期化

        // 初期値として基本料取得
        await getSystemCharge( salon );

        setIsLoading(false);
    }

    const getSystemCharge = async ( salon : MSalon ) => {
        let res = null;
        try{
            res = (await API.graphql(graphqlOperation(listMSystemCharges, {
                limit: 999999,
                //nextToken: nextToken,
            })) as GraphQLResult<ListMSystemChargesQuery>);
        } catch (e) {
            salon.basicCharge = 0;
            salon.pricePerCase = 0;
            salon.bankTransferFee = 0;

            //setSalonAccount({ ...salon, basicCharge:0 , pricePerCase:0 , bankTransferFee:0}) ;
        }

        console.log(res);

        // 未登録（一度も登録していない、もしくはdeleteで削除され0件のとき）は登録を促すためEditに遷移
        if ((res === null) || (res.data.listMSystemCharges.items.length === 0)) {
            salon.basicCharge = 0;
            salon.pricePerCase = 0;
            salon.bankTransferFee = 0;
            //setSalonAccount({ ...salon, basicCharge:0 , pricePerCase:0 , bankTransferFee:0}) ;

        }else{
            // 取得したデータをセット
            const mServices = res.data.listMSystemCharges.items as MSystemCharge[];
            salon.basicCharge = mServices[0].basicCharge;
            salon.pricePerCase = mServices[0].pricePerCase;
            salon.bankTransferFee = mServices[0].bankTransferFee;
            //setSalonAccount({ ...salon, basicCharge:mServices[0].basicCharge , pricePerCase:mServices[0].pricePerCase , bankTransferFee:mServices[0].bankTransferFee }) ;
        }

        setSalonAccount( salon );
        setValue('basicCharge', salon.basicCharge);
        setValue('pricePerCase', salon.pricePerCase);
        setValue('bankTransferFee', salon.bankTransferFee);
    }

    /**
     * 画面ロード時の処理
     */

    useEffect(() => {

        disableAutoCompleteAll() ; 
        createNewSalon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">店舗アカウント　新規登録</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="row g-3">
                                <div className="col-6">
                                    <label htmlFor="storeNumber" className="form-label">加盟店番号</label>
                                    <div className="input-group">
                                        <input type="text" {...register('storeId' , { required:false, })} className="form-control" id="storeNumber" value={salonAccount.storeId} onChange={onChangeStoreId} disabled autoFocus/>
                                    </div>
                                </div>
                                <div className="col-6">
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="storeName" className="form-label required">店舗名</label>
                                    <input type="text" {...register('storeName' , { required:true, })} className="form-control" id="storeName" value={salonAccount.storeName} onChange={onChangeStoreName}/>
                                    {errors.storeName?.types?.required && <div className="text-danger">店舗名を入力してください</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="phone" className="form-label required">電話番号</label>
                                    <input type="text" {...register('phone' , { required:true, pattern: /^[0-9-]+$/ })} className="form-control" id="phone" value={salonAccount.phone} onChange={onChangePhone} placeholder="半角0～9およびハイフン"/>
                                    {errors.phone?.types?.required && <div className="text-danger">電話番号を入力してください</div>}
                                    {errors.phone?.types?.pattern && <div className="text-danger">半角0～9およびハイフンで入力してください</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="storeAddress1" className="form-label required">住所１</label>
                                    <input type="text" {...register('storeAddress1' , { required:true, })} className="form-control" id="storeAddress1" value={salonAccount.storeAddress1} onChange={onChangeStoreAddress1}/>
                                    {errors.storeAddress1?.types?.required && <div className="text-danger">住所１を入力してください</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="storeAddress2" className="form-label">住所２</label>
                                    <input type="text" {...register('storeAddress2' , { required:false, })} className="form-control" id="storeAddress2" value={salonAccount.storeAddress2} onChange={onChangeStoreAddress2}/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName1" className="form-label required">担当者１氏名</label>
                                    <input type="text" {...register('personelName1' , { required:true, })} className="form-control" id="personelName1" value={salonAccount.personelName1} onChange={onChangePersonelName1} autoComplete="new-password"/>
                                    {errors.personelName1?.types?.required && <div className="text-danger">担当者１氏名を入力してください</div>}
                                </div>

                                <div className="col-sm-6"> 
                                    <label htmlFor="personelMail1" className="form-label required">担当者１メールアドレス</label>
                                    <input type="text" {...register('personelMail1' , { required:true, pattern: EMAIL_CHECK_PATTERN })} className="form-control" id="personelMail1" value={salonAccount.personelMail1} onChange={onChangePersonelMail1} placeholder="半角文字" autoComplete="new-password"/>
                                    {errors.personelMail1?.types?.required && <div className="text-danger">担当者１メールアドレスを入力してください</div>}
                                    {errors.personelMail1?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                                    {isPersonelMail1NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                                    {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>} 
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword1" className="form-label required">担当者１パスワード</label>
                                    <div className="input-group">
                                        <input type={isRevealPassword1 ? 'text' : 'password'} {...register('personelPassword1' , { required:true, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword1" value={salonAccount.personelPassword1} onChange={onChangePersonelPassword1} placeholder="半角8文字以上" autoComplete="new-password"/>
                                        <span onClick={togglePassword1} role="presentation" className="PasswordReveal">
                                        {isRevealPassword1 ? (
                                            <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                                        ) : (
                                            <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                                        )}
                                        </span>
                                    </div>
                                    {errors.personelPassword1?.types?.required && <div className="text-danger">担当者１パスワードを入力してください</div>}
                                    {errors.personelPassword1?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                                    {errors.personelPassword1?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName2" className="form-label">担当者２氏名</label>
                                    <input type="text" {...register('personelName2' , { required:false, })} className="form-control" id="personelName2" value={salonAccount.personelName2} onChange={onChangePersonelName2}/>
                                    {(isPersonel2Checks === false && isPersonelName2Checks === false) && <div className="text-danger">担当者２氏名を入力してください</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelMail2" className="form-label">担当者２メールアドレス</label>
                                    <input type="text" {...register('personelMail2' , { required:false, pattern: EMAIL_CHECK_PATTERN })} className="form-control" id="personelMail2" value={salonAccount.personelMail2} onChange={onChangePersonelMail2} placeholder="半角文字"/>
                                    {errors.personelMail2?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                                    {(isPersonel2Checks === false && isPersonelMail2Checks === false) && <div className="text-danger">担当者２メールアドレスを入力してください</div>}
                                    {isPersonelMail2NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                                    {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword2" className="form-label">担当者２パスワード</label>
                                    <div className="input-group">
                                        <input type={isRevealPassword2 ? 'text' : 'password'} {...register('personelPassword2' , { required:false, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword2" value={salonAccount.personelPassword2} onChange={onChangePersonelPassword2} placeholder="半角8文字以上"/>
                                        <span onClick={togglePassword2} role="presentation" className="PasswordReveal">
                                        {isRevealPassword2 ? (
                                            <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                                        ) : (
                                            <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                                        )}
                                        </span>
                                    </div>
                                    {(isPersonel2Checks === false && isPersonelPassword2Checks === false) && <div className="text-danger">担当者２パスワードを入力してください</div>}
                                    {errors.personelPassword2?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                                    {errors.personelPassword2?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelName3" className="form-label">担当者３氏名</label>
                                    <input type="text" {...register('personelName3' , { required:false, })} className="form-control" id="personelName3" value={salonAccount.personelName3} onChange={onChangePersonelName3}/>
                                    {(isPersonel3Checks === false && isPersonelName3Checks === false) && <div className="text-danger">担当者３氏名を入力してください</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelMail3" className="form-label">担当者３メールアドレス</label>
                                    <input type="text" {...register('personelMail3' , { required:false, pattern: EMAIL_CHECK_PATTERN })} className="form-control" id="personelMail3" value={salonAccount.personelMail3} onChange={onChangePersonelMail3} placeholder="半角文字"/>
                                    {errors.personelMail3?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                                    {(isPersonel3Checks === false && isPersonelMail3Checks === false) && <div className="text-danger">担当者３メールアドレスを入力してください</div>}
                                    {isPersonelMail3NotUse === false && <div className="text-danger">メールアドレスは既に使用されています</div>}
                                    {isSameMail === false && <div className="text-danger">担当者１～３で同じメールアドレスは指定できません</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="personelPassword3" className="form-label">担当者３パスワード</label>
                                    <div className="input-group">
                                        <input type={isRevealPassword3 ? 'text' : 'password'} {...register('personelPassword3' , { required:false, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control" id="personelPassword3" value={salonAccount.personelPassword3} onChange={onChangePersonelPassword3} placeholder="半角8文字以上"/>
                                        <span onClick={togglePassword3} role="presentation" className="PasswordReveal">
                                        {isRevealPassword3 ? (
                                            <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                                        ) : (
                                            <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                                        )}
                                        </span>
                                    </div>
                                    {(isPersonel3Checks === false && isPersonelPassword3Checks === false) && <div className="text-danger">担当者３パスワードを入力してください</div>}
                                    {errors.personelPassword3?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
                                    {errors.personelPassword3?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
                                </div>

                                <div className="col-sm-6" style={{ marginTop: 0 }}></div>

                                {/*利用料／手数料*/}
                                <h4 className="d-flex justify-content-between align-items-center mt-4">
                                    利用料／手数料
                                </h4>
                                <div className="col-sm-6">
                                    <label htmlFor="basicCharge" className="form-label required">サブスクリプションサービス基本利用料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('basicCharge' , { required:true, min:1, })} className="form-control" value={salonAccount.basicCharge} onChange={onChangeBasicCharge} placeholder="" />
                                    </div>
                                    {errors.basicCharge?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.basicCharge?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="pricePerCase" className="form-label required">サブスクリプションサービス手数料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">１件あたり</span>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('pricePerCase' , { required:true, min:1, })} className="form-control" value={salonAccount.pricePerCase} onChange={onChangePricePerCase} placeholder="" />
                                    </div>
                                    {errors.pricePerCase?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.pricePerCase?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankTransferFee" className="form-label">振替手数料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('bankTransferFee' , { required:true, min:1, })} className="form-control" value={salonAccount.bankTransferFee} onChange={onChangeBankTransferFee} />
                                    </div>
                                    {errors.bankTransferFee?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.bankTransferFee?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                

                                {/*オーナー預金口座情報*/}
                                <h4 className="d-flex justify-content-between align-items-center mt-4">
                                    オーナー預金口座情報
                                </h4>
                                <div className="col-sm-6">
                                    <label htmlFor="bankCode" className="form-label">銀行名</label>
                                    <div className="input-group">
                                        <input type="text" {...register('bankCode' , { required:false, })} className="form-control" id="bankCode" value={salonAccount.bankCode} onChange={onChangeBankCode} placeholder="自由入力"/>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankBranchCode" className="form-label">支店名</label>
                                    <input type="text" {...register('bankBranchCode' , { required:false, })} className="form-control" id="bankBranchCode" value={salonAccount.bankBranchCode} onChange={onChangeBankBranchCode} placeholder="自由入力"/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankDepositType" className="form-label">預金種別</label>
                                    <input type="text" {...register('bankDepositType' , { required:false, })} className="form-control" id="bankDepositType" value={salonAccount.bankDepositType} onChange={onChangeBankDepositType} placeholder="自由入力"/>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankAccountNumber" className="form-label">口座番号</label>
                                    <input type="text" {...register('bankAccountNumber' , { required:false, pattern: /^[0-9]+$/, minLength:7, maxLength:7 })} className="form-control" id="bankAccountNumber" value={salonAccount.bankAccountNumber} onChange={onChangeBankAccountNumber} placeholder="半角7桁0～9の数字"/>
                                    {errors.bankAccountNumber?.types?.pattern && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                                    {errors.bankAccountNumber?.types?.minLength && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                                    {errors.bankAccountNumber?.types?.maxLength && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="bankDepositerName" className="form-label">預金者名</label>
                                    <input type="text" {...register('bankDepositerName' , { required:false, })} className="form-control" id="bankDepositerName" value={salonAccount.bankDepositerName} onChange={onChangeBankDepositerName} placeholder="自由入力"/>
                                </div>
                               

                                {/*その他情報*/}
                                <h4 className="d-flex justify-content-between align-items-center mt-4">
                                    その他情報
                                </h4>
                                <div className="col-sm-6">
                                    <label htmlFor="classification" className="form-label required">請求区分コード</label>
                                    <div className="input-group">
                                        <input type="text" {...register('classification' , {required: true, pattern: /^[0-9]{2}$/i})} className="form-control" id="classification" value={salonAccount.classification} onChange={onChangeClassification} placeholder="00～99で入力"/>
                                    </div>
                                    {errors.classification?.types?.required && <div className="text-danger">請求区分コードを 00～99 で入力してください</div>}
                                    {errors.classification?.types?.pattern && <div className="text-danger">請求区分コードは 00～99 で入力してください</div>}
                                </div>
                                
                                <div className="col-sm-12">
                                    <label className="form-label required">利用規約</label>
                                    <div className="input-group">
                                        <textarea className="form-control" rows={5} id="classification" value={ salonAccount.termsOfService } onChange={onChangeTermsOfService} placeholder="利用規約を入力"/>
                                    </div>
                                </div>

                                {/*Cognitoでのその他エラー*/}
                                {0 < cognitoError.length && <div className="text-danger">{cognitoError}</div>}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={handleCreateConfiumShow}>登録する</button>
                            </div>
                        </div>
                    </div>

                    {/*登録確認ダイアログ*/}
                    <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>登録します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{ width: 150 }} onClick={handleCreateConfiumClose}>いいえ</Button>
                                <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="submit" onClick={handleCreateConfiumClose}>はい</button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};