import { GetTUploadHeaderQuery, ListTUploadDataQuery, ListTUploadHeadersQuery, TUploadHeader } from './../API';
import { getTUploadHeader, listTUploadData, listTUploadHeaders } from './../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';

export const useBillingUploads = () => {

    const getUploadHeaders = async( ids : string[] ) => {

        try {
            let headerArray : TUploadHeader[] = [] ; //new Array(); array表記のワーニング対応

            for ( const id of ids ){
                const header = await getUploadHeader(id) ; 
                if ( header !== null ) {
                    headerArray.push( header as TUploadHeader ) ; 
                } 
            }

            return ( headerArray ) ; 
        } catch (e){
            console.log(e);
            return( null );
        } 
    }  

    const getUploadHeader = async (id: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(getTUploadHeader, {
                id: id
            })) as GraphQLResult<GetTUploadHeaderQuery>);

            console.log(res);
            const uploadHeader = res.data.getTUploadHeader as TUploadHeader;

            return (uploadHeader);
        } catch (e) {
            console.log(e);
            return (null);
        }
    }

    const listUploadData = async( uploadDateTime : string ) => {
        try {
            const res = (await API.graphql(graphqlOperation(listTUploadData, {
                uploadDateTime : uploadDateTime,
                limit: 999999
            })) as GraphQLResult<ListTUploadDataQuery>);

            console.log(res);
            const uploadData = res.data.listTUploadData.items

            return (uploadData);
        } catch (e) {
            console.log(e);
            return (null);
        }
    }

    const listUploadHeaders = async( arg ) => {
        try {
            let limit = 999999 ;
            if ( 'limit' in arg ) {
                limit = arg.limit ;
            }

            const res = (await API.graphql(graphqlOperation(listTUploadHeaders, {
                limit: limit
            })) as GraphQLResult<ListTUploadHeadersQuery>);

            console.log(res);
            const uploadData = res.data.listTUploadHeaders.items

            return (uploadData);
        } catch (e) {
            console.log(e);
            return (null);
        }
    }



    return { getUploadHeaders , getUploadHeader , listUploadData , listUploadHeaders }


}


