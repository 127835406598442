import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom" ;
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { MSalon, MSalonMember } from '../../API';
import { useSalon } from "../../hooks/useSalon";
import { useSalonMembers } from "../../hooks/useSalonMembers";
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

type Inputs = {
  searchStr: string ;
}

export const StoreMemberList = ( props ) => {

    const { setUser, setAuthState , userName } = props;

    const {
        register, 
        handleSubmit
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 
    
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const storeId = query.get("storeId")

    //const [nextToken, setNextToken] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchStr, setSearchStr] = useState<string>('');
    const { getSalonAccount } = useSalon();
    const { getSalonMembers } = useSalonMembers();
    const [salonAccount, setSalonAccount] = useState<MSalon>();
    const [salonMembers, setSalonMembers] = useState<MSalonMember[]>([]);



    const navigate = useNavigate(); 
    
    /**
     * 新規登録ボタン押下
     */
    const OnClickAddNew = () =>{
        let nextId = 0 

        /* ワーニング対応Map削除
        salonMembers.map(( member ) => {
            if ( nextId < Number(member.memberId) ){
                nextId = Number(member.memberId)
            }
        })
        */

        for ( const member of salonMembers ){
            if ( nextId < Number(member.memberId) ){
                nextId = Number(member.memberId)
            }
        }

        nextId ++;
        navigate(`/StoreMemberRegister?storeId=${storeId}&memberId=${nextId.toString()}`)
    }

    /**
     * 詳細ボタン押下
     * @param storeMember 
     */
    const OnClickDetail = ( storeMember:MSalonMember ) => {
        navigate(`/StoreMemberDetail?storeId=${storeMember.storeId}&memberId=${storeMember.memberId}`)
    }
    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => {
        navigate(`/StoreMenu?storeId=${storeId}`)
    }
    /**
     * 検索ボタン押下
     */
    const onSubmit = async () => {
        //----------------
        // 検索
        //----------------
        setIsLoading(true);
        console.log(search);
    
        // 登録済みのデータを抽出
        let members = await getSalonMembers(storeId);
        console.log(members);
        if ((members == null) || (members.length === 0))
        {
            // データ0件
            ;
        }
        else
        {
            if (0 < members.length) {
                // いずれかから部分一致に該当するデータを抽出する
                const mStoreMembers = members.filter(el =>
                    (el.storeId.indexOf(`${searchStr}`) !== -1) ||
                    (el.memberId.indexOf(`${searchStr}`) !== -1) ||
                    (el.applicationDate.indexOf(`${searchStr}`) !== -1) ||
                    (el.memberName.indexOf(`${searchStr}`) !== -1) ||
                    (el.furigana.indexOf(`${searchStr}`) !== -1) ||
                    (el.dateOfBirth.indexOf(`${searchStr}`) !== -1) ||
                    (el.zipCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.address1.indexOf(`${searchStr}`) !== -1) ||
                    (el.address2.indexOf(`${searchStr}`) !== -1) ||
                    (el.phoneNumber.indexOf(`${searchStr}`) !== -1) ||
                    (el.phoneNumberMobile.indexOf(`${searchStr}`) !== -1) ||
                    (el.mailAddress.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankName.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankBranchCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankBranchName.indexOf(`${searchStr}`) !== -1) ||
                    (el.depositType.indexOf(`${searchStr}`) !== -1) ||
                    (el.accountNumber.indexOf(`${searchStr}`) !== -1) ||
                    (el.depositerName.indexOf(`${searchStr}`) !== -1) ||
                    (el.confirmStatus.indexOf(`${searchStr}`) !== -1)
                );
                // 会員番号順にソートする
                mStoreMembers.sort(function(a, b) {
                    var memberIdA = a.memberId.toUpperCase(); // 大文字と小文字を無視する
                    var memberIdB = b.memberId.toUpperCase(); // 大文字と小文字を無視する
                    if (memberIdA < memberIdB) {
                        return -1;
                    }
                    if (memberIdA > memberIdB) {
                        return 1;
                    }
                
                    // accountNos must be equal
                    return 0;
                });

                setSalonMembers(mStoreMembers as MSalonMember[])
                //setNextToken(res.data.ListMemberByStoreId.nextToken);
            }
        }
        
        setIsLoading(false);
    }

    const onChangeSearchStr = (event) => setSearchStr(event.target.value);
    
    const getData = async () => {
        const result = await getSalonAccount(storeId);
        setSalonAccount( result ); 

        const members = await getSalonMembers(storeId);
        setSalonMembers( members ); 

        setIsLoading(false);
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
            <div className="container mt-5">
                <section className="row mb-4">
                    <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">会員　一覧</h1>
                            </header>
                        </div>
                    </section>
                </section>

                <section className="row">
                    <section className="col-8 col-md-6">
                        <div className="input-group mb-3">
                            <h1 className="fs-4 m-0 ">選択店舗：{salonAccount.storeName}</h1>
                        </div>
                    </section>
                </section>

                <section className="row">
                    <section className="col-8 col-md-6">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group mb-3">
                                <input type="text" {...register('searchStr')} className="form-control shadow-none" id="searchStr" value={searchStr} onChange={onChangeSearchStr} placeholder="検索キーワード" aria-label="検索キーワード" aria-describedby="search"/>
                                <span className="input-group-btn">
                                    <button type="submit" className="btn btn-primary shadow-none" >
                                    <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                        </form>
                    </section>
                    <section className="col-4 col-md-6">
                        <div className="d-flex mb-3">
                            <button className="btn btn-primary ms-auto shadow-sm" id="addon2" style={{ width: 110 }} onClick={OnClickAddNew}>新規登録</button>
                        </div>
                    </section>
                </section>
                <section className="row">
                    <section className="col">
                        <div className="float-end m-1">会員数 : {salonMembers.length.toLocaleString()} 名</div>                    
                    </section>
                </section>
                <section className="row mb-5">
                    <section className="col">
                    <table className="table table-hover table-bordered shadow-sm">
                        <thead>
                        <tr>
                            <th className="text-center" style={{width: 100}}>会員番号</th>
                            <th className="text-center">氏名</th>
                            <th className="text-center" style={{width: 100}}>詳細</th>
                        </tr>
                        </thead>
                        <tbody>
                            {((salonMembers == null) || (salonMembers.length === 0)) &&
                                <tr><td colSpan={3}>データが登録されていません</td></tr>
                            }
                            {salonMembers.sort((a, b) => Number(a.memberId) > Number(b.memberId) ? 1 : -1 ).map((salonMember , index) => (
                                <tr key={index}>
                                    <td className="align-middle text-end">{salonMember.memberId}</td>
                                    <td className="align-middle">{salonMember.memberName}</td>
                                    <td className="align-middle">
                                        <button className="btn btn-primary shadow-sm" id="detail" style={{width: 90}} onClick={() => OnClickDetail(salonMember)}>詳細</button>
                                    </td>
                                </tr>                            
                            ))}
                        </tbody>
                    </table>
                    </section>
                </section>
            </div>
            )}
            <Footer />
        </>
    ); 
};