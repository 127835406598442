import { updateTAnyWhereDetail } from './../graphql/mutations';
import { TAnyWhereHead, ListTAnyWhereHeadsQuery, ListTAnyWhereDetailsQuery, TAnyWhereDetail, UpdateTAnyWhereDetailInput } from './../API';
import { listTAnyWhereDetails, listTAnyWhereHeads } from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

export const useAnyWhere = () => {

    const getAnyWhereHead = async ( Id : string ) => {
        console.log( "head Id:" + Id ) ;

        const res = (await API.graphql(graphqlOperation( listTAnyWhereHeads, {
            id : `${Id}`,
            limit: 999999
        })) as GraphQLResult<ListTAnyWhereHeadsQuery>);

        console.log(res);
        const result = res.data.listTAnyWhereHeads.items as TAnyWhereHead[];
    
        return result[0] ;
    }

    const listAnyWhereDetail = async ( uploadDateTime : string ) => {
        console.log("uploadDateTime:" + uploadDateTime ) ;

        const res = ( await API.graphql(graphqlOperation( listTAnyWhereDetails , {
            uploadDateTime : `${uploadDateTime}`, 
            limit: 999999
        })) as GraphQLResult<ListTAnyWhereDetailsQuery>)

        console.log(res);
        const result = res.data.listTAnyWhereDetails.items as TAnyWhereDetail[];

        return ( result );
    } 

    const updateDetailSalonMember = async ( detail : TAnyWhereDetail , salonMemberID : string ) => {
        const res = (await API.graphql(graphqlOperation(updateTAnyWhereDetail, {
            input: {
                uploadDateTime: detail.uploadDateTime,
                lineNo : detail.lineNo,
                tAnyWhereDetailSalonMemberId : salonMemberID
            },
        })) as GraphQLResult<UpdateTAnyWhereDetailInput>);
     
        console.log(res);
        const result = res.data as TAnyWhereDetail ;

        return( result ); 
    }

    return { getAnyWhereHead , listAnyWhereDetail , updateDetailSalonMember };
}
