import React, { useState /*, useEffect */} from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"

import { API, graphqlOperation } from 'aws-amplify';
import { createMAdmin } from '../../graphql/mutations';
import { listMAdmins } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { CreateMAdminInput, ListMAdminsQuery } from '../../API';
import { useForm } from 'react-hook-form'
import '../../css/common.css';
// get our fontawesome imports
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from 'react-bootstrap';
import { Loading } from '../../atoms/Loading';
import { useCognito } from '../../hooks/useCognito';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { EMAIL_CHECK_PATTERN } from '../../global-define';

type Inputs = {
  accountNo: string;
  mail: string;
  name: string;
  contact: string;
  password: string;
}
export const AccountRegister = (props) => {

  const { setUser, setAuthState, userName } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createUser, setUserPassword, addUserToGroup } = useCognito();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const navigate = useNavigate();

  /**
   *  createAdmin
   *
   *  画面で登録ボタンが押された時の処理。画面の内容をDBに登録します。
   */
  const createAdmin = async () => {

    try {

      const attrs = [
        { Name: 'email', Value: mail },
        { Name: 'email_verified', Value: 'true' }
      ]

      const user = await createUser(mail, attrs);
      console.log(user);
      const pass = await setUserPassword(user.User.Username, password);
      console.log(pass);
      const group = await addUserToGroup(user.User.Username, 'Admins');
      console.log(group);

      const res = (await API.graphql(graphqlOperation(createMAdmin, {
        input: {
          accountNo: accountNo,
          mail: mail,
          name: name,
          contact: contact,
          password: password,
          cognitoUserId: user.User.Username
        },
      })) as GraphQLResult<CreateMAdminInput>);

      console.log(res);

    } catch (e) {
      console.log(e)
    }
    //const result = await Auth.updateUserAttributes( user ,  {
    //  email_verified : true
    //})
    //console.log( result );  
  }

  //const OnClickReset = () => navigate('/AccountList')
  const OnClickReturn = () => navigate('/AccountList')

//  const [nextToken, setNextToken] = useState<string>(null);
  //  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [accountNo, setAccountNo] = useState<string>('');
  const [isDuplicateAccountNo, setIsDuplicateAccountNo] = useState<boolean>(false);
  const [mail, setMail] = useState<string>('');
  const [isDuplicateMail, setIsDuplicateMail] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }

  // Webpage Event
  const onSubmit = async () => {
    //----------------
    // 重複チェック
    //----------------
    // 登録済みのデータを抽出
    setIsLoading(true);
    let res = null;
    try {
      res = (await API.graphql(graphqlOperation(listMAdmins, {
        limit: 999999,
      })) as GraphQLResult<ListMAdminsQuery>);
    } catch (e) {
      console.log(`エラー発生 ${e.errors}`);
    }
    console.log(res);

    let isDuplicateAccountNo = false;
    let isDuplicateMail = false;
    if ( 0 < res.data.listMAdmins.items.length ) {
      // 登録データ有り
      isDuplicateAccountNo = res.data.listMAdmins.items.some(el => (el.accountNo === `${accountNo}`));
      isDuplicateMail = res.data.listMAdmins.items.some(el => el.mail === `${mail}`);
      // いずれの項目か判定
      setIsDuplicateAccountNo(isDuplicateAccountNo);
      setIsDuplicateMail(isDuplicateMail);
    }
    if (!isDuplicateAccountNo && !isDuplicateMail) {
      // 重複無し（登録データ無し）
      // 登録
      await createAdmin();
      // 遷移
      navigate(`/AccountList`);
    }
    setIsLoading(false);

  }
  const onChangeAccountNo = (event) => setAccountNo(event.target.value);
  const onChangeMail = (event) => setMail(event.target.value);
  const onChangeName = (event) => setName(event.target.value);
  const onChangeContact = (event) => setContact(event.target.value);
  const onChangePassword = (event) => setPassword(event.target.value);

  /**
   * 登録確認モーダルダイアログの制御用
   */
  const [showCreateConfium, setShowCreateConfium] = useState(false);
  const handleCreateConfiumClose = () => setShowCreateConfium(false);
  const handleCreateConfiumShow = () => setShowCreateConfium(true);

  /**
   * レンダー後に呼ばれる処理（いわゆる画面表示時に呼ばれる処理）
   */
  /*
  useEffect(() => {
    // useEffectは起動時に引数なしを想定しているので第二引数は除外する（以下のコメントでVSCodeでの警告出力を抑制）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */
  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mt-5">
          <section className="row mb-4">
            <section className="col">
              <div className="d-flex col-12 border-bottom">
                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                <header className="d-flex align-items-center">
                  <h1 className="fs-4 m-2">管理者アカウント　新規登録</h1>
                </header>
              </div>
            </section>
          </section>

          <div className="row row-cols-1 g-3">
            <div className="col">
              <label htmlFor="accountNo" className="form-label required">社員番号</label>
              <div className="input-group">
                <input type="text" {...register('accountNo', { required: true, pattern: /^[a-zA-Z0-9-]+$/ })} className="form-control shadow-sm" id="accountNo" value={accountNo} onChange={onChangeAccountNo} placeholder="半角文字" autoFocus />
              </div>
              {errors.accountNo?.types?.required && <div className="text-danger">社員番号を入力してください</div>}
              {errors.accountNo?.types?.pattern && <div className="text-danger">半角のa～zもしくはA～Zもしくは0～9もしくは-で入力してください</div>}
              {isDuplicateAccountNo === true && <div className="text-danger">その社員番号は他のアカウントで使われています</div>}
            </div>
            <div className="col">
              <label htmlFor="mail" className="form-label required">メールアドレス</label>
              <input type="text" {...register('mail', { required: true, pattern: EMAIL_CHECK_PATTERN })} className="form-control shadow-sm" id="mail" value={mail} onChange={onChangeMail} placeholder="半角文字" />
              {errors.mail?.types?.required && <div className="text-danger">メールアドレスを入力してください</div>}
              {errors.mail?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
              {isDuplicateMail === true && <div className="text-danger">そのメールアドレスは他のアカウントで使われています</div>}
            </div>
            <div className="col">
              <label htmlFor="name" className="form-label required">氏名</label>
              <input type="text" {...register('name', { required: true, })} className="form-control shadow-sm" id="name" value={name} onChange={onChangeName} placeholder="" />
              {errors.name?.types?.required && <div className="text-danger">氏名を入力してください</div>}
            </div>
            <div className="col">
              <label htmlFor="contact" className="form-label required">連絡先</label>
              <input type="text" {...register('contact', { required: true, pattern: /^[0-9-]+$/ })} className="form-control shadow-sm" id="contact" value={contact} onChange={onChangeContact} placeholder="半角0～9およびハイフン" autoComplete="new-password" />
              {errors.contact?.types?.required && <div className="text-danger">連絡先を入力してください</div>}
              {errors.contact?.types?.pattern && <div className="text-danger">半角0～9およびハイフンで入力してください</div>}
            </div>
            <div className="col">
              <label htmlFor="password" className="form-label required">パスワード</label>
              <div className="input-group">
                <input type={isRevealPassword ? 'text' : 'password'} {...register('password', { required:true, minLength:8, pattern:/[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/ })} className="form-control shadow-sm" id="password" value={password} onChange={onChangePassword} placeholder="半角8文字以上" autoComplete="new-password" />
                <span onClick={togglePassword} role="presentation" className="PasswordReveal">
                  {isRevealPassword ? (
                    <i><FontAwesomeIcon icon={faEye} focusable="true" cursor='pointer' /></i>
                  ) : (
                    <i><FontAwesomeIcon icon={faEyeSlash} focusable="true" cursor='pointer' /></i>
                  )}
                </span>
              </div>
              {errors.password?.types?.required && <div className="text-danger">パスワードを入力してください</div>}
              {errors.password?.types?.minLength && <div className="text-danger">パスワードは半角8文字以上で入力してください</div>}
              {errors.password?.types?.pattern && <div className="text-danger">パスワードは「英字・数字・記号」のみ利用できます</div>}
            </div>
          </div>

          <div className="row mt-3 mb-5">
            <div className="col">
              <div className="d-flex justify-content-end">
                <button className="btn btn-lg btn-success ms-auto m-1" style={{ width: 150 }} type="button" onClick={handleCreateConfiumShow}>登録する</button>
              </div>
            </div>
          </div>

          {/*登録確認ダイアログ*/}
          <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>確認</Modal.Title>
              </Modal.Header>
              <Modal.Body>登録します。よろしいですか？</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" style={{ width: 150 }} onClick={handleCreateConfiumClose}>いいえ</Button>
                <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="submit" onClick={handleCreateConfiumClose}>はい</button>
              </Modal.Footer>
            </form>
          </Modal>

        </div>
      )}
      <Footer />
    </>
  );
};