import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { billResultCSVtoDynamoDB } from "../../graphql/queries";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

//import { Button, Modal} from 'react-bootstrap';

export const BillResultCSVUpload = (props) => {

    const { setUser, setAuthState , userName } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [noSelect, setNoSelect] = useState<boolean>(false);

    const [progressMessage, setProgressMessage] = useState<string>('');
//    const [progressMessageDetail, setProgressMessageDetail] = useState<string>('');
  
    const navigate = useNavigate();
    const OnClickUpload = async () => {
        try {
            const fileInput = document.getElementById('file') as HTMLInputElement;
            const files = fileInput.files;
           
            if (files.length === 0) {
                // ファイル未指定
                setNoSelect(true);
                return;
            }

            setIsLoading(true);
            setProgressMessage("結果データファイルアップロード中 . . .");

            console.log(`★${new Date().toLocaleString()}_転送開始`)

            await Storage.put(files[0].name, files[0])
                .then(result => console.log(result))
                .catch(err => console.log(err));

            setProgressMessage("アップロードデータ処理中 . . .");
            //const result = await API.graphql(graphqlOperation(billResultCSVtoDynamoDB, { fileName: `public/${files[0].name}` })) as any;
            // 呼びっぱなしで次へ行く
            API.graphql(graphqlOperation(billResultCSVtoDynamoDB, { fileName: `public/${files[0].name}` })) as any;

            //navigate(`/BillResultCSVInfo?uploadIds=${result.data.billResultCSVtoDynamoDB}`);
            // resultがもらえないので持たないまま次へ行く
            navigate(`/BillResultCSVInfo`);

        } catch (error) {
            console.error(error);
        }
    }
    const OnClickReturn = () => navigate('/BillResultCSVUploadHistory')

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <>
                    {/*<div className="fs-1 m-5 align-items-center text-center">デ ー タ ア ッ プ ロ ー ド 中 . . .</div>*/}
                    <div className="fs-1 m-5 align-items-center text-center">{progressMessage}</div>
                    {/*<div className="fs-5 m-5 align-items-center text-center">{progressMessageDetail}</div>*/}
                    <Loading />
                </>
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">結果データファイルアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col">
                            <header className="pb-2 mb-4 d-flex align-items-center">
                                <h1 className="fs-4 m-0 ">※結果データファイルを選択してアップロードを押してください。</h1>
                            </header>
                        </section>
                    </section>

                    <section className="row g-3 mb-5">
                        <section className="col">
                            <div className="col-md-12">
                                <div className="input-group">
                                    <input type="file" className="form-control" id="file" placeholder="" />
                                </div>
                                {noSelect === true && <div className="text-danger">結果データファイルを選択してください。</div>}
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm ms-auto m-1" style={{ width: 160 }} type="button"
                                        onClick={OnClickUpload}>アップロード</button>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};