import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { BiPersonFill } from "../../atoms/icons/BiPersonFill"
import { BiShieldLockFill } from "../../atoms/icons/BiShieldLockFill"
import { useSalon } from "../../hooks/useSalon";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import { MSalon } from '../../API';

export const StoreMenu = (props) => {

    const { setUser, setAuthState , userName } = props;

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const storeId = query.get("storeId");
    const [salonAccount, setSalonAccount] = useState<MSalon>();

    const { getSalonAccount } = useSalon();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const OnClickReturn = () => navigate('/StoreList')

    /**
     * １件データ取得
     */
    const getData = async () => {
        const result = await getSalonAccount(storeId);
        setSalonAccount( result ) ; 

        setIsLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">店舗管理メニュー</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col-8 col-md-6">
                            <div className="input-group mb-3">
                                <h1 className="fs-4 m-0 ">選択店舗：{salonAccount.storeName}</h1>
                            </div>
                        </section>
                    </section>

                    <section className="row mb-5">
                        <section className="col">
                            <div className="list-group fs-5 shadow-sm">
                                {/*}
                                <Link to={'/AnyWhereCSVUpload'} className="list-group-item list-group-item-action">
                                    <BiGearFill />
                                    AnyWhereCSVアップロード
                                </Link>
                                */}
                                <Link to={`/StoreServiceMenuList?storeId=${storeId}`} className="list-group-item list-group-item-action">
                                    <BiShieldLockFill />
                                    サービスメニュー管理
                                </Link>
                                <Link to={`/StoreMemberList?storeId=${storeId}`} className="list-group-item list-group-item-action">
                                    <BiPersonFill />
                                    会員管理
                                </Link>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};