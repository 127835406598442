import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { useUploadHistory } from '../../hooks/useUploadHistory';
import { TUploadHeader } from '../../API';
import { Loading } from '../../atoms/Loading';
import { useBillingUploads } from '../../hooks/useBillingUploads';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useCommonTools } from '../../hooks/useCommonTools';
import '../../css/common.css';

export const BillResultCSVUploadHistory = ( props ) => {

    const { setUser, setAuthState , userName } = props;
    //const { listUploadHistory } = useUploadHistory();
    const { listUploadHeaders } = useBillingUploads(); 
    const { dateTimeFormatString } = useCommonTools();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    //const [histories, setHistories] = useState<TUploadHistory[]>([])
    const [ headers  , setHeaders ] = useState<TUploadHeader[]>([]) 

    const navigate = useNavigate();
    const OnClickUpload = () => navigate('/BillResultCSVUpload')
    const OnClickReturn = () => navigate('/MainMenu')

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        //const uploadHistories = await listUploadHistory();
        const uploadHeaders  = await listUploadHeaders( { limit : 10 });  
        //setHistories(uploadHistories);
        setHeaders( uploadHeaders );
        setIsLoading( false ); 
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">結果データファイルアップロード</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    
                    <section className="row mb-4">
                        <section className="col">
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm" type="button" onClick={OnClickUpload}>結果データファイルをアップロードする</button>
                                </div>
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <section className="col">
                            <header className=" pb-2 mb-1 d-flex align-items-center">
                                <h1 className="fs-4 m-0 ">アップロード履歴</h1>
                            </header>
                        </section>
                    </section>

                    <section className="row mb-5">
                        <section className="col">
                            <div className="col-12">
                                <table className="table table-bordered shadow-sm">
                                    <thead>
                                        {/*
                                        <tr className="text-center align-middle">
                                            <th rowSpan={2}>日時</th>
                                            <th rowSpan={2}>ファイル</th>
                                            <th colSpan={7}>振替</th>
                                        </tr>
                                        <tr className="text-center align-middle">
                                            <th>年月日</th>
                                            <th>件数</th>
                                            <th>金額</th>
                                            <th>完了件数</th>
                                            <th>失敗件数</th>
                                            <th>完了金額</th>
                                            <th>失敗金額</th>
                                        </tr>         
                                        */}
                                        <tr className="text-center align-middle">
                                            <th rowSpan={3} style={{width:10}}>日時</th>
                                            <th rowSpan={3} style={{width:10}}>ファイル</th>
                                            <th rowSpan={3} colSpan={1} style={{width:10}}>振替<br/>年月日</th>
                                            <th rowSpan={3}>区分</th>
                                            <th colSpan={2}>振替件数</th>
                                            <th colSpan={2}>振替金額</th>
                                        </tr>

                                        <tr className="text-center align-middle">
                                            <th rowSpan={2}>合計<br/>件数</th>
                                            <th className="small text-muted">完了件数</th>
                                            <th rowSpan={2}>合計<br/>金額</th>
                                            <th className="small text-muted">完了件数</th>
                                        </tr>
                                        <tr className="text-center align-middle">
                                            <th className="small text-muted">失敗件数</th>
                                            <th className="small text-muted">失敗件数</th>
                                        </tr>                                        

                                    </thead>
                                    <tbody>
                                        {headers.sort((a, b) => a.uploadDateTime <= b.uploadDateTime ? 1 : -1 ).map((header, index) => (
                                            <>
                                            <tr key={index} className="align-middle">
                                                <td rowSpan={2} className="text-end">{dateTimeFormatString(header.uploadDateTime)}</td>
                                                <td rowSpan={2}>{header.uploadFileName.substring(header.uploadFileName.indexOf("/")+1)}</td>
                                                <td rowSpan={2} className="text-center">{header.transferYear}/{header.transferDate.substring(0,2)}/{header.transferDate.substring(2,4)}</td>
                                                <td rowSpan={2} className="text-center">{header.classification}</td>
                                                <td rowSpan={2} className="text-end">{Number(header.totalNumber)}</td>
                                                <td className="text-end small text-muted">{Number(header.totalNumberOfSuccess).toLocaleString()}</td>
                                                <td rowSpan={2}  className="text-end">&yen;{Number(header.totalFee).toLocaleString()}</td>
                                                <td className="text-end small text-muted">&yen;{Number(header.totalFeeOfSuccess).toLocaleString()}</td>
                                            </tr>
                                            <tr key={index} className="align-middle">
                                                <td className="text-end small text-muted">{Number(header.totalNumberOfFailure).toLocaleString()}</td>
                                                <td className="text-end small text-muted">&yen;{Number(header.totalFeeOfFailure).toLocaleString()}</td>
                                            </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};