import { useState } from "react";
//import { useNavigate } from "react-router-dom";
import { useAdmin } from "../hooks/useAdmin";
import { Footer } from "./Footer";
import { Loading } from "./Loading";
import logo50 from "../images/en_logo_wh_50.png";
import logo60 from "../images/en_logo_wh_60.png";
import { useCognito } from "../hooks/useCognito" ;
import { AuthState } from '@aws-amplify/ui-components';

export const LoginDialog = ( props ) => {
    
    const { setUser , setAuthState , setUserName } = props ; 
    
    const { checkPassword } = useAdmin();
    const { signIn } = useCognito() ; 
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

//    const navigate = useNavigate();

    const onSubmit = async () => {

        try {
            setIsLoading(true);
            const result = await signIn( userId , password ) 
            const admin = await checkPassword(userId, password);
            if (( result != null ) && ( admin != null )) {
                //const result = await signIn( userId , password ) 
                console.log( result ) ;  
                setIsLoading(false);
                setUser(result);
                setAuthState( AuthState.SignedIn );
                setUserName( admin.name ); 
                // navigate('/MainMenu')
            } else {
                setErrorMessage("ユーザーIDまたはパスワードが正しくありません。");
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onChangeMailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value);
    };

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    };
    
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                    <div className="container-fluid">
                        <img src={logo50} alt="EN.logo" className="img-fluid d-md-none" />
                        <img src={logo60} alt="EN.logo" className="img-fluid d-none d-md-block me-2" />
                        <a className="m-0 m-md-2 navbar-brand fs-5" href="/MainMenu"> 月 １ 美 容 </a>
                        <div className="collapse navbar-collapse fs-6" id="navbarNav">
                        </div>
                    </div>
                </nav>
            </header>
            { isLoading ? (
                <Loading />
            ) : (
            <div className="container mt-5">
                <form onSubmit={onSubmit}>
                    <section className="row">
                        <section className="col">
                            <header className=" border-bottom pb-2 mb-4 d-flex align-items-center">
                                <h1 className="fs-4 m-0 ">管理者ログイン</h1>
                            </header>
                        </section>
                    </section>

                    <section className="row g-3 mt-1">
                        <section className="col">
                            <div className="col-md-12">
                                <div className="row g-3">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="UserID" className="form-label">メールアドレス</label>
                                        <input type="text" className="form-control shadow-sm" id="UserID" value={userId} onChange={onChangeMailAddress} placeholder="" required />
                                    </div>
                                    <div className="col-3">
                                    </div>
                                    <div className="col-3">
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="Password" className="form-label">パスワード</label>
                                        <input type="password" className="form-control shadow-sm" id="Password" value={password} onChange={onChangePassword} placeholder="" required />
                                    </div>
                                    <div className="col-3">
                                    </div>

                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 d-flex">
                                    <button className="btn btn-lg btn-primary shadow-sm mx-auto mt-5" id="login" style={{ width: 200 }} type="submit">ログイン</button>
                                </div>
                            </div>
                            <div className="row mt-4 is-invalid">
                                <div className="col-md-12 d-flex invalid-feedback text-center">
                                    {errorMessage}
                                </div>
                            </div>
                        </section>
                    </section>
                </form>
            </div>
            )}
            <Footer />
        </>
    );
};