import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import { MSalonMember, MService, MSalon, TServicePeriod } from "../API";

type SelectedSalonType = {
    selectedSalonContext: MSalon | null
    setSelectedSalonContext: Dispatch<SetStateAction<MSalon | null>>
    selectedPersonelName: string | null | undefined
    setSelectedPersonelName: Dispatch<SetStateAction<string | null | undefined>>
    selectedMemberContext: MSalonMember | null
    setSelectedMemberContext: Dispatch<SetStateAction<MSalonMember | null>>
    selectedServiceContext: MService | null
    setSelectedServiceContext: Dispatch<SetStateAction<MService | null>>
    selectedServiceMenusContext: MService[] | null
    setSelectedServiceMenusContext: Dispatch<SetStateAction<MService[] | null>>
    selectedServicePeriodsContext: TServicePeriod[] | null
    setSelectedServicePeriodsContext: Dispatch<SetStateAction<TServicePeriod[] | null>>
    initSelectedMemberContext: any
}

export const SelectedSalonContext = createContext<SelectedSalonType>({} as SelectedSalonType);

export const SelectedSalonProvider: React.FC = (props: { children?: ReactNode }) => {
    const { children } = props;

    const [selectedSalonContext, setSelectedSalonContext] = useState<MSalon | null>(null);
    const [selectedPersonelName, setSelectedPersonelName] = useState<string | null | undefined>(null);
    const [selectedMemberContext, setSelectedMemberContext] = useState<MSalonMember | null>(null);
    const [selectedServiceContext, setSelectedServiceContext] = useState<MService | null>(null);
    const [selectedServiceMenusContext, setSelectedServiceMenusContext] = useState<MService[] | null>(null);
    const [selectedServicePeriodsContext, setSelectedServicePeriodsContext] = useState<TServicePeriod[] | null>(null);

    const initSelectedMemberContext = () => {
        const member: MSalonMember = {
            __typename: "MSalonMember",
            id: "",
            storeId: "",
            memberId: "",
            applicationDate: "",
            memberName: "",
            furigana: "",
            dateOfBirth: "",
            zipCode: "",
            address1: "",
            address2: "",
            phoneNumber: "",
            phoneNumberMobile: "",
            mailAddress: "",
            bankCode: "",
            bankName: "",
            bankBranchCode: "",
            bankBranchName: "",
            depositType: "",
            accountNumber: "",
            depositerName: "",
            confirmStatus: "",
            updateDate: "",
            confirmDate: "",
            deleteFlag: "",
            deleteDate: "",
            lastUpdater: "",
            lastUpdateDate: "",
            useServices: null,
            createdAt: "",
            updatedAt: "",
        }

        setSelectedMemberContext(member);
    }


    return (
        <SelectedSalonContext.Provider
            value=
            {{
                selectedSalonContext
                , setSelectedSalonContext
                , selectedPersonelName
                , setSelectedPersonelName
                , selectedMemberContext
                , setSelectedMemberContext
                , selectedServiceContext
                , setSelectedServiceContext
                , selectedServiceMenusContext
                , setSelectedServiceMenusContext
                , selectedServicePeriodsContext
                , setSelectedServicePeriodsContext
                , initSelectedMemberContext
            }}>
            {children}
        </SelectedSalonContext.Provider>
    )
}