import { useBankcodejp, Mbank, Mbranch } from "../../hooks/useBankcodejp";
import { useNavigate, useLocation } from "react-router-dom"
import { Header } from "../../atoms/Header"
import { Footer } from "../../atoms/Footer"
import { useEffect, useState, useRef } from "react";
import { useSalon } from "../../hooks/useSalon";
import { useSalonMembers } from "../../hooks/useSalonMembers";
import { Loading } from '../../atoms/Loading';
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/common.css';
import { Button, Modal } from 'react-bootstrap';
import { MSalon, MSalonMember } from "../../API";
import { DEPOSIT_TYPE_ORDINARY, ANYWHERE_UNCONFIRMED } from '../../global-define';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useCommonTools } from "../../hooks/useCommonTools";

/* ReactDatePicker(ここから) */
import ReactDatePicker/*, { registerLocale } */ from "react-datepicker";
//import getYear from "date-fns/getYear";
//import getMonth from "date-fns/getMonth";
//import ja from 'date-fns/locale/ja';

type Inputs = {
    bankType: string;
    bankSearch: string;
    bankList: string;
    bankBranchSearch: string;
    bankBranchList: string;
    //bankName: string;
    //bankBranchName: string; 
    depositType: string;
    accountNumber: string;
    accountKigou: string;
    accountBangou: string;
    depositerName: string;
}

export const StoreMemberBankEdit = (props) => {

    const { setUser, setAuthState, userName } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        //getValues
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const storeId = query.get("storeId")
    const memberId = query.get("memberId");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getSalonAccount } = useSalon();
    const { updateSalonMember, getSalonMember } = useSalonMembers();
    const { getBanks, getBranches, getBank, getBranch } = useBankcodejp();
    const { zenNum2HanNum, hira2ZenKana } = useCommonTools();

    const [salonMember, setSalonMember] = useState<MSalonMember>();

    const [accountKigouRequired, setAccountKigouRequired] = useState<boolean>(false);
    const [accountBangouRequired, setAccountBangouRequired] = useState<boolean>(false);
    const [bankListRequired, setBankListRequired] = useState<boolean>(false);
    const [bankBranchListRequired, setBankBranchListRequired] = useState<boolean>(false);
    const [accountNumberRequired, setAccountNumberRequired] = useState<boolean>(false);
    const [salonAccount, setSalonAccount] = useState<MSalon>();

    const bankSelectRef = useRef<HTMLSelectElement>(null);
    const bankBranchInputRef = useRef<HTMLInputElement>(null);
    const bankBranchSelectRef = useRef<HTMLSelectElement>(null);

    const navigate = useNavigate();

    const Y = (new Date()).getFullYear();
    const M = ("00" + ((new Date()).getMonth() + 1)).slice(-2);
    const D = ("00" + (new Date()).getDate()).slice(-2);
    const TodayString = Y + "/" + M + "/" + D

    /**
     * 更新するボタン押下
     */
    const onSubmit = async () => {
        setIsLoading(true);

        // 郵便局の場合（記号、番号の入力チェック）
        if ((typePost === true) && (accountKigou == null || accountKigou.length !== 5)) {
            setAccountKigouRequired(true);
        }
        else if ((typePost === true) && (accountBangou == null || accountBangou.length !== 8)) {
            setAccountKigouRequired(false);
            setAccountBangouRequired(true);
        }
        // 銀行の場合（銀行名、支店名、口座番号の入力チェック）
        else if ((typeBank === true) && (bankList == null || bankList.length === 0)) {
            setBankListRequired(true);
        }
        else if ((typeBank === true) && (bankBranchList == null || bankBranchList.length === 0)) {
            setBankListRequired(false);
            setBankBranchListRequired(true);
        }
        // 口座番号
        else if ((typeBank === true) && (accountNumber == null || accountNumber.length !== 7)) {
            setBankListRequired(false);
            setBankBranchListRequired(false);
            setAccountNumberRequired(true);
        }
        else {
            // 更新内容に変更があるか比較する。
            var salonMemberOrign = await getSalonMember(query.get("storeId"), query.get("memberId"));
            var update = false;
            if ((salonMemberOrign.accountNumber !== salonMember.accountNumber) ||
                (salonMemberOrign.depositerName !== salonMember.depositerName) ||
                (salonMemberOrign.depositType !== salonMember.depositType) ||
                (salonMemberOrign.bankSetDate !== salonMember.bankSetDate) ||
                (salonMemberOrign.bankCode !== salonMember.bankCode) ||
                (salonMemberOrign.bankName !== salonMember.bankName) ||
                (salonMemberOrign.bankBranchCode !== salonMember.bankBranchCode) ||
                (salonMemberOrign.bankBranchName !== salonMember.bankBranchName)) {
                // 変更あり
                update = true;
            }

            // 更新内容に変更があれば変更を実施する。
            if (update === true) {
                if (typePost === true) {
                    // 郵便局の場合は初期値をセットする SWEBデータレイアウトに合わせる
                    salonMember.depositType = DEPOSIT_TYPE_ORDINARY; // 普通預金固定
                    salonMember.bankCode = "9900"; // ゆうちょ銀行固定
                    salonMember.bankName = ' '.repeat(15);// スペース
                    salonMember.bankBranchCode = accountKigou.slice(1, 4);
                    salonMember.bankBranchName = ' '.repeat(15);// スペース
                }
                salonMember.confirmStatus = ANYWHERE_UNCONFIRMED;/*カード確認状態を未確認に変更*/
                await updateSalonMember(salonMember);
            }
            // 画面遷移
            navigate(`/StoreMemberEdit?storeId=${storeId}&memberId=${memberId}`);
        }

        setIsLoading(false);
        return;
    }

    /**
     * 戻るボタン押下
     */
    const OnClickReturn = () => navigate(`/StoreMemberEdit?storeId=${storeId}&memberId=${memberId}`)
    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showEditConfium, setShowEditConfium] = useState(false);
    const handleEditConfiumClose = () => setShowEditConfium(false);
    const handleEditConfiumShow = () => setShowEditConfium(true);

    /**
     * １件データ取得
     */
    const getData = async () => {
        // サロン情報の取得
        const result = await getSalonAccount(query.get("storeId"));

        setSalonAccount(result);
        // セット手順１．店舗会員情報を取得
        var salonMember = await getSalonMember(query.get("storeId"), query.get("memberId"));

        // セット手順２．画面表示用にstate変数にセット
        setSalonMember(salonMember);

        if (salonMember.bankSetDate != null && 0 < salonMember.bankSetDate.length) {
            setFormBankSetDate(new Date(salonMember.bankSetDate));
        } else {
            setFormBankSetDate(new Date(2022, 0));
        }

        // セット手順３．form変数にセット（inputフォームなど編集せずにSubmitした場合などに値を認識させるため）
        //setValue('bankName', salonMember.bankName); // 検索ボックスの値
        setValue('bankList', salonMember.bankCode); // Selectの値
        //setValue('bankBranchName', salonMember.bankBranchName); // 検索ボックスの値
        setValue('bankBranchList', salonMember.bankBranchCode); // Selectの値
        setValue('depositType', salonMember.depositType);
        setValue('accountNumber', salonMember.accountNumber);
        setValue('depositerName', salonMember.depositerName);

        // 初期化
        setValue('accountKigou', '');
        setAccountKigou('');
        setValue('accountBangou', '');
        setAccountBangou('');
        setAccountNumber('');
        // 格納
        if (salonMember.accountNumber != null && salonMember.accountNumber.length === 14) {
            // 郵便局の場合
            setValue('bankType', '2');
            updateBankType("2");
            setValue('accountKigou', salonMember.accountNumber.slice(0, 5));
            setAccountKigou(salonMember.accountNumber.slice(0, 5));
            setValue('accountBangou', salonMember.accountNumber.slice(6));
            setAccountBangou(salonMember.accountNumber.slice(6));

            setIsLoading(false);
        } else {
            // 銀行の場合
            setValue('bankType', '1');
            updateBankType("1");

            setAccountNumber(salonMember.accountNumber);


            // リストの更新
            getBankInfo(salonMember);

        }

    }

    const getBankInfo = async (salonMember: MSalonMember) => {

        // 銀行名取得
        var bank = await getBank(salonMember.bankCode);
        // 検索ボックスにセット
        setBankSearch(bank.fullWidthKana);
        // 検索を行いドロップダウンを更新する
        var bankList = await getBanks("fullWidthKana==" + bank.fullWidthKana + "*");
        setBankList(bankList);
        // ドロップダウンの値を設定する
        setBankName(salonMember.bankCode);
        // ドロップダウンのdisableを解除する
        setBankSearchButtonDisabled(false);
        setBankListDisabled(false);

        // 支店名取得
        var bankBranch = await getBranch(salonMember.bankCode, salonMember.bankBranchCode);
        // 検索ボックスにセット
        setBankBranchSearch(bankBranch.fullWidthKana);
        // 検索を行いドロップダウンを更新する
        var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranch.fullWidthKana + "*");
        setBankBranchList(bankBranchesList);
        // ドロップダウンの値を設定する
        setBankBranchName(salonMember.bankBranchCode);
        // ドロップダウンのdisableを解除する
        setBankBranchSearchDisabled(false);
        setBankBranchSearchButtonDisabled(false);
        setBankBranchListDisabled(false);

        // 非同期完了でローディングを解除
        setIsLoading(false);

    }

    /**
     * リロード時のイベント
     * @param event 
     */
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
        // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
        event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
    };

    /**
     * イベント処理
     */
    const onChangeAccountNumber = (event) => {
        setAccountNumber(zenNum2HanNum(event.target.value));
        setSalonMember({ ...salonMember, accountNumber: zenNum2HanNum(event.target.value) });
    }
    const onChangeDepositerName = (event) => { setSalonMember({ ...salonMember, depositerName: event.target.value }); }
    //const onBlurDepositerName = (event) => { setSalonMember({ ...salonMember, depositerName: hira2ZenKana(event.target.value) }); }
    const onChangeDepositType = (event) => { setSalonMember({ ...salonMember, depositType: event.target.value }); }

    /**
    * 銀行名・支店名関連
    */
    const [yokinClassName, setYokinClassName] = useState<string>("col-sm-6");
    const [yokinClassName2, setYokinClassName2] = useState<string>("col-sm-6");
    const [yokinClassName3, setYokinClassName3] = useState<string>("col-sm-6");
    const [typeBank, setTypeBank] = useState<boolean>(false);
    const [typePost, setTypePost] = useState<boolean>(false);
    // 区別
    const onChangeBankType = (event) => {
        updateBankType(event.target.value);
    }
    const updateBankType = (type) => {
        if (type === "1") {
            // 銀行の場合
            setTypeBank(true);
            setTypePost(false);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6");
            setYokinClassName2("col-sm-6 d-none");
            setYokinClassName3("col-sm-6");
        }
        else if (type === "2") {
            // 郵便局の場合
            setTypeBank(false);
            setTypePost(true);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6 d-none");
            setYokinClassName2("col-sm-6");
            setYokinClassName3("col-sm-6");
        }
    }
    // 銀行名検索
    const [bankSearch, setBankSearch] = useState<string>('');
    // 銀行名検索ボタンの有効/無効
    const [bankSearchButtonDisabled, setBankSearchButtonDisabled] = useState<boolean>(true);
    // 銀行名リストselectコントロールの値保存用
    const [bankName, setBankName] = useState<string>('');
    // 銀行名リスト
    const [bankList, setBankList] = useState<Mbank[]>([]);
    // 銀行名Optionリストの有効/無効
    const [bankListDisabled, setBankListDisabled] = useState<boolean>(true);
    // 銀行名OptionHTML
    const optionBankList = bankList.map((data) => <option value={data.code}>({data.code}) {data.name}</option>)
    // 口座登録日のフォーム用
    const [formBankSetDate, setFormBankSetDate] = useState(new Date());

    // イベント　銀行名検索ボックス入力
    const onChangeBankSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankSearchButtonDisabled(true);
        } else {
            setBankSearchButtonDisabled(false);
        }
        setBankSearch(event.target.value);
    }
    const onBlurBankSearch = (event) => { setBankSearch(hira2ZenKana(event.target.value)); }

    // イベント　銀行名検索ボタン押下
    const onButtonBankSearch = async () => {
        /*
        if (0 < bankSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankList = await getBanks("fullWidthKana==" + bankSearch + "*");
            setBankList(bankList);
            // 銀行名が更新されたので支店情報をリセット
            setBankBranchSearch("");
            setBankBranchList([]);
            // ドロップダウンのdisableを解除する
            setBankListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankListDisabled(true);
        }*/
        if (0 < bankSearch.length) {
            // bankSerchが数値なら銀行コードで検索
            if (isNaN(Number(bankSearch))) {
                // 1文字以上の場合は検索を行いドロップダウンを更新する
                var bankList = await getBanks("fullWidthKana==" + bankSearch + "*");
                setBankList(bankList);
                // 銀行名が更新されたので支店情報をリセット
                setBankBranchSearch("");
                setBankBranchList([]);
                // ドロップダウンのdisableを解除する
                setBankListDisabled(false);
                // 検索終わったら銀行選択にフォーカスあてる
                bankSelectRef.current.focus();

            } else {
                if (bankSearch.length === 4) {
                    var bank = await getBank(bankSearch);
                    if (bank !== '') {
                        // 検索ボックスにセット
                        setBankSearch(bank.fullWidthKana);
                        // 検索を行いドロップダウンを更新する
                        const banks = await getBanks("fullWidthKana==" + bank.fullWidthKana + "*");
                        setBankList(banks);
                        // ドロップダウンの値を設定する
                        setBankName(bankSearch);
                        setSalonMember({ ...salonMember, bankCode: bankSearch, bankName: bank.name });
                        //setSalonMember({ ...salonMember ,  bankName: bank.name}); 
                        // ドロップダウンのdisableを解除する
                        setBankSearchButtonDisabled(false);
                        setBankListDisabled(false);
                        setBankBranchSearch("");
                        setBankBranchList([]);
                        // 銀行名が確定したので支店名入力を有効にする。
                        setBankBranchSearchDisabled(false);
                        // コード直接の場合は支店名入力にフォーカスあてる    
                        bankBranchInputRef.current.focus();
                    } else {
                        //setBankListDisabled(true);
                    }
                } else {
                    //setBankListDisabled(true);
                }
            }
        } else {
            // ドロップダウンのdisableを有効にする
            setBankListDisabled(true);
        }

    }
    // イベント　銀行名Optionリストの変更確定時イベント
    const onChangeBankList = (event) => {
        salonMember.bankCode = event.target.value;
        setSalonMember({ ...salonMember, bankCode: event.target.value });
        salonMember.bankName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankName: salonMember.bankName });
        // 銀行名が確定したので支店名入力を有効にする。
        setBankBranchSearchDisabled(false);
        // Selectの値を更新する
        setBankName(event.target.value);
        // 銀行が変わった場合は支店も初期化
        setBankBranchSearch("");
        setBankBranchList([]);
    }
    // 支店名検索
    const [bankBranchSearch, setBankBranchSearch] = useState<string>('');
    // 支店名リストselectコントロールの値保存用
    const [bankBranchName, setBankBranchName] = useState<string>('');
    // 支店名検索ボックスの有効/無効
    const [bankBranchSearchDisabled, setBankBranchSearchDisabled] = useState<boolean>(true);
    // 支店名検索ボタンの有効/無効
    const [bankBranchSearchButtonDisabled, setBankBranchSearchButtonDisabled] = useState<boolean>(true);
    // 支店名リスト
    const [bankBranchList, setBankBranchList] = useState<Mbranch[]>([]);
    // 支店名Optionリストの有効/無効
    const [bankBranchListDisabled, setBankBranchListDisabled] = useState<boolean>(true);
    // イベント　支店名検索ボックス入力
    const onChangeBankBranchSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankBranchSearchButtonDisabled(true);
        } else {
            setBankBranchSearchButtonDisabled(false);
        }
        setBankBranchSearch(event.target.value);
    }
    const onBlurBankBranchSearch = (event) => { setBankBranchSearch(hira2ZenKana(event.target.value)); }
    // イベント　支店名OptionHTML
    const optionBankBranchList = bankBranchList.map((data, index) => <option value={data.code}>({data.code}) {data.name}</option>)
    // イベント　支店検索ボタン押下
    const onButtonBankBranchSearch = async () => {
        /*
        if (0 < bankBranchSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranchSearch + "*");
            setBankBranchList(bankBranchesList);
            // ドロップダウンのdisableを解除する
            setBankBranchListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankBranchListDisabled(true);
        }*/
        if (0 < bankBranchSearch.length) {

            if (isNaN(Number(bankBranchSearch))) {
                // 1文字以上の場合は検索を行いドロップダウンを更新する
                var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranchSearch + "*");
                setBankBranchList(bankBranchesList);
                // ドロップダウンのdisableを解除する
                setBankBranchListDisabled(false);
                // 検索終わったら支店選択ドロップにフォーカスを移す
                bankBranchSelectRef.current.focus();
            } else {
                if (bankBranchSearch.length === 3) {
                    //salonMember.bankBranchCode = bankBranchSearch ;
                    // 支店名取得
                    var bankBranch = await getBranch(salonMember.bankCode, bankBranchSearch);

                    if (bankBranch) { // 存在しない場合はundiendが返る
                        // 検索ボックスにセット
                        setBankBranchSearch(bankBranch.fullWidthKana);
                        // 検索を行いドロップダウンを更新する
                        const branches = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranch.fullWidthKana + "*");
                        setBankBranchList(branches);
                        // ドロップダウンの値を設定する
                        setBankBranchName(bankBranchSearch);
                        setSalonMember({ ...salonMember, bankBranchCode: bankBranch.code, bankBranchName: bankBranch.name });

                        // ドロップダウンのdisableを解除する
                        setBankBranchSearchDisabled(false);
                        setBankBranchSearchButtonDisabled(false);
                        setBankBranchListDisabled(false);

                        //bankBranchSelectRef.current.focus();
                    } else {
                        //setBankBranchListDisabled(true);
                    }
                } else {
                    //setBankBranchListDisabled(true);
                }
            }
        } else {
            // ドロップダウンのdisableを有効にする
            setBankBranchListDisabled(true);
        }
    }
    // イベント　支店名Optionリストの変更確定時イベント
    const onChangeBankBranchList = (event) => {
        salonMember.bankBranchCode = event.target.value;
        setSalonMember({ ...salonMember, bankBranchCode: event.target.value });
        salonMember.bankBranchName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankBranchName: salonMember.bankBranchName });
        // Selectの値を更新する
        setBankBranchName(event.target.value);
    }
    const [accountNumber, setAccountNumber] = useState<string>(null);
    const [accountKigou, setAccountKigou] = useState<string>(null);
    const [accountBangou, setAccountBangou] = useState<string>(null);
    const onChangeAccountKigou = (event) => {
        setAccountKigou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = zenNum2HanNum(event.target.value) + "-" + accountBangou;
        setSalonMember({ ...salonMember, accountNumber: zenNum2HanNum(salonMember.accountNumber) });
    }
    const onChangeAccountBangou = (event) => {
        setAccountBangou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = zenNum2HanNum(accountKigou) + "-" + event.target.value;
        setSalonMember({ ...salonMember, accountNumber: zenNum2HanNum(salonMember.accountNumber) });
    }

    const onChangeBankSetDate = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, bankSetDate: Y + "/" + M + "/" + D } as MSalonMember);
            setFormBankSetDate(new Date(Y + "/" + M + "/" + D));
            //setValue('applicationDate', Y+"/"+M+"/"+D);
        } catch (e) {
            // 入力フォームの内容が消された場合（必須項目なので今日の日付をセットする）
            setSalonMember({ ...salonMember, bankSetDate: TodayString } as MSalonMember);
            setFormBankSetDate(new Date(TodayString));
            //setValue('applicationDate', TodayString);
        }
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();

        // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //console.log("formbankSetDate"); 
    //console.log( formBankSetDate );

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">預金口座振替情報　編集</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">
                        <div className="col-md-12">
                            <h4>会員情報</h4>
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">契約店舗</h6>
                                    </div>
                                    <span className="text-muted">{salonAccount.storeName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">会員番号</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.memberId}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">会員氏名</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.memberName}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row g-3">
                        <h4>
                            預金口座振替情報
                        </h4>
                        <div className="col-md-6">
                            <label htmlFor="bankSetDate" className="form-label">口座登録日</label>
                            <ReactDatePicker
                                id="bankSetDate"
                                locale='ja'
                                placeholderText="選択してください"
                                dateFormat="yyyy/MM/dd"
                                todayButton="今日"
                                onChange={onChangeBankSetDate}
                                selected={formBankSetDate ? formBankSetDate as Date : null}
                                onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                            />
                        </div>
                        <label htmlFor="bankType" className="form-label required">銀行またはゆうちょ銀行を選択してください</label>
                        <div className="btn-group m-1" style={{ zIndex: -99 }}>
                            <input type="radio" {...register('bankType', { required: true })} className="btn-check" id="bank" value="1" onChange={onChangeBankType} checked={typeBank} />
                            <label htmlFor="bank" className="btn btn-outline-primary w-50">銀行</label>

                            <input type="radio" {...register('bankType', { required: true })} className="btn-check" id="post" value="2" onChange={onChangeBankType} checked={typePost} />
                            <label htmlFor="post" className="btn btn-outline-primary w-50">ゆうちょ銀行</label>
                        </div>
                        {errors.bankType?.types?.required && <div className="text-danger">銀行またはゆうちょ銀行を選択してください</div>}

                        <div className={yokinClassName}>
                            <label htmlFor="bankList" className="form-label required">銀行名カナまたは銀行コード(4桁)で検索してください。</label>
                            <div className="input-group">
                                <input type="text" {...register('bankSearch')} className="form-control" id="bankSearch" value={bankSearch} onChange={onChangeBankSearch} onBlur={onBlurBankSearch} placeholder="銀行コード(4桁)または銀行名を全角カタカナで１文字以上入力" />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankSearch} disabled={bankSearchButtonDisabled}>
                                        <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                            <select {...register('bankList')} className="form-control" id="bankList" value={bankName} onChange={onChangeBankList} disabled={bankListDisabled} ref={bankSelectRef} >
                                <option value=''>選択してください</option>
                                {optionBankList}
                            </select>
                            {bankListRequired === true && <div className="text-danger">銀行名を選択してください</div>}
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="bankBranchList" className="form-label required">支店名カナまたは支店コード(3桁)で検索してください。</label>
                            <div className="input-group">
                                <input type="text" {...register('bankBranchSearch')} ref={bankBranchInputRef} className="form-control" id="bankBranchSearch" value={bankBranchSearch} onChange={onChangeBankBranchSearch} onBlur={onBlurBankBranchSearch} disabled={bankBranchSearchDisabled} placeholder="支店コード(3桁)または支店名を全角カタカナで１文字以上入力" />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankBranchSearch} disabled={bankBranchSearchButtonDisabled}>
                                        <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                            <select {...register('bankBranchList')} className="form-control" id="bankBranchList" value={bankBranchName} onChange={onChangeBankBranchList} disabled={bankBranchListDisabled} ref={bankBranchSelectRef}>
                                <option value=''>選択してください</option>
                                {optionBankBranchList}
                            </select>
                            {bankBranchListRequired === true && <div className="text-danger">支店名を選択してください</div>}
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="depositType" className="form-label required">預金種別</label>
                            <select {...register('depositType')} className="form-control" id="depositType" value={salonMember.depositType} onChange={onChangeDepositType}>
                                <option value="1">普通</option>
                                <option value="2">当座</option>
                            </select>
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="accountNumber" className="form-label required">口座番号</label>
                            <input type="text" {...register('accountNumber')} className="form-control" id="accountNumber" value={accountNumber} onChange={onChangeAccountNumber} placeholder="半角7桁0～9の数字" />
                            {accountNumberRequired === true && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                        </div>
                        <div className={yokinClassName2}>
                            <label htmlFor="accountKigou" className="form-label required">記号</label>
                            <input type="text" {...register('accountKigou')} className="form-control" id="accountKigou" value={accountKigou} onChange={onChangeAccountKigou} placeholder="半角5文字" />
                            {accountKigouRequired === true && <div className="text-danger">記号を半角5桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのAAAAAの部分。</div>}
                        </div>
                        <div className={yokinClassName2}>
                            <label htmlFor="accountKigou" className="form-label required">番号</label>
                            <input type="text" {...register('accountBangou')} className="form-control" id="accountBangou" value={accountBangou} onChange={onChangeAccountBangou} placeholder="半角8文字" />
                            {accountBangouRequired === true && <div className="text-danger">番号を半角8桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのBBBBBBBBの部分。</div>}
                        </div>
                        <div className={yokinClassName3}>
                            <label htmlFor="depositerName" className="form-label required">預金者名（カナ）</label>
                            {/*参照：https://www.tanshin.co.jp/business/netbk/pdf/zengin_moji.pdf&clen=67237&chunk=true */}
                            <input type="text" {...register('depositerName', { required: true, pattern: /^[ァ-ヶー　（）．－「」／０-９Ａ-Ｚ]+$/ })} className="form-control" id="depositerName" value={salonMember.depositerName} onChange={onChangeDepositerName} /*onBlur={onBlurDepositerName}*/ placeholder="全角カタカナ・記号・英大文字" />
                            {errors.depositerName?.types?.required && <div className="text-danger">預金者名を全角カナ・記号・英大文字で入力してください</div>}
                            {errors.depositerName?.types?.pattern && <div className="text-danger">預金者名は全角カナ・記号・英大文字で入力してください</div>}
                        </div>
                    </div>
                    <div className="row mt-2 mb-5">
                        <div className="col-md-7">
                        </div>
                        <div className="col-md-5 d-flex" style={{ marginBottom: 60 }}>
                            <button className="btn btn-lg btn-primary ms-auto shadow-sm m-1" style={{ width: 120 }} type="button" onClick={handleEditConfiumShow}>更新する</button>
                        </div>
                    </div>

                    {/*更新確認ダイアログ*/}
                    <Modal show={showEditConfium} onHide={handleEditConfiumClose}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>預金口座振替情報を更新します。よろしいですか？
                                <p className="attention-box mb-0">更新した預金口座振替情報で振替を行うには「はい」を押下後、カードの再読み込み、もしくは「カード強制確認済み」のチェックをONにして会員情報を更新してください。</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{ width: 150 }} onClick={handleEditConfiumClose}>いいえ</Button>
                                <button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} type="submit" onClick={handleEditConfiumClose}>はい</button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};