import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom" ;
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { GraphQLResult } from '@aws-amplify/api';
import { useForm } from 'react-hook-form'
import { SystemSettingContext } from '../../providers/SystemSettingProvider';
import { updateMSystemCharge, createMSystemCharge } from '../../graphql/mutations';
import { CreateMSystemChargeInput, MSystemCharge } from '../../API';
import '../../css/common.css';
import { Button, Modal} from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { CONSIGNOR_CODE , CONSIGNOR_NAME } from '../../global-define';

type Inputs = {
    basicCharge: number ;
    pricePerCase: number ;  
    bankTransferFee: number ;  
}
export const SystemSettingEdit = ( props ) => {
    
    const { setUser, setAuthState , userName } = props;

    const {
        register, 
        handleSubmit, 
        formState: { errors },
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 

    const navigate = useNavigate(); 

     const { systemChargeContext } = useContext( SystemSettingContext ); 
    const [systemCharge, setSystemCharge] = useState<MSystemCharge>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isNodata, setIsNodata] = useState<boolean>(true);

    /**
     *  updateSystemSetting
     *
     *  画面で登録ボタンが押された時の処理。画面の内容をDBに登録もしくは更新します。
     * 
     *  ※運用開始時に１件固定で登録するので未登録は本来ない想定だがMSystemChargeが未登録の時はcreateを利用する。
     */
     const updateSystemSetting = async () => {

        if (isNodata === true){
            // 登録
            const res = (await API.graphql(graphqlOperation(createMSystemCharge, {
                input: {
                //id :             "0001" ,
                basicCharge     : Number(systemCharge.basicCharge) ,
                pricePerCase    : Number(systemCharge.pricePerCase) ,
                bankTransferFee : Number(systemCharge.bankTransferFee) ,
                } ,
            })) as GraphQLResult<CreateMSystemChargeInput> );
            
            console.log( res ) ;
            setIsNodata(false);
        }else{
            // 更新
            const res = (await API.graphql(graphqlOperation(updateMSystemCharge, {
                input: {
                id :        `${systemCharge.id}` ,
                basicCharge     : Number(systemCharge.basicCharge) ,
                pricePerCase    : Number(systemCharge.pricePerCase) ,
                bankTransferFee : Number(systemCharge.bankTransferFee) ,
                } ,
            })) as GraphQLResult<CreateMSystemChargeInput> );
            console.log( res ); 
        }
    }

    /**
     *  getSystemCharge
     *
     *  SystemSettingDetailで取得したsystemChargeContextをセットします。
     *  SystemSettingDetailでMSystemChargeの登録が無かった場合は初期値をセットします。
     */
    const getSystemCharge = async () => {
        setIsNodata(true);
        if (systemChargeContext != null)
        {
            setSystemCharge( { ...systemChargeContext } )
            setIsNodata(false);
        }
    }

    console.log( "useeffect before" );

    /**
     * レンダー後に呼ばれる処理（いわゆる画面表示時に呼ばれる処理）
     */
    useEffect(() => {
        getSystemCharge();
        console.log( systemCharge );
        setIsLoading(false);
        // useEffectは起動時に引数なしを想定しているので第二引数は除外する（以下のコメントでVSCodeでの警告出力を抑制）
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log( "useeffect after" );

    const onSubmit = async () => {
        await updateSystemSetting() ;
        navigate('/SystemSettingDetail')
    }
    const OnClickReturn = () => navigate('/SystemSettingDetail')

    // フォームの入力を常に監視
    const onChangeBasicCharge = (event) => {
        setSystemCharge({ ...systemCharge , basicCharge: event.target.value });
    }
    const onChangePricePerCase = ( event ) => {
        setSystemCharge({ ...systemCharge , pricePerCase: event.target.value });
    }
    const onChangeBankTransferFee = ( event ) => {
        setSystemCharge({ ...systemCharge , bankTransferFee: event.target.value });
    }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showEditConfium, setShowEditConfium] = useState(false);
    const handleEditConfiumClose = () => setShowEditConfium(false);
    const handleEditConfiumShow = () => setShowEditConfium(true);
 
    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            { isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <section className="row mb-4">
                        <section className="col">
                            <div className="d-flex col-12 border-bottom">
                                <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex align-items-center">
                                    <h1 className="fs-4 m-2">システム設定　編集</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3">

                        <div className="col-md-6">
                            <section className="row">
                                <section className="col">
                                    <header className="pb-2 mb-1 d-flex align-items-center">
                                        <h1 className="fs-4 m-0 ">＜料金情報＞</h1>
                                    </header>
                                </section>
                            </section>
                            <div className="row g-3">
                            {isNodata === true ? (
                                <div className="col-md-12">
                                    <div className="text-danger font-weight-bold">未設定です。登録してください。</div>
                                </div>
                            ):(null)}
                                <div className="col-md-12">
                                    <label htmlFor="basicCharge" className="form-label required">サブスクリプションサービス基本利用料</label>
                                    <div className="input-group">
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('basicCharge' , { required:true, min:1, })} className="form-control" id="en1" value={systemCharge == null ? "" : systemCharge.basicCharge} onChange={onChangeBasicCharge} placeholder="" />
                                    </div>
                                    {errors.basicCharge?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.basicCharge?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="pricePerCase" className="form-label required">サブスクリプションサービス手数料</label>
                                    <div className="input-group">  
                                        <span className="input-group-text">１件あたり</span>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('pricePerCase' , { required:true, min:1, })} className="form-control" id="en1" value={systemCharge == null ? "" : systemCharge.pricePerCase} onChange={onChangePricePerCase} placeholder="" />
                                    </div>
                                    {errors.pricePerCase?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.pricePerCase?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="bankTransferFee" className="form-label required">振替手数料</label>
                                    <div className="input-group">  
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" style={{textAlign: 'right'}} {...register('bankTransferFee' , { required:true, min:1, })} className="form-control" id="en1" value={systemCharge == null ? "" : systemCharge.bankTransferFee} onChange={onChangeBankTransferFee} placeholder="" />
                                    </div>
                                    {errors.bankTransferFee?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.bankTransferFee?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <section className="row">
                                <section className="col">
                                    <header className="pb-2 mb-1 d-flex align-items-center">
                                        <h1 className="fs-4 m-0 ">＜アプラス情報＞</h1>
                                    </header>
                                </section>
                            </section>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label htmlFor="basicCharge" className="form-label unchangeable">委託者コード</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="consignorCode" value={CONSIGNOR_CODE} disabled />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="pricePerCase" className="form-label unchangeable">委託者名</label>
                                    <div className="input-group">  
                                        <input type="text" className="form-control" id="consignorName" value={CONSIGNOR_NAME} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <button className="btn btn-lg btn-success mx-auto m-1" style={{width: 150}} type="button" onClick={handleEditConfiumShow}>登録する</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
            
                    {/*更新確認ダイアログ*/}
                    <Modal show={showEditConfium} onHide={handleEditConfiumClose}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>登録します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" style={{width: 150}} onClick={handleEditConfiumClose}>いいえ</Button>
                                <button className="btn btn-primary ms-auto m-1" style={{width: 150}} type="submit" onClick={handleEditConfiumClose}>はい</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            )}
            <Footer />
        </>
    ); 
};