import { createTSalonPayment, deleteTSalonPayment } from './../graphql/mutations';
import { useSalon } from './useSalon';
import { useBilling } from './useBilling';
import { TSalonPayment, CreateTSalonPaymentInput, CreateTSalonPaymentMutation, GetTSalonPaymentQuery, DeleteTSalonPaymentMutation } from './../API';
import { listStoreByTransferDate, getTSalonPayment } from './../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { ListStoreByTransferDateQuery } from '../API';
import { BILLING_DATA_DOWNLOADED, BILLING_RESULT_UPLOADED } from '../global-define';
import { useSalonMembers } from './useSalonMembers';

export const useSalonPayment = () => {

    const { listSalonAccounts } = useSalon();
    const { /*listBillingDataByTransferDate, */ getBillingHeadersByTransferDate } = useBilling();
    const { getSalonMembers } = useSalonMembers();

    /**
      * 振替情報レコードの１件削除
      * 
      * @param   {string} id Paymentのレコードid 
      * 
      */
    const deleteSalonPayment = async (id: string) => {
        const res = (await API.graphql(graphqlOperation(deleteTSalonPayment, {
            input: {
                id: id
            },
        })) as GraphQLResult<DeleteTSalonPaymentMutation>);

        return (res);
    }
    /**
      * 振替日を指定して支払い情報ファイルを一括削除する
      * 
      * @param   {string} transferDate 取得するユーザ名(email) 
      * @returns {object} ユーザオブジェクト、存在しない場合はnull 
      */
    const deletePaymentByTransferDate = async (transferDate: string) => {

        const payments = await listPaymentByTransferDate(transferDate);

        if (payments != null) {
            for (const payment of payments) {
                const result = await deleteSalonPayment(payment.id);
                console.log(result);
            }
        }
    }

    const listPaymentByTransferDate = async (transferDate: string) => {

        const res = (await API.graphql(graphqlOperation(listStoreByTransferDate, {
            transferDate: transferDate,
            limit: 999999
        })) as GraphQLResult<ListStoreByTransferDateQuery>);

        const ret = res.data.listStoreByTransferDate.items as TSalonPayment[];

        return (ret)
    }

    const makePaymentData = async (transferDate: string) => {

        try {

            const salons = await listSalonAccounts();

            //            const billings = await listBillingDataByTransferDate(transferDate);
            //const billingLists = await listBillingDataByTransferDate(transferDate);
            const billinHeaders = await getBillingHeadersByTransferDate(transferDate.substring(0, 4), transferDate.substring(4, 8));

            if (billinHeaders[0].status === BILLING_RESULT_UPLOADED) {

                //if (billingLists.length === 0) {
                //    return ('no billling data');
                //}

                for (const salon of salons) {

                    const payment: CreateTSalonPaymentInput = {
                        storeId: salon.storeId,
                        storeName: salon.storeName,
                        transferDate: transferDate,
                        totalNumber: 0,
                        totalFee: 0,
                        totalNumberOfSuccess: 0,
                        totalFeeOfSuccess: 0,
                        totalNumberOfFailure: 0,
                        totalFeeOfFailure: 0,
                        basicCharge: salon.basicCharge,
                        pricePerCase: salon.pricePerCase,
                        bankRegistFee: 0,
                        bankRegistNumber: 0
                    }

                    for (const header of billinHeaders) {
                        for (const billing of header.billingData.items) {
                            if (salon.id === billing.salonId) {

                                payment.totalNumber++;
                                payment.totalFee += Number(billing.transferAmount);

                                switch (billing.transferResultCode) {
                                    case '0': // 振替済み
                                        payment.totalNumberOfSuccess++;
                                        payment.totalFeeOfSuccess += Number(billing.transferAmount);
                                        break;
                                    case '1': // 資金不足
                                    case '2': // 預金取引無し
                                    case '3': // 預金者都合による振替停止
                                    case '4': // 預金口座振替依頼書なし
                                    case '8': // 委託者都合による振替停止
                                    case '9': // その他：請求データ不備（データフォーマット上のエラー）
                                    default:
                                        payment.totalNumberOfFailure++;
                                        payment.totalFeeOfFailure += Number(billing.transferAmount);
                                }
                            }
                        }
                    }

                    // 該当するストアの会員を取得する
                    const members = await getSalonMembers(salon.storeId);
                    console.log('storeId:' + salon.storeId)
                    // membersの中からbankSetDateがTranferDateに一致するデータを抽出する
                    const bankRegistThisMonth = members.filter(function (member) {
                        if (member.bankSetDate) {
                            console.log('bankSetDate:' + member.bankSetDate)

                            const bankSetMonth = member.bankSetDate.substring(0, 4) + member.bankSetDate.substring(5, 7);

                            //const transferMonth = this.transferDate.substring(0, 6);
                            const registCountMonth = calcMonth(transferDate, -1);
                            if (bankSetMonth === registCountMonth) {
                                //return member;
                                return true;
                            } else {
                                return false;
                            }
                        }
                        return false;
                    }, { transferDate })

                    payment.bankRegistNumber = bankRegistThisMonth.length;
                    payment.bankRegistFee = 330;

                    const result = await createSalonPayment(payment);
                    console.log(result)
                }

                return (BILLING_RESULT_UPLOADED);
            } else {
                return (BILLING_DATA_DOWNLOADED)
            }
        } catch (e) {
            return (e);
        }
    }
    const calcMonth = (strDate: string, addMonth: number) => {

        const year = parseInt(strDate.substring(0, 4)); // 年を取得
        const month = parseInt(strDate.substring(4, 6)); // 月を取得
        const date = new Date(year, month + addMonth - 1, 1); // 1か月前のDateオブジェクトを作成
        const newYear = date.getFullYear(); // 新しい年を取得
        const newMonth = ('0' + (date.getMonth() + 1)).slice(-2); // 新しい月を取得
        const newStrDate = `${newYear}${newMonth}`; // 新しい年月のつながった6桁の文字列を作成

        return newStrDate
    }

    const createSalonPayment = async (payment: CreateTSalonPaymentInput) => {
        const res = (await API.graphql(graphqlOperation(createTSalonPayment, {
            input: {
                storeId: payment.storeId,
                storeName: payment.storeName,
                transferDate: payment.transferDate,
                totalNumber: payment.totalNumber,
                totalFee: payment.totalFee,
                totalNumberOfSuccess: payment.totalNumberOfSuccess,
                totalFeeOfSuccess: payment.totalFeeOfSuccess,
                totalNumberOfFailure: payment.totalNumberOfFailure,
                totalFeeOfFailure: payment.totalFeeOfFailure,
                basicCharge: payment.basicCharge,
                pricePerCase: payment.pricePerCase,
                bankRegistFee: payment.bankRegistFee,
                bankRegistNumber: payment.bankRegistNumber
            },
        })) as GraphQLResult<CreateTSalonPaymentMutation>);

        return (res);
    }

    const getSalonPayment = async (paymentId: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(getTSalonPayment, {
                id: paymentId
            })) as GraphQLResult<GetTSalonPaymentQuery>);

            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }

    return { listPaymentByTransferDate, deletePaymentByTransferDate, makePaymentData, getSalonPayment }
}



