import { GetAdminByIdAndPassQuery, MAdmin } from './../API';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { getAdminByIdAndPass } from '../graphql/queries';



export const useAdmin = () => {

    const checkPassword = async (userId: string, password: string) => {

        try {
            const res = (await API.graphql(graphqlOperation( getAdminByIdAndPass, {
                mail: userId,
                password: { eq: password },
                limit: 999999
            })) as GraphQLResult<GetAdminByIdAndPassQuery>);

            let result = null;

            if (res.data.getAdminByIdAndPass.items.length > 0) {
                result = res.data.getAdminByIdAndPass.items[0] as MAdmin;
            }

            return (result)
        } catch (e) {
            console.log(e);
            return (e); 
        }
    }

    return { checkPassword };

}

