import { Link, useNavigate } from "react-router-dom" ; 
import logo50 from "../images/en_logo_wh_50.png" ;
import logo60 from "../images/en_logo_wh_60.png" ;
import { BiBank2 } from "../atoms/icons/BiBank2"
import { BiBookFill } from "../atoms/icons/BiBookFill"
import { BiGearFill } from "../atoms/icons/BiGearFill"
import { BiCloudDownloadFill } from "../atoms/icons/BiCloudDownloadFill"
import { BiCloudUploadFill } from "../atoms/icons/BiCloudUploadFill"
import { BiHouseFill } from "../atoms/icons/BiHouseFill"
import { BiPersonFill } from "../atoms/icons/BiPersonFill"
import { BiShieldLockFill } from "../atoms/icons/BiShieldLockFill"
import { BiDoorClosedFill } from "../atoms/icons/BiDoorClosedFill"
import { useCognito } from "../hooks/useCognito";
import { AuthState } from '@aws-amplify/ui-components';
import '../css/common.css';

export const Header = ( props ) => {
    
    const { setUser , setAuthState , userName , setIsLoading } = props ; 

    const {  signOut } = useCognito() ; 
    const navigate = useNavigate();

    const onClickLogout = async () => {
        setIsLoading( true ) ; 
        await signOut() ; 
        setUser( null ) ; 
        setAuthState( AuthState.SignedOut )
        setIsLoading( false ) ;
        navigate('/') 
    } 

    return (
        <>
          <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark bg-gradient">
                    <div className="container-fluid">
                        <img src={logo50} alt='logo' className="img-fluid d-md-none" />
                        <img src={logo60} alt='logo' className="img-fluid d-none d-md-block me-2" />
                        <a className="m-0 m-md-2 navbar-brand fs-5" href="/MainMenu"> 月 １ 美 容 </a>
                        
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse fs-6" id="navbarNav">
                            <div className="dropdown ms-auto my-2 me-2">
                                <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-menu-down me-2"></i>MENU
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <Link to="/MainMenu" className="dropdown-item">
                                            <BiHouseFill />
                                            MainMenu
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/SystemSettingDetail" className="dropdown-item">
                                            <BiGearFill />
                                            システム設定
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/AccountList" className="dropdown-item">
                                            <BiShieldLockFill />
                                            管理者アカウント管理
                                        </Link>
                                    </li>
                                    <li>                                    
                                        <Link to="/StoreAccountList" className="dropdown-item">
                                        <BiPersonFill />
                                            店舗アカウント管理
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/StoreList" className="dropdown-item">
                                            <BiBookFill />
                                            店舗管理
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/AnyWhereCSVUpload" className="dropdown-item">
                                            <BiGearFill />
                                            AnyWhereCSVアップロード
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/TransInfoCSVDownload" className="dropdown-item">
                                            <BiCloudDownloadFill />
                                            請求データファイルダウンロード
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/BillResultCSVUploadHistory" className="dropdown-item">
                                            <BiCloudUploadFill />
                                            結果データファイルアップロード
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/PayCallList" className="dropdown-item">
                                            <BiBank2 />
                                            支払い通知書
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown my-2">
                                <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-person-fill me-2"></i>{userName}
                                </button>
                                {/*dropdown-menu-md-end ≥768px for iPad */}
                                <ul className="dropdown-menu dropdown-menu-md-end" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <button className="dropdown-item" onClick={onClickLogout} >
                                            <BiDoorClosedFill />
                                            ログアウト
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>    
            </header>
        </>
    )
}