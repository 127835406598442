import React from 'react';
import { Routes , Route } from 'react-router-dom';
//import { Home } from "../Home"
import { MainMenu } from "../pages/MainMenu"
import { SystemSettingDetail } from "../pages/systemsetting/SystemSettingDetail"
import { SystemSettingEdit } from "../pages/systemsetting/SystemSettingEdit"
import { AccountList } from "../pages/account/AccountList"
import { AccountRegister } from "../pages/account/AccountRegister"
import { AccountDetail } from "../pages/account/AccountDetail"
import { AccountEdit } from "../pages/account/AccountEdit"
import { StoreAccountList } from "../pages/storeaccount/StoreAccountList"
import { StoreAccountRegister } from "../pages/storeaccount/StoreAccountRegister"
import { StoreAccountEdit } from "../pages/storeaccount/StoreAccountEdit"
import { StoreAccountDetail } from "../pages/storeaccount/StoreAccoutDetail"
import { StoreList } from "../pages/store/StoreList"
import { StoreMenu } from "../pages/store/StoreMenu"
import { AnyWhereCSVUpload } from "../pages/store/AnyWhereCSVUpload"
import { AnyWhereCSVUploadComplete } from "../pages/store/AnyWhereCSVUploadComplete"
import { AnyWhereCSVUploadList } from "../pages/store/AnyWhereCSVUploadList"
import { StoreMemberBankEdit } from "../pages/store/StoreMemberBankEdit"
import { StoreMemberDetail } from "../pages/store/StoreMemberDetail"
import { StoreMemberEdit } from "../pages/store/StoreMemberEdit"
import { StoreMemberList } from "../pages/store/StoreMemberList"
import { StoreMemberRegister } from "../pages/store/StoreMemberRegister"
import { StoreMemberRegisterConfirm } from "../pages/store/StoreMemberRegisterConfirm"
import { StoreMemberHistory } from "../pages/store/StoreMemberHistory"
import { StoreServiceMenuAdd } from "../pages/store/StoreServiceMenuAdd"
import { StoreServiceMenuEdit } from "../pages/store/StoreServiceMenuEdit"
import { StoreServiceMenuList } from "../pages/store/StoreServiceMenuList"

import { TransInfoCSVDownload } from "../pages/TransInfoCSVDownload"
import { BillResultCSVUploadHistory } from "../pages/billresult/BillResultCSVUploadHistory"
import { BillResultCSVInfo } from "../pages/billresult/BillResultCSVInfo"
import { BillResultCSVUpload } from "../pages/billresult/BillResultCSVUpload"
import { BillResultCSVUploadResult } from "../pages/billresult/BillResultCSVUploadResult"

import { PayCallList } from "../pages/paycall/PayCallList"
import { PayCallHistoryDetail } from "../pages/paycall/PayCallHistoryDetail"
import { PayCallHistoryDetailResult } from "../pages/paycall/PayCallHistoryDetailResult"

import { Page404 } from "../pages/Page404"

export const Router = ( props ) => {

    const { setUser , setAuthState , userName } = props ; 

    return (
        <Routes>
            {/* ログイン */}
            <Route path="/" element={<MainMenu setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            {/* メインメニュー */}
            <Route path="MainMenu" element={<MainMenu setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            {/* システム利用料設定 */}
            <Route path="SystemSettingDetail" element={<SystemSettingDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="SystemSettingEdit" element={<SystemSettingEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            {/* 管理アカウント管理登録 */}
            <Route path="AccountList" element={<AccountList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="AccountRegister" element={<AccountRegister setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="AccountDetail" element={<AccountDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id" element={<AccountDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>}/>
            </Route>
            <Route path="AccountEdit" element={<AccountEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id" element={<AccountEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>}/>
            </Route>
            {/* 店舗アカウント管理登録 */}            
            <Route path="StoreAccountList" element={<StoreAccountList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreAccountRegister" element={<StoreAccountRegister setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreAccountEdit" element={<StoreAccountEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":storeId" element={<StoreAccountEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>}/>
            </Route>
            <Route path="StoreAccountDetail" element={<StoreAccountDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>}>
                <Route path=":storeId" element={<StoreAccountDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>}/>
            </Route> 
            {/* 店舗管理メニュー */}            
            <Route path="StoreList" element={<StoreList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMenu" element={<StoreMenu setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id" element={<StoreMenu setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            </Route>
            <Route path="AnyWhereCSVUpload" element={<AnyWhereCSVUpload setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="AnyWhereCSVUploadComplete" element={<AnyWhereCSVUploadComplete setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="AnyWhereCSVUploadList" element={<AnyWhereCSVUploadList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberBankEdit" element={<StoreMemberBankEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberDetail" element={<StoreMemberDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberEdit" element={<StoreMemberEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberList" element={<StoreMemberList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id" element={<StoreMemberList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            </Route>    

            <Route path="StoreMemberRegister" element={<StoreMemberRegister setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberRegisterConfirm" element={<StoreMemberRegisterConfirm setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreMemberHistory" element={<StoreMemberHistory setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreServiceMenuAdd" element={<StoreServiceMenuAdd setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id/:nextId" element={<StoreServiceMenuAdd setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            </Route>
            <Route path="StoreServiceMenuEdit" element={<StoreServiceMenuEdit setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="StoreServiceMenuList" element={<StoreServiceMenuList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} >
                <Route path=":id" element={<StoreServiceMenuList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            </Route>

            {/* 請求データファイルダウンロード */}            
            <Route path="TransInfoCSVDownload" element={<TransInfoCSVDownload setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            {/* 結果データファイルアップロード */}            
            <Route path="BillResultCSVUploadHistory" element={<BillResultCSVUploadHistory setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="BillResultCSVInfo" element={<BillResultCSVInfo setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="BillResultCSVUpload" element={<BillResultCSVUpload setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            <Route path="BillResultCSVUploadResult" element={<BillResultCSVUploadResult setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />
            {/* 支払い通知書 */}            
            <Route path="PayCallList" element={<PayCallList setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />            
            <Route path="PayCallHistoryDetail" element={<PayCallHistoryDetail setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />            
            <Route path="PayCallHistoryDetailResult" element={<PayCallHistoryDetailResult setUser={setUser} setAuthState={setAuthState} userName={userName}/>} />            
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}
