//----------------------------------------
// グローバル定数定義
//----------------------------------------
// 委託者コード
export const CONSIGNOR_CODE = '324205' ;
export const CONSIGNOR_NAME = 'バル' ;
export const TYPE_CODE = '91' ;
export const CODE_PARTITION = '1' ;
// サロンメンバー関連
// 預金種別
export const DEPOSIT_TYPE_ORDINARY = "1" ;  // 普通口座
export const DEPOSIT_TYPE_CURRENT = "2" ;   // 当座口座
// 振替用紙
export const CONFIRM_PAPER_YES = "1" ;      // 振替用紙
export const CONFIRM_PAPER_NO = "0" ;       // AnyWhere

// 強制確認済みフラグ(AnyWhere)
export const FORCE_CONFIRM_YES = "1" ;   // カード強制確認済あり
export const FORCE_CONFIRM_NO  = "0" ;   // カード強制確認済なし

// 口座確認ステータス(AnyWhere)
export const ANYWHERE_CONFIRMED = "2" ;     // AnyWhere口座確認済み
export const ANYWHERE_UNCONFIRMED = "0" ;   // 口座未確認

// 請求結果ヘッダ
export const BILLING_DATA_DOWNLOADED = "0" ; // 請求データダウンロード済み
export const BILLING_RESULT_UPLOADED = "1" ; // 請求結果ファイルアップロード済み
// 請求結果データ
export const BILLING_FIRST = "1" ; // 初回振替分
export const BILLING_OTHER = "0" ; // ２回目以降
// サービス登録最大件数
export const MAX_SERVICE_NUM = 20; // 最大20件

//----------------------------------------
// メールアドレスチェック用正規表現
//----------------------------------------

export const EMAIL_CHECK_PATTERN = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*[A-Za-z0-9]{1}@{1}[A-Za-z0-9]{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/ ;
