import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom" ;
import { Footer } from "../../atoms/Footer"
import{ Header } from "../../atoms/Header"
import { Loading } from "../../atoms/Loading";
import { useBilling } from "../../hooks/useBilling" 
import { useSalon } from "../../hooks/useSalon";
import { useSalonMembers } from "../../hooks/useSalonMembers";
import { useCommonTools } from '../../hooks/useCommonTools';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';
import { MSalon, MSalonMember, TBillingData } from '../../API';

export const StoreMemberHistory = ( props ) => {
    
    const { setUser, setAuthState , userName } = props;
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { listBillingByMemberId } = useBilling();
    const { getSalonMember } = useSalonMembers();
    const { getSalonAccount } = useSalon();
    const { getTransferResultString, dateFormatString } = useCommonTools();
        
    const [ salonMember , setSalonMember ] = useState<MSalonMember>();

    const [Billings, setBillings] = useState<TBillingData[]>([]);

    const [salonAccount, setSalonAccount] = useState<MSalon>();

    /**
     * １件データ取得
     */
    const getData = async () => {

        // 履歴情報取得
        const billings = await listBillingByMemberId( query.get("memberPrimaryId") );
        //getStoreMember( '1' , '1' );
        setBillings( billings ) ; 

        // 店舗情報を取得
        const result = await getSalonAccount(query.get("storeId"));
        setSalonAccount( result );

        // 店舗会員情報を取得
        const member = await getSalonMember(query.get("storeId"), query.get("memberId"));
        setSalonMember( member ); 

        setIsLoading(false);
    }

    const navigate = useNavigate(); 

    const OnClickReturn = () => navigate(`/StoreMemberDetail?storeId=${query.get("storeId")}&memberId=${query.get("memberId")}`)

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} userName={userName}/>
            {isLoading ? (     
                <Loading />
            ) : (
            <div className="container mt-5">
                <section className="row mb-4">
                    <section className="col">
                        <div className="d-flex col-12 border-bottom">
                            <button className="btn p-0 d-flex align-items-left" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                            <header className="d-flex align-items-center">
                                <h1 className="fs-4 m-2">会員　振替履歴</h1>
                            </header>
                        </div>
                    </section>
                </section>

                <div className="row g-3">
                    <div className="col-md-12">
                    <h5>会員情報</h5>
                    <ul className="list-group mb-3 shadow-sm">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">契約店舗</h6>
                        </div>
                        <span className="text-muted">{salonAccount.storeName}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">会員番号</h6>
                        </div>
                        <span className="text-muted">{salonMember.memberId}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">会員氏名</h6>
                        </div>
                        <span className="text-muted">{salonMember.memberName}</span>
                        </li>
                    </ul>
                    </div>
                </div>

                <section className="row mb-5">
                    <h5>振替履歴</h5>
                    <section className="col">
                    <table className="table table-striped table-hover table-bordered shadow-sm">
                        <thead>
                        <tr className="text-center">
                            <th>振替日付</th>
                            <th>振替金額</th>
                            <th>振替結果</th>
                        </tr>
                        </thead>
                        <tbody>

                            {Billings.length === 0 &&
                                <tr>
                                    <td colSpan={8}>データはありません</td>
                                </tr>
                            }
                            {Billings.sort((a, b) => Number(a.transferDate) > Number(b.transferDate) ? 1 : -1 ).map((item , index ) => (
                                <tr key={index}>
                                    <td className="text-center">{dateFormatString(item.transferDate)}</td>
                                    <td className="text-end">&yen;{Number(item.transferAmount).toLocaleString()}</td>
                                    <td>{getTransferResultString(item.transferResultCode).replaceAll("<br/>"," ")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </section>
                </section>
            </div>
            )}
            <Footer />
        </>
    ); 
};


